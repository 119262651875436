import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { Loader, requestApi } from "./CommonFunction";
import '../styles/custom.css';

const Footer = () => {
	const [loadNewsletterButton, setLoadNewsletterButton] = useState(false);
  const [successMsg, setSuccessMsg] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = useForm();

	const showCookieModal = localStorage.getItem('accept-cookie');

	const acceptCookie = (value) => {
		localStorage.setItem('accept-cookie', value);
	};

	const handleNewsletterFormSubmit = async (values) => {
    isValid ? setLoadNewsletterButton(true) : setLoadNewsletterButton(false)
    
    await requestApi(
      'POST',
      'newsletter-subscription',
      {},
      {},
      values
    ).then((result) => {
      setSuccessMsg('You subscribe successfully.');
      // await setTimeout(() => {
      // 	setLoadNewsletterButton(false);
    	// }, 1500);
      setLoadNewsletterButton(false);
      reset();
      setErrorMsg('');
    }).catch((error) => {
      setErrorMsg(error?.response?.data?.message);
      setLoadNewsletterButton(false);
    });
    //setErrorMsg('Some error is occured.');
  };

	return (
		<>
			{/* Footer cookie modal popup */}
			<div
				className={ `modal fade modal-bottom-center ${showCookieModal == null ? 'modal-popup' : ''} `}
				id="modal-cookie-popup"
				tabIndex="-1"
			>
			  <div className="modal-dialog modal-xl">
				  <div className="modal-content">
				    <div className="modal-body p-6">
				      <div className="row">
				        <div className="col-md-12 col-lg-7 mb-4 mb-lg-0 my-auto align-items-center">
				          <h4 className="mb-2">Cookie Policy</h4>
				          <p className="mb-0">We use cookies to personalize content to make our site easier for you to use.</p>
				        </div>
				        <div className="col-md-10 col-lg-5 text-lg-end my-auto">
				          <button
				          	className="btn btn-primary rounded-pill mx-1"
				          	data-bs-dismiss="modal"
				          	onClick={() => acceptCookie('yes')}
				          >
				          	Accept
				          </button>
				          <button
				          	className="btn btn-primary rounded-pill mx-1" data-bs-dismiss="modal" aria-label="Close"
				          	onClick={() => acceptCookie('no')}
				          >
				          	Dismiss
				          </button>
				        </div>
				      </div>
				    </div>
				  </div>
				</div>
			</div>
			{/* Footer cookie modal popup */}

			<footer className="bg-gray">
	      <div className="container py-13 py-md-15">
	        <div className="row gy-6 gy-lg-0">
	          <div className="col-md-4 col-lg-4">
	            <div className="widget">
	              <img className="mb-4" src="/assets/img/logo.png" srcSet="/assets/img/logo@2x.png 2x" alt="" />
	              <p className="mb-4">© {new Date().getUTCFullYear()} youngathlete.org <br className="d-none d-lg-block" />All rights reserved.
	              </p>
	              <nav className="nav social ">
	                <Link to="#/">
	                  <i className="uil uil-twitter"></i>
	                </Link>
	                <Link to="#/">
	                  <i className="uil uil-facebook-f"></i>
	                </Link>
	                <Link to="#/">
	                  <i className="uil uil-instagram"></i>
	                </Link>
	              </nav>
	            </div>
	          </div>
	          <div className="col-md-4 col-lg-2">
	            <div className="widget">
	              <h4 className="widget-title mb-3">Organization</h4>
	              <ul className="list-unstyled text-reset mb-0">
		              <li>
		              	<Link to="/about-us">About us</Link>
		              </li>
		              <li>
		              	<Link to="/events">Events</Link>
		              </li>
		              <li>
		              	<Link to="/get-involved">Get involved</Link>
		              </li>
		              <li>
		              	<Link to="/contact">Contact</Link>
		              </li>
		            </ul>
	            </div>
	          </div>
	          <div className="col-md-4 col-lg-2">
	            <div className="widget">
	              <h4 className="widget-title mb-3">More</h4>
	              <ul className="list-unstyled text-reset mb-0">
		              <li>
		              	<Link to="/cookie-policy">Cookies Policy</Link>
		              </li>
		              <li>
		              	<Link to="/terms-of-use">Terms of Use</Link>
		              </li>
		              <li>
		              	<Link to="/privacy-policy">Privacy Policy</Link>
		              </li>
		            </ul>
	            </div>
	          </div>
	          <div className="col-md-12 col-lg-4">
	            <div className="widget">
	              <h4 className="widget-title mb-3">Our Newsletter</h4>
	              <p className="mb-5">Subscribe to our newsletter to get our news & deals delivered to you.</p>
	              <div className="newsletter-wrapper">
	                <div>
	                	{
			                successMsg &&
			                <div className="alert alert-success alert-icon alert-dismissible fade show text-start" role="alert">
			                  <i className="uil uil-check"></i>{successMsg} <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
			                </div>  
			              }
			              {
			                errorMsg &&
                      (typeof errorMsg === "string") &&
			                <div className="alert alert-danger alert-icon alert-dismissible fade show text-start" role="alert">
			                  <i className="uil uil-exclamation-triangle"></i>{errorMsg} <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
			                </div>  
			              }

	                  <form
	                  	className="text-start mb-3"
                			onSubmit={handleSubmit(handleNewsletterFormSubmit)}
	                  >
	                    <div id="mc_embed_signup_scroll2">
	                      <div className="mc-field-group input-group form-floating">
	                        <input
	                        	type="email"
				                    className="form-control"
				                    placeholder="Email Address"
				                    id="newsletterEmail"
				                    {...register('email', {
				                      required: true,
				                      pattern: /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/  //eslint-disable-line
				                    })}
	                        />
	                        <label htmlFor="newsletterEmail">Email Address</label>
	                        <button
	                        	disabled={loadNewsletterButton}
					                  className="btn btn-primary"
					                  type="submit"
	                        >
	                        	{ loadNewsletterButton ? <Loader text='Join' /> : 'Join' }
	                        </button>
	                      </div>
	                      {
			                    errors.email?.type === "required" && 
			                    <div className="formFieldError">
			                      Please enter your email address.
			                    </div>
			                  }
			                  {
			                    errors.email?.type === "pattern" && 
			                    <div className="formFieldError">
			                      Please enter valid email address.
			                    </div>
			                  }
			                  {
                          errorMsg &&
                          (typeof errorMsg === "object") &&
                          errorMsg?.email && 
                          <div className="formFieldError">
                            {errorMsg.email[0]}
                          </div>
                        }
	                    </div>
	                  </form>
	                </div>
	              </div>
	            </div>
	          </div>
	        </div>
	      </div>
	    </footer>
	    <div className="progress-wrap">
	      <svg className="progress-circle svg-content" width="100%" height="100%" viewBox="-1 -1 102 102">
	        <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
	      </svg>
	    </div>
	  </>
	);
};

export default Footer;