import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, Link } from "react-router-dom";
import { Loader, requestApi } from "./CommonFunction";
import '../styles/custom.css';

const LoginForm = () => {
  const [loadLoginButton, setLoadLoginButton] = useState(false);
  //const [successMsg, setSuccessMsg] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = useForm();
  const navigate = useNavigate();

  const handleLoginFormSubmit = async (values) => {
    isValid ? setLoadLoginButton(true) : setLoadLoginButton(false)

    await requestApi(
      'POST',
      'user-login-through-email-password',
      {},
      {},
      values
    ).then((result) => {
      //setSuccessMsg('Login successfully.Please wait while we redirecting to dashboard page.');
      localStorage.setItem('ya-user-access-token', result.data.response);
      
      setTimeout(() => {
        setLoadLoginButton(false);
      }, 1500);
      setErrorMsg('');
      reset();

      setTimeout(() => {
        document.getElementById("loginModalCloseBtn").click();
        navigate('/dashboard');
      }, 2000);

    }).catch((error) => {
      //console.log(error);
      setErrorMsg(error?.response?.data?.message);
      setLoadLoginButton(false);
    });
    //setErrorMsg('The login credential is not correct.');
  };

  return (
    <>
      <div className="modal fade" id="modal-login" tabIndex="-1">
        <div className="modal-dialog modal-dialog-centered modal-md">
          <div className="modal-content text-center">
            <div className="modal-body">
              <button type="button" id="loginModalCloseBtn" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              <h1 className="mb-3 display-5 text-start">Hi, Welcome Back</h1>
              <p className="lead mb-6 text-start">Fill your email and password to log in.</p>
              
              {/*
                successMsg &&
                <div className="alert alert-success alert-icon alert-dismissible fade show text-start" role="alert">
                  <i className="uil uil-check"></i>{successMsg} <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                </div>  
              */}
              {
                errorMsg &&
                (typeof errorMsg === "string") &&
                <div className="alert alert-danger alert-icon alert-dismissible fade show text-start" role="alert">
                  <i className="uil uil-exclamation-triangle"></i>{errorMsg} <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                </div>  
              }

              <form
                className="text-start mb-3"
                onSubmit={handleSubmit(handleLoginFormSubmit)}
              >
                <div className="form-floating mb-4">
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Email"
                    id="loginEmail"
                    {...register('email', {
                      required: true,
                      pattern: /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/  //eslint-disable-line
                    })}
                  />
                  <label htmlFor="loginEmail">Email</label>
                  {
                    errors.email?.type === "required" && 
                    <div className="formFieldError">
                      Please enter your email address.
                    </div>
                  }
                  {
                    errors.email?.type === "pattern" && 
                    <div className="formFieldError">
                      Please enter valid email address.
                    </div>
                  }
                  {
                    errorMsg &&
                    (typeof errorMsg === "object") &&
                    errorMsg?.email && 
                    <div className="formFieldError">
                      {errorMsg.email[0]}
                    </div>
                  }
                </div>
                <div className="form-floating password-field mb-4">
                  <input
                    type="password"
                    className="form-control"
                    placeholder="Password"
                    id="loginPassword"
                    {...register('password', {
                      required: true,
                      minLength: 8,
                      maxLength: 12,
                    })}
                  />
                  <span className="password-toggle">
                    <i className="uil uil-eye"></i>
                  </span>
                  <label htmlFor="loginPassword">Password</label>
                  {
                    errors.password?.type === "required" && 
                    <div className="formFieldError">
                      Please enter your password.
                    </div>
                  }
                  {
                    errors.password?.type === "minLength" && 
                    <div className="formFieldError">
                      Password length must be minimum 8 character.
                    </div>
                  }
                  {
                    errors.password?.type === "maxLength" && 
                    <div className="formFieldError">
                      Password length must be maximum 12 character.
                    </div>
                  }
                  {
                    errorMsg &&
                    (typeof errorMsg === "object") &&
                    errorMsg?.password && 
                    <div className="formFieldError">
                      {errorMsg.password[0]}
                    </div>
                  }
                </div>
                <p className="mb-3 text-end">
                  <Link to="/forgot-password" className="hover">Forgot Password?</Link>
                </p>      
                <button
                  disabled={loadLoginButton}
                  className="btn btn-primary rounded-pill btn-login w-100 mb-2"
                  type="submit"
                >
                  { loadLoginButton ? <Loader text='Log In' /> : 'Log In' }
                </button>

              </form>
              <p className="mb-0">
                <Link to="/login-through-otp" className="hover">Login through OTP</Link>
              </p>
              <br/>
              <p className="mb-0">Don't have an account? <Link to="/#" data-bs-target="#modal-signup" data-bs-toggle="modal" data-bs-dismiss="modal" className="hover">Sign up</Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const RegisterForm = () => {
  const [loadRegisterButton, setLoadRegisterButton] = useState(false);
  //const [successMsg, setSuccessMsg] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [timezoneData, setTimezoneData] = useState('');
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = useForm();
  const navigate = useNavigate();

  const loadTimezoneData = async () => {
    await requestApi(
      'GET',
      'get-timezone-list',
    ).then((result) => {
      setTimezoneData(result.data.response);
    }).catch((error) => {
      setErrorMsg(error?.response?.data?.message);
      //console.log(error);
    });
  };

  const handleRegistrationFormSubmit = async (values) => {
    isValid ? setLoadRegisterButton(true) : setLoadRegisterButton(false)

    await requestApi(
      'POST',
      'user-register',
      {},
      {},
      values
    ).then((result) => {
      //setSuccessMsg('Registration successfully completed.Please wait while we redirecting to dashboard page.');
      localStorage.setItem('ya-user-access-token', result.data.response);
      
      setTimeout(() => {
        setLoadRegisterButton(false);
      }, 1500);
      setErrorMsg('');
      reset();

      setTimeout(() => {
        document.getElementById("registerModalCloseBtn").click();
        navigate('/dashboard');
      }, 2000);
    }).catch((error) => {
      setErrorMsg(error?.response?.data?.message);
      setLoadRegisterButton(false);
    });
  };

  useEffect(() => {
    if (timezoneData === '') {
      loadTimezoneData();  
    }
  }, [timezoneData]);

  return (
    <>
      <div className="modal fade" id="modal-signup" tabIndex="-1">
        <div className="modal-dialog modal-dialog-centered modal-md">
          <div className="modal-content text-center">
            <div className="modal-body">
              <button type="button" id="registerModalCloseBtn" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              <h1 className="mb-3 display-5 text-start">Sign up</h1>
              <p className="lead mb-6 text-start">Registration takes less than a minute.</p>
              
              {/*
                successMsg &&
                <div className="alert alert-success alert-icon alert-dismissible fade show text-start" role="alert">
                  <i className="uil uil-check"></i>{successMsg} <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                </div>  
              */}
              {
                errorMsg &&
                (typeof errorMsg === "string") &&
                <div className="alert alert-danger alert-icon alert-dismissible fade show text-start" role="alert">
                  <i className="uil uil-exclamation-triangle"></i>{errorMsg} <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                </div>  
              }
              
              <form
                className="text-start mb-3"
                onSubmit={handleSubmit(handleRegistrationFormSubmit)}
              >
                <div className="form-floating mb-4">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Name"
                    id="userName"
                    {...register('fullName', {
                      required: true,
                      maxLength: 255,
                    })}
                  />
                  <label htmlFor="userName">Name</label>
                  {
                    errors.fullName?.type === "required" && 
                    <div className="formFieldError">
                      Please enter your name.
                    </div>
                  }
                  {
                    errors.fullName?.type === "maxLength" && 
                    <div className="formFieldError">
                      Name length must be maximum 255 character.
                    </div>
                  }
                  {
                    errorMsg &&
                    (typeof errorMsg === "object") &&
                    errorMsg?.fullName && 
                    <div className="formFieldError">
                      {errorMsg.fullName[0]}
                    </div>
                  }
                </div>
                <div className="form-floating mb-4">
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Email"
                    id="email"
                    {...register('email', {
                      required: true,
                      pattern: /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/ //eslint-disable-line
                    })}
                  />
                  <label htmlFor="email">Email</label>
                  {
                    errors.email?.type === "required" && 
                    <div className="formFieldError">
                      Please enter your email address.
                    </div>
                  }
                  {
                    errors.email?.type === "pattern" && 
                    <div className="formFieldError">
                      Please enter valid email address.
                    </div>
                  }
                  {
                    errorMsg &&
                    (typeof errorMsg === "object") &&
                    errorMsg?.email && 
                    <div className="formFieldError">
                      {errorMsg.email[0]}
                    </div>
                  }
                </div>
                <div className="form-floating password-field mb-4">
                  <input
                    type="password"
                    className="form-control"
                    placeholder="Password"
                    id="password"
                    {...register('password', {
                      required: true,
                      minLength: 8,
                      maxLength: 12,
                    })}
                  />
                  <span className="password-toggle">
                    <i className="uil uil-eye"></i>
                  </span>
                  <label htmlFor="password">Password (8-12 charcters)</label>
                  {
                    errors.password?.type === "required" && 
                    <div className="formFieldError">
                      Please enter your password.
                    </div>
                  }
                  {
                    errors.password?.type === "minLength" && 
                    <div className="formFieldError">
                      Password length must be minimum 8 character.
                    </div>
                  }
                  {
                    errors.password?.type === "maxLength" && 
                    <div className="formFieldError">
                      Password length must be maximum 12 character.
                    </div>
                  }
                  {
                    errorMsg &&
                    (typeof errorMsg === "object") &&
                    errorMsg?.password && 
                    <div className="formFieldError">
                      {errorMsg.password[0]}
                    </div>
                  }
                </div>
                <div className="form-select-wrapper mb-4">
                  <select
                    className="form-select"
                    aria-label="Timezone"
                    {...register("timeZone", { required: true })}
                  >
                    <option value="">Time zone</option>
                    {
                      timezoneData &&
                      Object.keys(timezoneData).map((value, index) => {
                        return (
                          <option
                            key={`timezone_${index}`}
                            value={value}
                          >
                            {value}
                          </option>
                        );
                      })
                    }
                  </select>
                  {
                    errors.timeZone?.type === "required" && 
                    <div className="formFieldError">
                      Please select your timezone.
                    </div>
                  }
                  {
                    errorMsg &&
                    (typeof errorMsg === "object") &&
                    errorMsg?.timeZone && 
                    <div className="formFieldError">
                      {errorMsg.timeZone[0]}
                    </div>
                  }
                </div>
                <div className="form-check mb-4">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="agreeCheckBox"
                    {...register("agreeCheckBox", { required: true })}
                  />
                  <label className="form-check-label" htmlFor="agreeCheckBox">I agree <Link to="/terms-of-use" className="hover">Term of use</Link> and <Link to="/privacy-policy" className="hover">Privacy policy</Link>
                  </label>
                  {
                    errors.agreeCheckBox?.type === "required" && 
                    <div className="formFieldError">
                      Please accept terms and privacy policy.
                    </div>
                  }
                </div>
                <button
                  disabled={loadRegisterButton}
                  className="btn btn-primary rounded-pill btn-login w-100 mb-2"
                  type="submit"
                >
                  { loadRegisterButton ? <Loader text='Sign Up' /> : 'Sign Up' }
                </button>
              </form>
              <div className="divider-icon my-4">or</div>
              <p className="mb-0">Already have an account? <Link to="/#" data-bs-target="#modal-login" data-bs-toggle="modal" data-bs-dismiss="modal" className="hover">Login</Link> here </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export const LoginRegister = () => {
  return (
    <>
      <LoginForm />

      <RegisterForm />
    </>
	);
};

export const PersonalInformation = ({
  userName,
  publicProfileData
}) => {
  const [personalInformationSubmitButton, setPersonalInformationSubmitButton] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [sportNames, setSportNames] = useState('');
  const [countryData, setCountryData] = useState('');
  const [stateData, setStateData] = useState('');
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isValid },
  } = useForm();
  const navigate = useNavigate();

  const loadSportsName = async () => {
    await requestApi(
      'GET',
      'get-sports-name-list',
    ).then((result) => {
      setSportNames(result.data.response);
    }).catch((error) => {
      setErrorMessage(error?.response?.data?.message);
    });
  };

  const loadCountryData = async () => {
    setErrorMessage('');
    await requestApi(
      'GET',
      'get-country-list',
    ).then((result) => {
      setCountryData(result.data.response);
    }).catch((error) => {
      setErrorMessage(error?.response?.data?.message);
    });
  };

  const loadCountryStateData = async (value) => {
    setErrorMessage('');
    setStateData('');
    if (value && value !== '') {
      await requestApi(
        'GET',
        'get-country-state-list',
        {},
        { 'country': value },
      ).then((result) => {
        setStateData(result.data.response);
      }).catch((error) => {
        setErrorMessage(error?.response?.data?.message);
      });
    }
  };

  const handlePersonalInformationFormSubmit = async (values) => {
    isValid ? setPersonalInformationSubmitButton(true) : setPersonalInformationSubmitButton(false)
    
    await requestApi(
      'POST',
      'update-user-athlete-profile/' + userName,
      { 'Authorization' : `Bearer ${localStorage.getItem('ya-user-access-token')}` },
      {},
      values
    ).then((result) => {
      setSuccessMessage('Your personal information updated successfully.Redirecting to player page...');
      // setTimeout(() => {
      //   setPersonalInformationSubmitButton(false);
      // }, 1500);
      
      setPersonalInformationSubmitButton(false);
      setErrorMessage('');

      setTimeout(() => {
        if (values.personalInformation.name !== publicProfileData.personalInformation.name) {
          navigate('/player/' + result.data.response.publicProfileName);
          navigate(0);
        } else {
          navigate(0);
        }
      }, 500);
    }).catch((error) => {
      setSuccessMessage('');
      setErrorMessage(error?.response?.data?.message);
      setPersonalInformationSubmitButton(false);
    });
  };

  useEffect(() => {
    if (publicProfileData !== ''
        && sportNames === ''
        && countryData === ''
    ) {
      loadCountryData();
      loadSportsName();
    }

    setValue('personalInformation.name', publicProfileData?.personalInformation?.name);
    setValue('personalInformation.email', publicProfileData?.personalInformation?.email);
    setValue('personalInformation.gender', publicProfileData?.personalInformation?.gender);
    setValue('personalInformation.mobileNo', publicProfileData?.personalInformation?.mobileNo);
    setValue('personalInformation.sportName', publicProfileData?.personalInformation?.sportName);
    setValue('homeAddress.country', publicProfileData?.homeAddress?.country);
    setValue('homeAddress.state', publicProfileData?.homeAddress?.state);
    setValue('homeAddress.city', publicProfileData?.homeAddress?.city);
    setValue('athleticInformation.heightInFeat', publicProfileData?.athleticInformation?.heightInFeat);
    setValue('athleticInformation.heightInInch', publicProfileData?.athleticInformation?.heightInInch);
    setValue('athleticInformation.weight', publicProfileData?.athleticInformation?.weight);
    setValue('athleticInformation.position', publicProfileData?.athleticInformation?.position);
    setValue('athleticInformation.dominantHand', publicProfileData?.athleticInformation?.dominantHand);
    setValue('schoolInformation.highSchool.name', publicProfileData?.schoolInformation?.highSchool?.name);
    setValue('schoolInformation.highSchool.graduationYear', publicProfileData?.schoolInformation?.highSchool?.graduationYear);
    setValue('schoolInformation.middleSchool.name', publicProfileData?.schoolInformation?.middleSchool?.name);
    setValue('schoolInformation.middleSchool.graduationYear', publicProfileData?.schoolInformation?.middleSchool?.graduationYear);
    setValue('socialMediaInfo.instagramURL', publicProfileData?.socialMediaInfo?.instagramURL);
    setValue('socialMediaInfo.twitterURL', publicProfileData?.socialMediaInfo?.twitterURL);
    setValue('socialMediaInfo.facebookURL', publicProfileData?.socialMediaInfo?.facebookURL);

    if (publicProfileData?.homeAddress?.country !== undefined
        && publicProfileData?.homeAddress?.country !== null
    ) {
      loadCountryStateData(publicProfileData.homeAddress.country);
      setTimeout(() => {
        setValue('homeAddress.state', publicProfileData?.homeAddress?.state);
      }, 1000);
    }
  }, [
    publicProfileData,
    sportNames,
    countryData,
    setValue
  ]);

  return (
    <>
      <div className="modal fade" id="modal-personalInfomation" tabIndex="-1">
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <div className="modal-body">
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              <h2 className="mb-8">Profile</h2>

              {
                successMessage &&
                <div className="alert alert-success alert-icon alert-dismissible fade show text-start" role="alert">
                  <i className="uil uil-check"></i>{successMessage} <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                </div>  
              }
              {
                errorMessage &&
                (typeof errorMessage === "string") &&
                <div className="alert alert-danger alert-icon alert-dismissible fade show text-start" role="alert">
                  <i className="uil uil-exclamation-triangle"></i>{errorMessage} <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                </div>  
              }

              <form
                className="text-start mb-3"
                onSubmit={handleSubmit(handlePersonalInformationFormSubmit)}
              >
                <h4>My information</h4>
                <div className="row g-3 mb-8">
                  <div className="col-md-6">
                    <div className="form-floating">
                      <input
                        id="name"
                        className="form-control"
                        placeholder="Name *"
                        {...register('personalInformation.name', {
                          required: true,
                          maxLength: 255,
                        })}
                      />
                      <label htmlFor="name">Name *</label>
                      {
                        errors.personalInformation?.name?.type === "required" && 
                        <div className="formFieldError">
                          Please enter your name.
                        </div>
                      }
                      {
                        errors.personalInformation?.name?.type === "maxLength" && 
                        <div className="formFieldError">
                          Name may not be greater than 255 character.
                        </div>
                      }
                      {
                        errorMessage &&
                        (typeof errorMessage === "object") &&
                        errorMessage['personalInformation.name'] && 
                        <div className="formFieldError">
                          {errorMessage['personalInformation.name'][0]}
                        </div>
                      }
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-floating">
                      <input
                        id="profileEmail"
                        type="email"
                        className="form-control"
                        placeholder="Email *"
                        {...register('personalInformation.email', {
                          required: true,
                          maxLength: 255,
                          pattern: /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/, //eslint-disable-line
                        })}
                      />
                      <label htmlFor="profileEmail">Email *</label>
                      {
                        errors.personalInformation?.email?.type === "required" && 
                        <div className="formFieldError">
                          Please enter your email address.
                        </div>
                      }
                      {
                        errors.personalInformation?.email?.type === "pattern" && 
                        <div className="formFieldError">
                          Please enter valid email address.
                        </div>
                      }
                      {
                        errors.personalInformation?.email?.type === "maxLength" && 
                        <div className="formFieldError">
                          Email may not be greater than 255 character.
                        </div>
                      }
                      {
                        errorMessage &&
                        (typeof errorMessage === "object") &&
                        errorMessage['personalInformation.email'] && 
                        <div className="formFieldError">
                          {errorMessage['personalInformation.email'][0]}
                        </div>
                      }
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-select-wrapper">
                      <select
                        className="form-select"
                        aria-label="select_gender"
                        {...register('personalInformation.gender', {
                          required: true,
                        })}
                      >
                        <option value="">Gender *</option>
                        <option value="M">Male</option>
                        <option value="F">Female</option>
                      </select>
                      {
                        errors.personalInformation?.gender?.type === "required" && 
                        <div className="formFieldError">
                          Please select your gender.
                        </div>
                      }
                      {
                        errorMessage &&
                        (typeof errorMessage === "object") &&
                        errorMessage['personalInformation.gender'] && 
                        <div className="formFieldError">
                          {errorMessage['personalInformation.gender'][0]}
                        </div>
                      }
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-floating">
                      <input
                        id="mobile"
                        className="form-control"
                        placeholder="Mobile *"
                        {...register('personalInformation.mobileNo', {
                          required: true,
                          minLength: 10,
                          maxLength: 10,
                          pattern: /^[0-9]+$/,
                        })}
                      />
                      <label htmlFor="mobile">Mobile *</label>
                      {
                        errors.personalInformation?.mobileNo?.type === "required" && 
                        <div className="formFieldError">
                          Please enter mobile number.
                        </div>
                      }
                      {
                        errors.personalInformation?.mobileNo?.type === "minLength" && 
                        <div className="formFieldError">
                          Mobile number must be minimum 10 digit.
                        </div>
                      }
                      {
                        errors.personalInformation?.mobileNo?.type === "maxLength" && 
                        <div className="formFieldError">
                          Mobile number must be maximum 10 digit.
                        </div>
                      }
                      {
                        errors.personalInformation?.mobileNo?.type === "pattern" && 
                        <div className="formFieldError">
                          Mobile number must be number.
                        </div>
                      }
                      {
                        errorMessage &&
                        (typeof errorMessage === "object") &&
                        errorMessage['personalInformation.mobileNo'] && 
                        <div className="formFieldError">
                          {errorMessage['personalInformation.mobileNo'][0]}
                        </div>
                      }
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-select-wrapper">
                      <select
                        className="form-select"
                        aria-label="select_sport"
                        {...register('personalInformation.sportName', {
                          required: true,
                        })}
                      >
                        <option value="">Sports *</option>
                        {
                          sportNames &&
                          sportNames.map((value, index) => {
                            return (
                              <option
                                key={`sportName_${index}`}
                                value={value}
                              >
                                {value}
                              </option>
                            )
                          })
                        }
                      </select>
                      {
                        errors.personalInformation?.sportName?.type === "required" && 
                        <div className="formFieldError">
                          Please select sport.
                        </div>
                      }
                      {
                        errorMessage &&
                        (typeof errorMessage === "object") &&
                        errorMessage['personalInformation.sportName'] && 
                        <div className="formFieldError">
                          {errorMessage['personalInformation.sportName'][0]}
                        </div>
                      }
                    </div>
                  </div>
                </div>
                <h4>Hometown</h4>
                <div className="row g-3 mb-8">
                  <div className="col-md-6">
                    <div className="form-select-wrapper">
                      <select
                        className="form-select"
                        aria-label="select_country"
                        {...register('homeAddress.country', {
                          required: true,
                          onChange: ({ target }) => loadCountryStateData(target.value),
                        })}
                      >
                        <option value="">Country</option>
                        {
                          countryData &&
                          Object.entries(countryData).map((value, index) => {
                            return (
                              <option
                                key={`country_${index}`}
                                value={value[0]}
                              >
                              {value[1]}
                              </option>
                            );
                          })
                        }
                      </select>
                      {
                        errors.homeAddress?.country?.type === "required" && 
                        <div className="formFieldError">
                          Please select your country.
                        </div>
                      }
                      {
                        errorMessage &&
                        (typeof errorMessage === "object") &&
                        errorMessage['homeAddress.country'] && 
                        <div className="formFieldError">
                          {errorMessage['homeAddress.country'][0]}
                        </div>
                      }
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-select-wrapper">
                      <select
                        className="form-select"
                        aria-label="select_state"
                        {...register('homeAddress.state', {
                          required: true,
                        })}
                      >
                        <option value="">State *</option>
                        {
                          stateData &&
                          Object.entries(stateData).map((value, index) => {
                            return (
                              <option
                                key={`state_${index}`}
                                value={value[0]}
                              >
                              {value[1]}
                              </option>
                            );
                          })
                        }
                      </select>
                      {
                        errors.homeAddress?.state?.type === "required" && 
                        <div className="formFieldError">
                          Please select your state.
                        </div>
                      }
                      {
                        errorMessage &&
                        (typeof errorMessage === "object") &&
                        errorMessage['homeAddress.state'] && 
                        <div className="formFieldError">
                          {errorMessage['homeAddress.state'][0]}
                        </div>
                      }
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-floating">
                      <input
                        id="city"
                        className="form-control"
                        placeholder="City *"
                        {...register('homeAddress.city', {
                          required: true,
                        })}
                      />
                      <label htmlFor="city">City *</label>
                      {
                        errors.homeAddress?.city?.type === "required" && 
                        <div className="formFieldError">
                          Please enter city.
                        </div>
                      }
                      {
                        errorMessage &&
                        (typeof errorMessage === "object") &&
                        errorMessage['homeAddress.city'] && 
                        <div className="formFieldError">
                          {errorMessage['homeAddress.city'][0]}
                        </div>
                      }
                    </div>
                  </div>
                </div>
                <h4>Athletic information</h4>
                <div className="row g-3 mb-8">
                  <div className="col-md-6">
                    <div>Height</div>
                    <div className="row g-3">
                      <div className="col-6">
                        <div className="form-floating">
                          <input
                            id="heightInFeat"
                            className="form-control"
                            placeholder="Ft *"
                            {...register('athleticInformation.heightInFeat', {
                              required: true,
                              min: 3,
                              max: 7,
                              pattern: /^\d+(\.\d+)?$/,
                            })}
                          />
                          <label htmlFor="heightInFeat">Ft *</label>
                          {
                            errors.athleticInformation?.heightInFeat?.type === "required" && 
                            <div className="formFieldError">
                              Please enter your height in feat.
                            </div>
                          }
                          {
                            errors.athleticInformation?.heightInFeat?.type === "min" && 
                            <div className="formFieldError">
                              Height in feat must be minimum 3.
                            </div>
                          }
                          {
                            errors.athleticInformation?.heightInFeat?.type === "max" && 
                            <div className="formFieldError">
                              Height in feat must be maximum 7.
                            </div>
                          }
                          {
                            errors.athleticInformation?.heightInFeat?.type === "pattern" && 
                            <div className="formFieldError">
                              Height in feat accept only number with decimal points.
                            </div>
                          }
                          {
                            errorMessage &&
                            (typeof errorMessage === "object") &&
                            errorMessage['athleticInformation.heightInFeat'] && 
                            <div className="formFieldError">
                              {errorMessage['athleticInformation.heightInFeat'][0]}
                            </div>
                          }
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="form-floating">
                          <input
                            id="heightInInch"
                            className="form-control"
                            placeholder="In *"
                            {...register('athleticInformation.heightInInch', {
                              required: true,
                              min: 0,
                              max: 11,
                              pattern: /^\d+(\.\d+)?$/,
                            })}
                          />
                          <label htmlFor="heightInInch">In *</label>
                          {
                            errors.athleticInformation?.heightInInch?.type === "required" && 
                            <div className="formFieldError">
                              Please enter your height in inch.
                            </div>
                          }
                          {
                            errors.athleticInformation?.heightInInch?.type === "min" && 
                            <div className="formFieldError">
                              Height in inch must be minimum 0.
                            </div>
                          }
                          {
                            errors.athleticInformation?.heightInInch?.type === "max" && 
                            <div className="formFieldError">
                              Height in inch must be maximum 11.
                            </div>
                          }
                          {
                            errors.athleticInformation?.heightInInch?.type === "pattern" && 
                            <div className="formFieldError">
                              Height in inch accept only number with decimal points.
                            </div>
                          }
                          {
                            errorMessage &&
                            (typeof errorMessage === "object") &&
                            errorMessage['athleticInformation.heightInInch'] && 
                            <div className="formFieldError">
                              {errorMessage['athleticInformation.heightInInch'][0]}
                            </div>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div>Weight</div>
                    <div className="form-floating">
                      <input
                        id="weight"
                        className="form-control"
                        placeholder="Lb *"

                        {...register('athleticInformation.weight', {
                          required: true,
                          min: 0,
                          max: 500,
                          pattern: /^\d+(\.\d+)?$/,
                        })}
                      />
                      <label htmlFor="weight">Lb *</label>
                      {
                        errors.athleticInformation?.weight?.type === "required" && 
                        <div className="formFieldError">
                          Please enter your weight.
                        </div>
                      }
                      {
                        errors.athleticInformation?.weight?.type === "min" && 
                        <div className="formFieldError">
                          Weight must be minimum 0.
                        </div>
                      }
                      {
                        errors.athleticInformation?.weight?.type === "max" && 
                        <div className="formFieldError">
                          Weight must be maximum 500.
                        </div>
                      }
                      {
                        errors.athleticInformation?.weight?.type === "pattern" && 
                        <div className="formFieldError">
                          Weight accept only number with decimal points.
                        </div>
                      }
                      {
                        errorMessage &&
                        (typeof errorMessage === "object") &&
                        errorMessage['athleticInformation.weight'] && 
                        <div className="formFieldError">
                          {errorMessage['athleticInformation.weight'][0]}
                        </div>
                      }
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div>Position</div>
                    <div className="form-floating">
                      <input
                        id="position"
                        className="form-control"
                        placeholder="Position *"
                        {...register('athleticInformation.position', {
                          required: true,
                          maxLength: 255,
                        })}
                      />
                      <label htmlFor="position">Position *</label>
                      {
                        errors.athleticInformation?.position?.type === "required" && 
                        <div className="formFieldError">
                          Please enter your position.
                        </div>
                      }
                      {
                        errors.athleticInformation?.position?.type === "maxLength" && 
                        <div className="formFieldError">
                          Position may not be greater than 255 character.
                        </div>
                      }
                      {
                        errorMessage &&
                        (typeof errorMessage === "object") &&
                        errorMessage['athleticInformation.position'] && 
                        <div className="formFieldError">
                          {errorMessage['athleticInformation.position'][0]}
                        </div>
                      }
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div>Dominant hand *</div>
                    <div className="form-select-wrapper">
                      <select
                        className="form-select"
                        aria-label="dominant_hand"
                        label="Dominant Hand *"
                        {...register('athleticInformation.dominantHand', {
                          required: true,
                        })}
                      >
                        <option value="left">Left</option>
                        <option value="right">Right</option>
                      </select>
                      {
                        errors.athleticInformation?.dominantHand?.type === "required" && 
                        <div className="formFieldError">
                          Please enter your dominant hand.
                        </div>
                      }
                      {
                        errorMessage &&
                        (typeof errorMessage === "object") &&
                        errorMessage['athleticInformation.dominantHand'] && 
                        <div className="formFieldError">
                          {errorMessage['athleticInformation.dominantHand'][0]}
                        </div>
                      }
                    </div>
                  </div>
                </div>
                <h4>School</h4>
                <div className="row g-3 mb-8">
                  <div className="col-md-8">
                    <div className="form-floating">
                      <input
                        className="form-control"
                        placeholder="High School *"
                        id="highSchool"
                        {...register('schoolInformation.highSchool.name', {
                          required: true,
                          maxLength: 255,
                        })}
                      />
                      <label htmlFor="highSchool">High School *</label>
                      {
                        errors.schoolInformation?.highSchool?.name?.type === "required" && 
                        <div className="formFieldError">
                          Please enter high school name.
                        </div>
                      }
                      {
                        errors.schoolInformation?.highSchool?.name?.type === "maxLength" && 
                        <div className="formFieldError">
                          High school name may not be greater than 255 character.
                        </div>
                      }
                      {
                        errorMessage &&
                        (typeof errorMessage === "object") &&
                        errorMessage['schoolInformation.highSchool.name'] && 
                        <div className="formFieldError">
                          {errorMessage['schoolInformation.highSchool.name'][0]}
                        </div>
                      }
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-select-wrapper">
                      <select
                        className="form-select"
                        aria-label="select_hightschool_graduation_year"
                        {...register('schoolInformation.highSchool.graduationYear', {
                          required: true,
                        })}
                      >
                        <option value="">Graduation Year *</option>
                        <option value="2024">2024</option>
                        <option value="2025">2025</option>
                      </select>
                      {
                        errors.schoolInformation?.highSchool?.graduationYear?.type === "required" && 
                        <div className="formFieldError">
                          Please select your high school graduation year.
                        </div>
                      }
                      {
                        errorMessage &&
                        (typeof errorMessage === "object") &&
                        errorMessage['schoolInformation.highSchool.graduationYear'] && 
                        <div className="formFieldError">
                          {errorMessage['schoolInformation.highSchool.graduationYear'][0]}
                        </div>
                      }
                    </div>
                  </div>
                  <div className="col-md-8">
                    <div className="form-floating">
                      <input
                        id="middleSchool"
                        className="form-control"
                        placeholder="Middle School *"
                        {...register('schoolInformation.middleSchool.name', {
                          required: true,
                          maxLength: 255,
                        })}
                      />
                      <label htmlFor="middleSchool">Middle School *</label>
                      {
                        errors.schoolInformation?.middleSchool?.name?.type === "required" && 
                        <div className="formFieldError">
                          Please enter middle school name.
                        </div>
                      }
                      {
                        errors.schoolInformation?.middleSchool?.name?.type === "maxLength" && 
                        <div className="formFieldError">
                          Middle school name may not be greater than 255 character.
                        </div>
                      }
                      {
                        errorMessage &&
                        (typeof errorMessage === "object") &&
                        errorMessage['schoolInformation.middleSchool.name'] && 
                        <div className="formFieldError">
                          {errorMessage['schoolInformation.middleSchool.name'][0]}
                        </div>
                      }
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-select-wrapper">
                      <select
                        className="form-select"
                        aria-label="select_middleschool_graduation_year"
                        {...register('schoolInformation.middleSchool.graduationYear', {
                          required: true,
                        })}
                      >
                        <option value="">Graduation Year *</option>
                        <option value="2024">2024</option>
                        <option value="2025">2025</option>
                      </select>
                      {
                        errors.schoolInformation?.middleSchool?.graduationYear?.type === "required" && 
                        <div className="formFieldError">
                          Please select your middle school graduation year.
                        </div>
                      }
                      {
                        errorMessage &&
                        (typeof errorMessage === "object") &&
                        errorMessage['schoolInformation.middleSchool.graduationYear'] && 
                        <div className="formFieldError">
                          {errorMessage['schoolInformation.middleSchool.graduationYear'][0]}
                        </div>
                      }
                    </div>
                  </div>
                </div>
                <h4>Social media</h4>
                <div className="row g-3 mb-8">
                  <div className="col-md-12">
                    <div className="form-floating">
                      <input
                        id="instagramURL"
                        type="url"
                        className="form-control"
                        placeholder="Instagram URL *"
                        {...register('socialMediaInfo.instagramURL', {
                          required: true,
                        })}
                      />
                      <label htmlFor="instagramURL">Instagram URL *</label>
                      {
                        errors.socialMediaInfo?.instagramURL?.type === "required" && 
                        <div className="formFieldError">
                          Please enter instagram url.
                        </div>
                      }
                      {
                        errorMessage &&
                        (typeof errorMessage === "object") &&
                        errorMessage['socialMediaInfo.instagramURL'] && 
                        <div className="formFieldError">
                          {errorMessage['socialMediaInfo.instagramURL'][0]}
                        </div>
                      }
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-floating">
                      <input
                        id="twitterURL"
                        type="url"
                        className="form-control"
                        placeholder="Twitter URL *"
                        {...register('socialMediaInfo.twitterURL', {
                          required: true,
                        })}
                      />
                      <label htmlFor="twitterURL">Twitter URL *</label>
                      {
                        errors.socialMediaInfo?.twitterURL?.type === "required" && 
                        <div className="formFieldError">
                          Please enter twitter url.
                        </div>
                      }
                      {
                        errorMessage &&
                        (typeof errorMessage === "object") &&
                        errorMessage['socialMediaInfo.twitterURL'] && 
                        <div className="formFieldError">
                          {errorMessage['socialMediaInfo.twitterURL'][0]}
                        </div>
                      }
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-floating">
                      <input
                        id="facebookURL"
                        type="url"
                        className="form-control"
                        placeholder="Facebook URL *"
                        {...register('socialMediaInfo.facebookURL', {
                          required: true,
                        })}
                      />
                      <label htmlFor="facebookURL">Facebook URL *</label>
                      {
                        errors.socialMediaInfo?.facebookURL?.type === "required" && 
                        <div className="formFieldError">
                          Please enter facebook url.
                        </div>
                      }
                      {
                        errorMessage &&
                        (typeof errorMessage === "object") &&
                        errorMessage['socialMediaInfo.facebookURL'] && 
                        <div className="formFieldError">
                          {errorMessage['socialMediaInfo.facebookURL'][0]}
                        </div>
                      }
                    </div>
                  </div>
                </div>
                <button
                  disabled={personalInformationSubmitButton}
                  className="btn btn-primary rounded-pill w-100 mb-2"
                  type="submit"
                >
                  { personalInformationSubmitButton ? <Loader text='Save' /> : 'Save' }
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export const AboutMe = ({
  userName,
  publicProfileData
}) => {
  const [aboutMeSubmitButton, setAboutMeSubmitButton] = useState(false);
  const [successMsg, setSuccessMsg] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isValid },
  } = useForm();
  const navigate = useNavigate();

  const handleAboutMeFormSubmit = async (values) => {
    isValid ? setAboutMeSubmitButton(true) : setAboutMeSubmitButton(false)

    await requestApi(
      'POST',
      `update-user-athlete-about-me/${userName}`,
      { 'Authorization' : `Bearer ${localStorage.getItem('ya-user-access-token')}` },
      {},
      values
    ).then((result) => {
      setSuccessMsg('Your about me information updated successfully.Redirecting to player page...');
      setErrorMsg('');
      setAboutMeSubmitButton(false);

      // await setTimeout(() => {
      //   setAboutMeSubmitButton(false);
      // }, 1500);

      navigate(0);
    }).catch((error) => {
      setSuccessMsg('');
      setErrorMsg(error?.response?.data?.message);
      setAboutMeSubmitButton(false);
    });
  };

  useEffect(() => {
    if (publicProfileData !== undefined
        && publicProfileData !== ''
    ) {
      setValue('aboutMe', publicProfileData?.aboutMe);
    }
  }, [publicProfileData, setValue]);

  return (
    <>
      <div className="modal fade" id="modal-aboutMe" tabIndex="-1">
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content text-center">
            <div className="modal-body">
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              <h2 className="mb-3 text-start">About Me</h2>

              {
                successMsg &&
                <div className="alert alert-success alert-icon alert-dismissible fade show text-start" role="alert">
                  <i className="uil uil-check"></i>{successMsg} <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                </div>  
              }
              {
                errorMsg &&
                (typeof errorMsg === "string") &&
                <div className="alert alert-danger alert-icon alert-dismissible fade show text-start" role="alert">
                  <i className="uil uil-exclamation-triangle"></i>{errorMsg} <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                </div>  
              }

              <form
                className="text-start mb-3"
                onSubmit={handleSubmit(handleAboutMeFormSubmit)}
              >
                <div className="form-floating mb-4">
                  <textarea
                    id="aboutMe"
                    className="form-control textAreaCustomSize"
                    placeholder="About Me"
                    {...register('aboutMe', {
                      required: true,
                    })}
                  ></textarea>
                  <label htmlFor="aboutMe">About Me</label>
                  {
                    errors.aboutMe?.type === "required" && 
                    <div className="formFieldError">
                      Please enter something about yourself.
                    </div>
                  }
                  {
                    errorMsg &&
                    (typeof errorMsg === "object") &&
                    errorMsg?.aboutMe && 
                    <div className="formFieldError text-center">
                      {errorMsg?.aboutMe}
                    </div>
                  }
                </div>
                <button
                  disabled={aboutMeSubmitButton}
                  className="btn btn-primary rounded-pill w-100 mb-2"
                  type="submit"
                >
                  { aboutMeSubmitButton ? <Loader text='Save' /> : 'Save' }
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export const AddNewVideoForm = ({ userName }) => {
  const [addVideoSubmitButton, setAddVideoSubmitButton] = useState(false);
  const [successMsg, setSuccessMsg] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [uploadVideo, setUploadVideo] = useState('');
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm();
  const navigate = useNavigate();

  const handleAddVideoFormSubmit = async (values) => {
    isValid ? setAddVideoSubmitButton(true) : setAddVideoSubmitButton(false)
    values.video = uploadVideo;

    await requestApi(
      'POST',
      `add-athlete-user-video/${userName}`,
      {
        'Authorization' : `Bearer ${localStorage.getItem('ya-user-access-token')}`,
        'Content-Type' : 'multipart/form-data'
      },
      {},
      values
    ).then((result) => {
      setSuccessMsg('Your new video added successfully.Redirecting to video page...');
      setErrorMsg('');
      setAddVideoSubmitButton(false);

      setTimeout(() => {
        navigate(0);
      }, 500);
    }).catch((error) => {
      setSuccessMsg('');
      setErrorMsg(error?.response?.data?.message);
      setAddVideoSubmitButton(false);
    });
  };

  return (
    <>
      <div className="modal fade" id="modal-addNewVideo" tabIndex="-1">
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content text-center">
            <div className="modal-body">
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              <h2 className="mb-3 text-start">Add New Video</h2>
              
              {
                successMsg &&
                <div className="alert alert-success alert-icon alert-dismissible fade show text-start" role="alert">
                  <i className="uil uil-check"></i>{successMsg} <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                </div>  
              }
              {
                errorMsg &&
                (typeof errorMsg === "string") &&
                <div className="alert alert-danger alert-icon alert-dismissible fade show text-start" role="alert">
                  <i className="uil uil-exclamation-triangle"></i>{errorMsg} <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                </div>  
              }

              <form
                className="text-start mb-3"
                onSubmit={handleSubmit(handleAddVideoFormSubmit)}
              >
                <div className="form-floating mb-4">
                  <input
                    id="video"
                    type="file"
                    accept=".mp4,.avi,.3gp"
                    className="form-control"
                    placeholder="Video"
                    {...register('video', {
                      required: true,
                      onChange: ({ target }) => {
                        setUploadVideo(target.files[0]);
                      }
                    })}
                  />
                  <label htmlFor="video">Video</label>
                  {
                    errors.video?.type === "required" && 
                    <div className="formFieldError">
                      Please upload valid video.
                    </div>
                  }
                  {
                    errorMsg &&
                    (typeof errorMsg === "object") &&
                    errorMsg?.video && 
                    <div className="formFieldError text-center">
                      {errorMsg?.video[0]}
                    </div>
                  }
                </div>
                <button
                  disabled={addVideoSubmitButton}
                  className="btn btn-primary rounded-pill btn-login w-100 mb-2"
                  type="submit"
                >
                  { addVideoSubmitButton ? <Loader text='Save' /> : 'Save' }
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export const UpdateVideoForm = ({ userName, updateVideoId }) => {
  const [updateVideoSubmitButton, setUpdateVideoSubmitButton] = useState(false);
  const [successMsg, setSuccessMsg] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [uploadVideo,setUploadVideo] = useState('');
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm();
  const navigate = useNavigate();

  const handleUpdateVideoFormSubmit = async (values) => {
    isValid ? setUpdateVideoSubmitButton(true) : setUpdateVideoSubmitButton(false)
    values.videoId = updateVideoId;
    values.video = uploadVideo;

    await requestApi(
      'POST',
      `update-athlete-user-video/${userName}`,
      {
        'Authorization' : `Bearer ${localStorage.getItem('ya-user-access-token')}`,
        'Content-Type' : 'multipart/form-data'
      },
      {},
      values
    ).then((result) => {
      setSuccessMsg('Your video updated successfully.Redirecting to video page...');
      setErrorMsg('');
      setUpdateVideoSubmitButton(false);

      setTimeout(() => {
        navigate(0);
      }, 500);
    }).catch((error) => {
      setSuccessMsg('');
      setErrorMsg(error?.response?.data?.message);
      setUpdateVideoSubmitButton(false);
    });    
  };

  return (
    <>
      <div className="modal fade" id="modal-updateVideos" tabIndex="-1">
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content text-center">
            <div className="modal-body">
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              <h2 className="mb-3 text-start">Update Video</h2>

              {
                successMsg &&
                <div className="alert alert-success alert-icon alert-dismissible fade show text-start" role="alert">
                  <i className="uil uil-check"></i>{successMsg} <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                </div>  
              }
              {
                errorMsg &&
                (typeof errorMsg === "string") &&
                <div className="alert alert-danger alert-icon alert-dismissible fade show text-start" role="alert">
                  <i className="uil uil-exclamation-triangle"></i>{errorMsg} <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                </div>  
              }

              <form
                className="text-start mb-3"
                onSubmit={handleSubmit(handleUpdateVideoFormSubmit)}
              >
                <div className="form-floating mb-4">
                  <input
                    id="video"
                    type="file"
                    accept=".mp4,.avi,.3gp"
                    className="form-control"
                    placeholder="Video"
                    {...register('video', {
                      required: true,
                      onChange: ({ target }) => {
                        setUploadVideo(target.files[0]);
                      }
                    })}
                  />
                  <label htmlFor="video">Video</label>
                  {
                    errors.video?.type === "required" && 
                    <div className="formFieldError">
                      Please upload valid video.
                    </div>
                  }
                  {
                    errorMsg &&
                    (typeof errorMsg === "object") &&
                    errorMsg?.video && 
                    <div className="formFieldError text-center">
                      {errorMsg?.video[0]}
                    </div>
                  }
                </div>
            
                {/*<p className="text-center mb-8">
                  <Link to="#/" data-bs-toggle="modal" data-bs-target="#modal-addNewVideo"> Add New</Link>
                </p>*/}
                <button
                  disabled={updateVideoSubmitButton}
                  className="btn btn-primary rounded-pill btn-login w-100 mb-2"
                  type="submit"
                >
                  { updateVideoSubmitButton ? <Loader text='Update' /> : 'Update' }
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/*<AddNewVideoForm />*/}
    </>
  )
};

export const AddNewCareerHighlightForm = ({ userName }) => {
  const [addCareerHighlightSubmitButton, setAddCareerHighlightSubmitButton] = useState(false);
  const [successMsg, setSuccessMsg] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm();
  const navigate = useNavigate();

  const handleAddCareerHighlightFormSubmit = async (values) => {
    isValid ? setAddCareerHighlightSubmitButton(true) : setAddCareerHighlightSubmitButton(false)

    await requestApi(
      'POST',
      `add-athlete-user-award/${userName}`,
      { 'Authorization' : `Bearer ${localStorage.getItem('ya-user-access-token')}` },
      {},
      values
    ).then((result) => {
      setSuccessMsg('Your new career highlight added successfully.Redirecting to career highlight listing page...');
      setErrorMsg('');
      setAddCareerHighlightSubmitButton(false);

      setTimeout(() => {
        navigate(0);
      }, 500);
    }).catch((error) => {
      setSuccessMsg('');
      setErrorMsg(error?.response?.data?.message);
      setAddCareerHighlightSubmitButton(false);
    });
  };

  return (
    <>
      <div className="modal fade" id="modal-addNewCareerHighlight" tabIndex="-1">
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content text-center">
            <div className="modal-body">
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              <h2 className="mb-3 text-start">Add New Career Highlight</h2>
              
              {
                successMsg &&
                <div className="alert alert-success alert-icon alert-dismissible fade show text-start" role="alert">
                  <i className="uil uil-check"></i>{successMsg} <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                </div>  
              }
              {
                errorMsg &&
                (typeof errorMsg === "string") &&
                <div className="alert alert-danger alert-icon alert-dismissible fade show text-start" role="alert">
                  <i className="uil uil-exclamation-triangle"></i>{errorMsg} <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                </div>  
              }

              <form
                className="text-start mb-3"
                onSubmit={handleSubmit(handleAddCareerHighlightFormSubmit)}
              >
                <div className="form-floating mb-4">
                  <input
                    id="date"
                    type="month"
                    className="form-control"
                    placeholder="Date"
                    {...register('date', {
                      required: true,
                    })}
                  />
                  <label htmlFor="date">Date</label>
                  {
                    errors.date?.type === "required" && 
                    <div className="formFieldError">
                      Please select date.
                    </div>
                  }
                  {
                    errorMsg &&
                    (typeof errorMsg === "object") &&
                    errorMsg?.date && 
                    <div className="formFieldError">
                      {errorMsg?.date[0]}
                    </div>
                  }
                </div>
                <div className="form-floating mb-4">
                  <input
                    id="eventName"
                    type="text"
                    className="form-control"
                    placeholder="Event Name"
                    {...register('eventName', {
                      required: true,
                      maxLength: 150,
                    })}
                  />
                  <label htmlFor="eventName">Event Name</label>
                  {
                    errors.eventName?.type === "required" && 
                    <div className="formFieldError">
                      Please enter event name.
                    </div>
                  }
                  {
                    errors.eventName?.type === "maxLength" && 
                    <div className="formFieldError">
                      Event name length not be greater than 150 character.
                    </div>
                  }
                  {
                    errorMsg &&
                    (typeof errorMsg === "object") &&
                    errorMsg?.eventName && 
                    <div className="formFieldError">
                      {errorMsg?.eventName[0]}
                    </div>
                  }
                </div>
                <div className="form-floating mb-4">
                  <input
                    id="description"
                    type="text"
                    className="form-control"
                    placeholder="Description"
                    {...register('description', {
                      required: true,
                      maxLength: 150,
                    })}
                  />
                  <label htmlFor="description">Description</label>
                  {
                    errors.description?.type === "required" && 
                    <div className="formFieldError">
                      Please enter description.
                    </div>
                  }
                  {
                    errors.description?.type === "maxLength" && 
                    <div className="formFieldError">
                      Description length not be greater than 150 character.
                    </div>
                  }
                  {
                    errorMsg &&
                    (typeof errorMsg === "object") &&
                    errorMsg?.description && 
                    <div className="formFieldError">
                      {errorMsg?.description[0]}
                    </div>
                  }
                </div>
                <button
                  disabled={addCareerHighlightSubmitButton}
                  className="btn btn-primary rounded-pill btn-login w-100 mb-2"
                  type="submit"
                >
                  { addCareerHighlightSubmitButton ? <Loader text='Save' /> : 'Save' }
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export const UpdateCareerHighlightForm = ({
  userName,
  updateAwardId,
  updatedAwardData
}) => {
  const [updateCareerHighlightSubmitButton, setUpdateCareerHighlightSubmitButton] = useState(false);
  const [successMsg, setSuccessMsg] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isValid },
  } = useForm();
  const navigate = useNavigate();

  const handleUpdateCareerHighlightFormSubmit = async (values) => {
    isValid ? setUpdateCareerHighlightSubmitButton(true) : setUpdateCareerHighlightSubmitButton(false)
    values.awardId = updateAwardId;

    await requestApi(
      'POST',
      `update-athlete-user-award/${userName}`,
      { 'Authorization' : `Bearer ${localStorage.getItem('ya-user-access-token')}` },
      {},
      values
    ).then((result) => {
      setSuccessMsg('Your career highlight updated successfully.Redirecting to career highlight listing page...');
      setErrorMsg('');
      setUpdateCareerHighlightSubmitButton(false);

      setTimeout(() => {
        navigate(0);
      }, 500);
    }).catch((error) => {
      setSuccessMsg('');
      setErrorMsg(error?.response?.data?.message);
      setUpdateCareerHighlightSubmitButton(false);
    });
  };

  useEffect(() => {
    if (updatedAwardData !== undefined
        && updatedAwardData !== ''
    ) {
      setValue('date', updatedAwardData?.date);
      setValue('eventName', updatedAwardData?.eventName);
      setValue('description', updatedAwardData?.description);
    }
  }, [updatedAwardData, setValue]);

  return (
    <>
      <div className="modal fade" id="modal-updateCareerHighlight" tabIndex="-1">
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content text-center">
            <div className="modal-body">
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              <h2 className="mb-3 text-start">Update Career Highlight</h2>
              
              {
                successMsg &&
                <div className="alert alert-success alert-icon alert-dismissible fade show text-start" role="alert">
                  <i className="uil uil-check"></i>{successMsg} <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                </div>  
              }
              {
                errorMsg &&
                (typeof errorMsg === "string") &&
                <div className="alert alert-danger alert-icon alert-dismissible fade show text-start" role="alert">
                  <i className="uil uil-exclamation-triangle"></i>{errorMsg} <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                </div>  
              }

              <form
                className="text-start mb-3"
                onSubmit={handleSubmit(handleUpdateCareerHighlightFormSubmit)}
              >
                <div className="form-floating mb-4">
                  <input
                    id="date"
                    type="month"
                    className="form-control"
                    placeholder="Date"
                    {...register('date', {
                      required: true,
                    })}
                  />
                  <label htmlFor="date">Date</label>
                  {
                    errors.date?.type === "required" && 
                    <div className="formFieldError">
                      Please select date.
                    </div>
                  }
                  {
                    errorMsg &&
                    (typeof errorMsg === "object") &&
                    errorMsg?.date && 
                    <div className="formFieldError">
                      {errorMsg?.date[0]}
                    </div>
                  }
                </div>
                <div className="form-floating mb-4">
                  <input
                    id="eventName"
                    type="text"
                    className="form-control"
                    placeholder="Event Name"
                    {...register('eventName', {
                      required: true,
                      maxLength: 150,
                    })}
                  />
                  <label htmlFor="eventName">Event Name</label>
                  {
                    errors.eventName?.type === "required" && 
                    <div className="formFieldError">
                      Please enter event name.
                    </div>
                  }
                  {
                    errors.eventName?.type === "maxLength" && 
                    <div className="formFieldError">
                      Event name length not be greater than 150 character.
                    </div>
                  }
                  {
                    errorMsg &&
                    (typeof errorMsg === "object") &&
                    errorMsg?.eventName && 
                    <div className="formFieldError">
                      {errorMsg?.eventName[0]}
                    </div>
                  }
                </div>
                <div className="form-floating mb-4">
                  <input
                    id="description"
                    type="text"
                    className="form-control"
                    placeholder="Description"
                    {...register('description', {
                      required: true,
                      maxLength: 150,
                    })}
                  />
                  <label htmlFor="description">Description</label>
                  {
                    errors.description?.type === "required" && 
                    <div className="formFieldError">
                      Please enter description.
                    </div>
                  }
                  {
                    errors.description?.type === "maxLength" && 
                    <div className="formFieldError">
                      Description length not be greater than 150 character.
                    </div>
                  }
                  {
                    errorMsg &&
                    (typeof errorMsg === "object") &&
                    errorMsg?.description && 
                    <div className="formFieldError">
                      {errorMsg?.description[0]}
                    </div>
                  }
                </div>
                <button
                  disabled={updateCareerHighlightSubmitButton}
                  className="btn btn-primary rounded-pill btn-login w-100 mb-2"
                  type="submit"
                >
                  { updateCareerHighlightSubmitButton ? <Loader text='Update' /> : 'Update' }
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export const AddNewAcademicDetailsForm = ({ userName }) => {
  const [addAcademicDetailSubmitButton, setAddAcademicDetailSubmitButton] = useState(false);
  const [successMsg, setSuccessMsg] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm();
  const navigate = useNavigate();

  const handleAddAcademicDetailFormSubmit = async (values) => {
    isValid ? setAddAcademicDetailSubmitButton(true) : setAddAcademicDetailSubmitButton(false)

    await requestApi(
      'POST',
      `add-athlete-user-academic/${userName}`,
      { 'Authorization' : `Bearer ${localStorage.getItem('ya-user-access-token')}` },
      {},
      values
    ).then((result) => {
      setSuccessMsg('Your new academic detail added successfully.Redirecting to academic detail listing page...');
      setErrorMsg('');
      setAddAcademicDetailSubmitButton(false);

      setTimeout(() => {
        navigate(0);
      }, 500);
    }).catch((error) => {
      setSuccessMsg('');
      setErrorMsg(error?.response?.data?.message);
      setAddAcademicDetailSubmitButton(false);
    });
  };

  return (
    <>
      <div className="modal fade" id="modal-addNewAcademicDetail" tabIndex="-1">
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content text-center">
            <div className="modal-body">
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              <h2 className="mb-3 text-start">Add New Academic Detail</h2>
              
              {
                successMsg &&
                <div className="alert alert-success alert-icon alert-dismissible fade show text-start" role="alert">
                  <i className="uil uil-check"></i>{successMsg} <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                </div>  
              }
              {
                errorMsg &&
                (typeof errorMsg === "string") &&
                <div className="alert alert-danger alert-icon alert-dismissible fade show text-start" role="alert">
                  <i className="uil uil-exclamation-triangle"></i>{errorMsg} <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                </div>  
              }

              <form
                className="text-start mb-3"
                onSubmit={handleSubmit(handleAddAcademicDetailFormSubmit)}
              >
                <div className="form-floating mb-4">
                  <input
                    id="date"
                    type="month"
                    className="form-control"
                    placeholder="Date"
                    {...register('date', {
                      required: true,
                    })}
                  />
                  <label htmlFor="date">Date</label>
                  {
                    errors.date?.type === "required" && 
                    <div className="formFieldError">
                      Please select date.
                    </div>
                  }
                  {
                    errorMsg &&
                    (typeof errorMsg === "object") &&
                    errorMsg?.date && 
                    <div className="formFieldError">
                      {errorMsg?.date[0]}
                    </div>
                  }
                </div>
                <div className="form-floating mb-4">
                  <input
                    id="academicName"
                    type="text"
                    className="form-control"
                    placeholder="Academic Name"
                    {...register('academicName', {
                      required: true,
                      maxLength: 150,
                    })}
                  />
                  <label htmlFor="academicName">Academic Name</label>
                  {
                    errors.academicName?.type === "required" && 
                    <div className="formFieldError">
                      Please enter academic name.
                    </div>
                  }
                  {
                    errors.academicName?.type === "maxLength" && 
                    <div className="formFieldError">
                      Academic name length not be greater than 150 character.
                    </div>
                  }
                  {
                    errorMsg &&
                    (typeof errorMsg === "object") &&
                    errorMsg?.academicName && 
                    <div className="formFieldError">
                      {errorMsg?.academicName[0]}
                    </div>
                  }
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-floating mb-4">
                      <input
                        id="result"
                        type="text"
                        className="form-control"
                        placeholder="Result"
                        {...register('result', {
                          required: true,
                          pattern: /^\d+(\.\d+)?$/,
                        })}
                      />
                      <label htmlFor="result">Result</label>
                      {
                        errors.result?.type === "required" && 
                        <div className="formFieldError">
                          Please enter academic result.
                        </div>
                      }
                      {
                        errors.result?.type === "pattern" && 
                        <div className="formFieldError">
                          Result accept only number with decimal points.
                        </div>
                      }
                      {
                        errorMsg &&
                        (typeof errorMsg === "object") &&
                        errorMsg?.result && 
                        <div className="formFieldError">
                          {errorMsg?.result[0]}
                        </div>
                      }
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-floating mb-4">
                      <div className="form-select-wrapper">
                        <select
                          className="form-select"
                          aria-label="select_resultType"
                          {...register('resultType', {
                            required: true
                          })}
                        >
                          <option value="">Result Type</option>
                          <option value="marks">Marks</option>
                          <option value="grades">Grades</option>
                          <option value="percentage">Percentage</option>
                        </select>
                        {
                          errors.resultType?.type === "required" && 
                          <div className="formFieldError">
                            Please select your result type.
                          </div>
                        }
                        {
                          errorMsg &&
                          (typeof errorMsg === "object") &&
                          errorMsg?.resultType && 
                          <div className="formFieldError">
                            {errorMsg?.resultType[0]}
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                </div>
                <button
                  disabled={addAcademicDetailSubmitButton}
                  className="btn btn-primary rounded-pill btn-login w-100 mb-2"
                  type="submit"
                >
                  { addAcademicDetailSubmitButton ? <Loader text='Save' /> : 'Save' }
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export const UpdateAcademicDetailsForm = ({
  userName,
  updateAcademicId,
  updatedAcademicData
}) => {
  const [updateAcademicDetailSubmitButton, setUpdateAcademicDetailSubmitButton] = useState(false);
  const [successMsg, setSuccessMsg] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isValid },
  } = useForm();
  const navigate = useNavigate();

  const handleUpdateAcademicDetailFormSubmit = async (values) => {
    isValid ? setUpdateAcademicDetailSubmitButton(true) : setUpdateAcademicDetailSubmitButton(false)
    values.academicId = updateAcademicId;

    await requestApi(
      'POST',
      `update-athlete-user-academic/${userName}`,
      { 'Authorization' : `Bearer ${localStorage.getItem('ya-user-access-token')}` },
      {},
      values
    ).then((result) => {
      setSuccessMsg('Your academic detail updated successfully.Redirecting to academic detail listing page...');
      setErrorMsg('');
      setUpdateAcademicDetailSubmitButton(false);

      setTimeout(() => {
        navigate(0);
      }, 500);
    }).catch((error) => {
      setSuccessMsg('');
      setErrorMsg(error?.response?.data?.message);
      setUpdateAcademicDetailSubmitButton(false);
    });
  };

  useEffect(() => {
    if (updatedAcademicData !== undefined
        && updatedAcademicData !== ''
    ) {
      setValue('date', updatedAcademicData?.date);
      setValue('academicName', updatedAcademicData?.academicName);
      setValue('result', updatedAcademicData?.result);
      setValue('resultType', updatedAcademicData?.resultType);
    }
  }, [updatedAcademicData, setValue]);

  return (
    <>
      <div className="modal fade" id="modal-updateAcademicDetail" tabIndex="-1">
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content text-center">
            <div className="modal-body">
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              <h2 className="mb-3 text-start">Update Academic Detail</h2>
              
              {
                successMsg &&
                <div className="alert alert-success alert-icon alert-dismissible fade show text-start" role="alert">
                  <i className="uil uil-check"></i>{successMsg} <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                </div>  
              }
              {
                errorMsg &&
                (typeof errorMsg === "string") &&
                <div className="alert alert-danger alert-icon alert-dismissible fade show text-start" role="alert">
                  <i className="uil uil-exclamation-triangle"></i>{errorMsg} <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                </div>  
              }

              <form
                className="text-start mb-3"
                onSubmit={handleSubmit(handleUpdateAcademicDetailFormSubmit)}
              >
                <div className="form-floating mb-4">
                  <input
                    id="date"
                    type="month"
                    className="form-control"
                    placeholder="Date"
                    {...register('date', {
                      required: true,
                    })}
                  />
                  <label htmlFor="date">Date</label>
                  {
                    errors.date?.type === "required" && 
                    <div className="formFieldError">
                      Please select date.
                    </div>
                  }
                  {
                    errorMsg &&
                    (typeof errorMsg === "object") &&
                    errorMsg?.date && 
                    <div className="formFieldError">
                      {errorMsg?.date[0]}
                    </div>
                  }
                </div>
                <div className="form-floating mb-4">
                  <input
                    id="academicName"
                    type="text"
                    className="form-control"
                    placeholder="Academic Name"
                    {...register('academicName', {
                      required: true,
                      maxLength: 150,
                    })}
                  />
                  <label htmlFor="academicName">Academic Name</label>
                  {
                    errors.academicName?.type === "required" && 
                    <div className="formFieldError">
                      Please enter academic name.
                    </div>
                  }
                  {
                    errors.academicName?.type === "maxLength" && 
                    <div className="formFieldError">
                      Academic name length not be greater than 150 character.
                    </div>
                  }
                  {
                    errorMsg &&
                    (typeof errorMsg === "object") &&
                    errorMsg?.academicName && 
                    <div className="formFieldError">
                      {errorMsg?.academicName[0]}
                    </div>
                  }
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-floating mb-4">
                      <input
                        id="result"
                        type="text"
                        className="form-control"
                        placeholder="Result"
                        {...register('result', {
                          required: true,
                          pattern: /^\d+(\.\d+)?$/,
                        })}
                      />
                      <label htmlFor="result">Result</label>
                      {
                        errors.result?.type === "required" && 
                        <div className="formFieldError">
                          Please enter academic result.
                        </div>
                      }
                      {
                        errors.result?.type === "pattern" && 
                        <div className="formFieldError">
                          Result accept only number with decimal points.
                        </div>
                      }
                      {
                        errorMsg &&
                        (typeof errorMsg === "object") &&
                        errorMsg?.result && 
                        <div className="formFieldError">
                          {errorMsg?.result[0]}
                        </div>
                      }
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-floating mb-4">
                      <div className="form-select-wrapper">
                        <select
                          className="form-select"
                          aria-label="select_resultType"
                          {...register('resultType', {
                            required: true
                          })}
                        >
                          <option value="">Result Type</option>
                          <option value="marks">Marks</option>
                          <option value="grades">Grades</option>
                          <option value="percentage">Percentage</option>
                        </select>
                        {
                          errors.resultType?.type === "required" && 
                          <div className="formFieldError">
                            Please select your result type.
                          </div>
                        }
                        {
                          errorMsg &&
                          (typeof errorMsg === "object") &&
                          errorMsg?.resultType && 
                          <div className="formFieldError">
                            {errorMsg?.resultType[0]}
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                </div>
                <button
                  disabled={updateAcademicDetailSubmitButton}
                  className="btn btn-primary rounded-pill btn-login w-100 mb-2"
                  type="submit"
                >
                  { updateAcademicDetailSubmitButton ? <Loader text='Update' /> : 'Update' }
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export const AddNewAthleteEventForm = ({ userName }) => {
  const [addEventSubmitButton, setAddEventSubmitButton] = useState(false);
  const [successMsg, setSuccessMsg] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm();
  const navigate = useNavigate();

  const handleAddEventFormSubmit = async (values) => {
    isValid ? setAddEventSubmitButton(true) : setAddEventSubmitButton(false)

    await requestApi(
      'POST',
      `add-athlete-user-event/${userName}`,
      { 'Authorization' : `Bearer ${localStorage.getItem('ya-user-access-token')}` },
      {},
      values
    ).then((result) => {
      setSuccessMsg('Your new event added successfully.Redirecting to event listing page...');
      setErrorMsg('');
      setAddEventSubmitButton(false);

      setTimeout(() => {
        navigate(0);
      }, 500);
    }).catch((error) => {
      setSuccessMsg('');
      setErrorMsg(error?.response?.data?.message);
      setAddEventSubmitButton(false);
    });
  };

  return (
    <>
      <div className="modal fade" id="modal-addNewEvent" tabIndex="-1">
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content text-center">
            <div className="modal-body">
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              <h2 className="mb-3 text-start">Add New Event</h2>
              
              {
                successMsg &&
                <div className="alert alert-success alert-icon alert-dismissible fade show text-start" role="alert">
                  <i className="uil uil-check"></i>{successMsg} <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                </div>  
              }
              {
                errorMsg &&
                (typeof errorMsg === "string") &&
                <div className="alert alert-danger alert-icon alert-dismissible fade show text-start" role="alert">
                  <i className="uil uil-exclamation-triangle"></i>{errorMsg} <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                </div>  
              }

              <form
                className="text-start mb-3"
                onSubmit={handleSubmit(handleAddEventFormSubmit)}
              >
                <div className="form-floating mb-4">
                  <input
                    id="date"
                    type="date"
                    className="form-control"
                    placeholder="Date"
                    {...register('date', {
                      required: true,
                    })}
                  />
                  <label htmlFor="date">Date</label>
                  {
                    errors.date?.type === "required" && 
                    <div className="formFieldError">
                      Please select date.
                    </div>
                  }
                  {
                    errorMsg &&
                    (typeof errorMsg === "object") &&
                    errorMsg?.date && 
                    <div className="formFieldError">
                      {errorMsg?.date[0]}
                    </div>
                  }
                </div>
                <div className="form-floating mb-4">
                  <input
                    id="eventName"
                    type="text"
                    className="form-control"
                    placeholder="Event Name"
                    {...register('eventName', {
                      required: true,
                      maxLength: 255,
                    })}
                  />
                  <label htmlFor="eventName">Event Name</label>
                  {
                    errors.eventName?.type === "required" && 
                    <div className="formFieldError">
                      Please enter event name.
                    </div>
                  }
                  {
                    errors.eventName?.type === "maxLength" && 
                    <div className="formFieldError">
                      Event name length not be greater than 255 character.
                    </div>
                  }
                  {
                    errorMsg &&
                    (typeof errorMsg === "object") &&
                    errorMsg?.eventName && 
                    <div className="formFieldError">
                      {errorMsg?.eventName[0]}
                    </div>
                  }
                </div>
                <div className="form-floating mb-4">
                  <input
                    id="location"
                    type="text"
                    className="form-control"
                    placeholder="Location"
                    {...register('location', {
                      required: true,
                      maxLength: 255,
                    })}
                  />
                  <label htmlFor="location">Location</label>
                  {
                    errors.location?.type === "required" && 
                    <div className="formFieldError">
                      Please enter location.
                    </div>
                  }
                  {
                    errors.location?.type === "maxLength" && 
                    <div className="formFieldError">
                      Location length not be greater than 255 character.
                    </div>
                  }
                  {
                    errorMsg &&
                    (typeof errorMsg === "object") &&
                    errorMsg?.location && 
                    <div className="formFieldError">
                      {errorMsg?.location[0]}
                    </div>
                  }
                </div>
                <button
                  disabled={addEventSubmitButton}
                  className="btn btn-primary rounded-pill btn-login w-100 mb-2"
                  type="submit"
                >
                  { addEventSubmitButton ? <Loader text='Save' /> : 'Save' }
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export const UpdateAthleteEventForm = ({
  userName,
  updateEventId,
  updatedEventData
}) => {
  const [updateEventSubmitButton, setUpdateEventSubmitButton] = useState(false);
  const [successMsg, setSuccessMsg] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isValid },
  } = useForm();
  const navigate = useNavigate();

  const handleUpdateEventFormSubmit = async (values) => {
    isValid ? setUpdateEventSubmitButton(true) : setUpdateEventSubmitButton(false)
    values.eventId = updateEventId;

    await requestApi(
      'POST',
      `update-athlete-user-event/${userName}`,
      { 'Authorization' : `Bearer ${localStorage.getItem('ya-user-access-token')}` },
      {},
      values
    ).then((result) => {
      setSuccessMsg('Your event updated successfully.Redirecting to event listing page...');
      setErrorMsg('');
      setUpdateEventSubmitButton(false);

      setTimeout(() => {
        navigate(0);
      }, 500);
    }).catch((error) => {
      setSuccessMsg('');
      setErrorMsg(error?.response?.data?.message);
      setUpdateEventSubmitButton(false);
    });
  };

  useEffect(() => {
    if (updatedEventData !== undefined
        && updatedEventData !== ''
    ) {
      setValue('date', updatedEventData?.date);
      setValue('eventName', updatedEventData?.eventName);
      setValue('location', updatedEventData?.location);
    }
  }, [updatedEventData, setValue]);

  return (
    <>
      <div className="modal fade" id="modal-updateEvent" tabIndex="-1">
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content text-center">
            <div className="modal-body">
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              <h2 className="mb-3 text-start">Update Event</h2>
              
              {
                successMsg &&
                <div className="alert alert-success alert-icon alert-dismissible fade show text-start" role="alert">
                  <i className="uil uil-check"></i>{successMsg} <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                </div>  
              }
              {
                errorMsg &&
                (typeof errorMsg === "string") &&
                <div className="alert alert-danger alert-icon alert-dismissible fade show text-start" role="alert">
                  <i className="uil uil-exclamation-triangle"></i>{errorMsg} <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                </div>  
              }

              <form
                className="text-start mb-3"
                onSubmit={handleSubmit(handleUpdateEventFormSubmit)}
              >
                <div className="form-floating mb-4">
                  <input
                    id="date"
                    type="date"
                    className="form-control"
                    placeholder="Date"
                    {...register('date', {
                      required: true,
                    })}
                  />
                  <label htmlFor="date">Date</label>
                  {
                    errors.date?.type === "required" && 
                    <div className="formFieldError">
                      Please select date.
                    </div>
                  }
                  {
                    errorMsg &&
                    (typeof errorMsg === "object") &&
                    errorMsg?.date && 
                    <div className="formFieldError">
                      {errorMsg?.date[0]}
                    </div>
                  }
                </div>
                <div className="form-floating mb-4">
                  <input
                    id="eventName"
                    type="text"
                    className="form-control"
                    placeholder="Event Name"
                    {...register('eventName', {
                      required: true,
                      maxLength: 255,
                    })}
                  />
                  <label htmlFor="eventName">Event Name</label>
                  {
                    errors.eventName?.type === "required" && 
                    <div className="formFieldError">
                      Please enter event name.
                    </div>
                  }
                  {
                    errors.eventName?.type === "maxLength" && 
                    <div className="formFieldError">
                      Event name length not be greater than 255 character.
                    </div>
                  }
                  {
                    errorMsg &&
                    (typeof errorMsg === "object") &&
                    errorMsg?.eventName && 
                    <div className="formFieldError">
                      {errorMsg?.eventName[0]}
                    </div>
                  }
                </div>
                <div className="form-floating mb-4">
                  <input
                    id="location"
                    type="text"
                    className="form-control"
                    placeholder="Location"
                    {...register('location', {
                      required: true,
                      maxLength: 255,
                    })}
                  />
                  <label htmlFor="location">Location</label>
                  {
                    errors.location?.type === "required" && 
                    <div className="formFieldError">
                      Please enter location.
                    </div>
                  }
                  {
                    errors.location?.type === "maxLength" && 
                    <div className="formFieldError">
                      Location length not be greater than 255 character.
                    </div>
                  }
                  {
                    errorMsg &&
                    (typeof errorMsg === "object") &&
                    errorMsg?.location && 
                    <div className="formFieldError">
                      {errorMsg?.location[0]}
                    </div>
                  }
                </div>
                <button
                  disabled={updateEventSubmitButton}
                  className="btn btn-primary rounded-pill btn-login w-100 mb-2"
                  type="submit"
                >
                  { updateEventSubmitButton ? <Loader text='Update' /> : 'Update' }
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};