import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { requestApi } from "./CommonFunction";
import '../styles/custom.css';

const Navbar = (props) => {
	const navigate = useNavigate();
	const [userData, setUserData] = useState('');
	const { pathname } = useLocation();

	const logOut = async () => {
    await requestApi(
      'POST',
      'user-logout',
      { 'Authorization' : `Bearer ${localStorage.getItem('ya-user-access-token')} ` },
    ).then((result) => {
      localStorage.removeItem('ya-user-access-token');
      navigate('/');
    }).catch((error) => {
      //console.log(error?.response?.data?.message);
      console.log(error);
    });
  };



  const loadUserData = async () => {
    await requestApi(
      'GET',
      'get-user-data',
      { 'Authorization' : `Bearer ${localStorage.getItem('ya-user-access-token')} ` },
    ).then((result) => {
      setUserData(result.data.response)
    }).catch((error) => {
      //console.log(error?.response?.data?.message);
      console.log(error);
      alert('We encounter some error when fetch your data.please try after some time');
    });
  };

  useEffect(() => {
    if (userData === '' && localStorage.getItem('ya-user-access-token')) {
      loadUserData();  
    }
  }, [userData]);

	return (
		<>
			{
				(props.hideMenu !== undefined && props.hideMenu === true)
				?
					<header className="wrapper">
		        <nav className="navbar navbar-expand-lg center-nav transparent navbar-light position-absolute caret-none hideMenu">
		          <div className="container flex-lg-row flex-nowrap align-items-center py-xl-4 py-lg-4">
		            <div className="navbar-brand m-auto">
		              <Link to="/">
		                <img src="/assets/img/logo.png" srcSet="/assets/img/logo@2x.png 2x" alt="" />
		              </Link>
		            </div>
		          </div>
		        </nav>
	      	</header>
	      :
		      <header className="wrapper">
						<nav className="navbar navbar-expand-lg center-nav transparent position-absolute navbar-light caret-none">
						  <div className="container flex-lg-row flex-nowrap align-items-center">
						    <div className="navbar-brand w-100">
						      <Link to="/">
						        <img src="/assets/img/logo.png" srcSet="/assets/img/logo@2x.png 2x" alt="" />
						      </Link>
						    </div>
						    <div className="navbar-collapse offcanvas offcanvas-nav offcanvas-start bg-navy">
						      <div className="offcanvas-header d-lg-none">
						        <h3 className="text-white fs-24 mb-0">youngathlete.org</h3>
						        <button type="button" className="btn-close btn-close-white" data-bs-dismiss="offcanvas" aria-label="Close"></button>
						      </div>
						      <div className="offcanvas-body ms-lg-auto d-flex flex-column h-100">
						        <ul className="navbar-nav">
						          <li className="nav-item">
						            <Link className={`nav-link ${pathname === '/' ? 'active' : ''} `} to="/">Home</Link>
						          </li>
						          <li className="nav-item">
		                  	<Link className={`nav-link ${pathname === '/events' ? 'active' : ''} `} to="/events">Events</Link>
		                	</li>
		                	<li className="nav-item">
		                  	<Link className={`nav-link ${pathname === '/get-involved' ? 'active' : ''} `} to="/get-involved">Get involved</Link>
		                	</li>
						          <li className="nav-item">
		                  	<Link className={`nav-link ${pathname === '/about-us' ? 'active' : ''} `} to="/about-us">About us</Link>
		                	</li>
						          <li className="nav-item">
						            <Link className={`nav-link ${pathname === '/contact' ? 'active' : ''} `} to="/contact">Contact</Link>
						          </li>
						        </ul>
						        <div className="offcanvas-footer d-lg-none">
						          <div>
						            <nav className="nav social social-white mt-4">
						              <Link to="#/">
						                <i className="uil uil-twitter"></i>
						              </Link>
						              <Link to="#/">
						                <i className="uil uil-facebook-f"></i>
						              </Link>
						              <Link to="#/">
						                <i className="uil uil-instagram"></i>
						              </Link>
						            </nav>
						          </div>
						        </div>
						      </div>
						    </div>
						    <div className="navbar-other w-100 d-flex ms-auto">
						      <ul className="navbar-nav flex-row align-items-center ms-auto">
						        {
						        	(
						        		/*props.isUserLogin !== undefined
						        		&& props.isUserLogin === true*/
						        		localStorage.getItem('ya-user-access-token') !== undefined
						        		&& localStorage.getItem('ya-user-access-token') !== null
						        	)
						        	?
						        	<>
							        	{/*<li className="nav-item">
						              <Link to="/onboarding" className="btn btn-primary btn-sm rounded-pill mx-1 mb-2 mb-md-0">+ Athlete</Link>
						            </li>*/}
						            <li className="nav-item dropdown language-select">
						              <Link
						              	className="nav-link dropdown-item dropdown-toggle text-capitalize"
						              	to="#/"
						              	role="button"
						              	data-bs-toggle="dropdown"
						              	aria-haspopup="true"
						              	aria-expanded="false"
						              >
						              	{userData?.fullName}
						              </Link>
						              <ul className="dropdown-menu">
						              	{
						              		(userData !== undefined
						              		 && userData?.userType === 0)
						              		?
						              			<>
							              			<h6 className="mx-5">Athletes</h6>
									                {
					                          (userData?.athleteProfile !== undefined
					                           && userData?.athleteProfile?.length !== 0
					                          )
					                          ?
					                          userData?.athleteProfile.map((value, index) => {
					                            return (
												                <li
												                	key={`athleteProfile_${index}`}
												                	className="nav-item"
												                >
												                  <a
												                  	className="dropdown-item"
												                  	href={`/player/${value.publicProfileName}`}
												                  >
												                  	{value.personalInformation.name}
												                  </a>
												                </li>
												              );
												             })
					                          :
					                          <p className="nav-item dropdown-item text-muted">No profile found</p>
					                        }
									                <hr className="my-4" />
								                </>
						              		:
						              		''
						              	}

						              	<li className="nav-item">
						                  <Link className={`dropdown-item ${pathname === '/dashboard' ? 'active' : ''} `} to="/dashboard">Dashboard</Link>
						                </li>
						                <li className="nav-item">
						                  <Link className={`dropdown-item ${pathname === '/account' ? 'active' : ''} `} to="/account">Account</Link>
						                </li>
						                <li className="nav-item">
						                  <a
						                  	className="dropdown-item"
						                  	href="#/"
						                  	onClick={logOut}
						                  >
						                  	Logout
						                  </a>
						                </li>
						              </ul>
						            </li>
					            </>
						        	:
						        	<>
						        		<li>
						          		<Link to="#/" className="btn btn-outline-primary btn-sm rounded-pill mx-1" data-bs-toggle="modal" data-bs-target="#modal-login">
						          			Login
						          		</Link>
						        		</li>
						        		<li className="nav-item d-none d-md-block">
						          		<Link to="#/" className="btn btn-primary btn-sm rounded-pill mx-1" data-bs-toggle="modal" data-bs-target="#modal-signup">
						          			Sign Up
						          		</Link>
						        		</li>
						        	</>
						        }
						        
						        <li className="nav-item d-lg-none">
						          <button className="hamburger offcanvas-nav-btn">
						            <span></span>
						          </button>
						        </li>
						      </ul>
						    </div>
						  </div>
						</nav>
					</header>
			}
		</>
	);
};

export default Navbar;