import React, { useState } from 'react';
import { 
	OnBoardStepOne,
	OnBoardStepTwo,
	OnBoardStepThree,
} from '../components/OnBoard';
import { requestApi } from '../components/CommonFunction';

const Onboarding = () => {
	const [onboardStep, setOnboardStep] = useState(1);
	const [onBoardFormData, setOnBoardFormData] = useState({});

	const prevStep = () => {
		setOnboardStep(onboardStep - 1);
	};

	const nextStep = () => {
		setOnboardStep(onboardStep + 1);
	};

	const handleOnBoardFormData = (values) => {
		//setOnBoardFormData({...onBoardFormData, [values.target.name]: values.target.value });

		Object.entries(onBoardFormData).map((val, index) => {
		  if (val[0] in values) {
		  	values[val[0]] = {
		  		...onBoardFormData[val[0]], ...values[val[0]]
		  	}
		  }
		  return values;
		});

		setOnBoardFormData({...onBoardFormData, ...values });
	};

	const handleAthletProfileFormSubmit = async (values) => {
		const postAthleteProfile = await requestApi(
	      'POST',
	      'create-user-athlete-profile',
	      { 
	        'Authorization' : `Bearer ${localStorage.getItem('ya-user-access-token')}`,
	      },
	      {},
	      values
	    );

	    return postAthleteProfile;
	}
 
	switch (onboardStep) {
	  case 1: 
	    return (
	      <OnBoardStepOne
	      	nextStep={nextStep}
	      	handleOnBoardFormData={handleOnBoardFormData}
	      	onBoardFormData={onBoardFormData}
	      />
	    );
	  case 2: 
	    return (
	      <OnBoardStepTwo
	      	prevStep={prevStep}
	      	nextStep={nextStep}
	      	handleOnBoardFormData={handleOnBoardFormData}
	      	onBoardFormData={onBoardFormData}
	      />
	    );
	  case 3: 
	    return (
	      <OnBoardStepThree
	      	prevStep={prevStep}
	      	handleOnBoardFormData={handleOnBoardFormData}
	      	onBoardFormData={onBoardFormData}
	      	handleAthletProfileFormSubmit={handleAthletProfileFormSubmit}
	      />
	    );
	  default: 
	     return (
	     	<OnBoardStepOne />
	    );
	}
};

export default Onboarding;