import React, { useState, useEffect } from 'react';
import { Link, Navigate } from 'react-router-dom';
import {
  Layout,
  cleanUpBeforePageLoad,
  loadJSScript,
  PageLoader,
  requestApi,
} from '../components/CommonFunction';
import moment from 'moment';

const AccountSetting = () => {
  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState('');
  const [errorMsg, setErrorMsg] = useState('');

  const loadUserData = async () => {
    await requestApi(
      'GET',
      'get-user-data',
      { 'Authorization' : `Bearer ${localStorage.getItem('ya-user-access-token')} ` },
    ).then((result) => {
      setErrorMsg('');
      setUserData(result.data.response);
    }).catch((error) => {
      setLoading(false);
      setErrorMsg(error?.response?.data?.message);
    });
  };

  useEffect(() => {
    setLoading(true);
    window.scrollTo(0, 0);
    if (userData === '' && localStorage.getItem('ya-user-access-token')) {
      loadUserData();
    }

    if (userData !== undefined && userData !== '') {
      setLoading(false);
      cleanUpBeforePageLoad();
      loadJSScript("/assets/js/theme.js", 'themeScript');
      window.scrollTo(0, 0);
    }
  }, [userData]);

  return (
    <>
      {/*<div className="page-loader"></div>*/}

      {
        !localStorage.getItem('ya-user-access-token')
        ?
        <Navigate replace to="/" />
        :
        ''
      }

      <Layout isUserLogin={true} pageLoader={true} >
        <div className="content-wrapper">
          <section className="wrapper">
            <div className="container py-16">
              <div className="col-xl-9 col-lg-12 col-md-12 m-auto">
                {
                  errorMsg &&
                  (typeof errorMsg === "string") &&
                  <div className="alert alert-danger alert-icon alert-dismissible fade show text-start" role="alert">
                    <i className="uil uil-exclamation-triangle"></i>{errorMsg} <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                  </div>  
                }

                <h1 className="display-4 mb-8">Account</h1>
                {
                  loading
                  ?
                  <PageLoader />
                  :
                  <>
                    <div className="row gy-6 mb-6">
                      <div className="col-lg-6 col-md-6 col-sm-6">
                        <div className="card h-100">
                          <div className="card-body py-xxl-8">
                            <div className="row">
                              <div className="col-lg-8 col-sm-4">
                                <h4 className="mb-4">Personal information</h4>
                              </div>
                              <div className="col-lg-4 col-sm-4 text-end personalInfoHeaderLinkClass">
                                <Link to="/account/personal-info">Update</Link>
                              </div>
                            </div>
                            <p>View and update your information.</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6">
                        <div className="card h-100">
                          <div className="card-body py-xxl-8">
                            <div className="row">
                              <div className="col-lg-8 col-sm-6">
                                <h4 className="mb-4">Membership</h4>
                              </div>
                              {
                                userData && userData.membershipId !== ''
                                && userData.membershipStartDate !== ''
                                && userData.membershipEndDate !== '' &&
                                (moment().format('YYYY-MM-DD') > userData.membershipEndDate) &&
                                <div className="col-lg-4 col-sm-6 text-end personalInfoHeaderLinkClass">
                                  <Link
                                    to="/account/renew-membership"
                                    state={{ selectedPlan: {
                                      ...userData?.lastMembershipPlan,
                                      'membershipEndDate': userData?.membershipEndDate
                                    }}}
                                  >
                                    Renew
                                  </Link>
                                </div>
                              }
                            </div>
                            {
                              userData && userData.membershipId === ''
                              && userData.membershipStartDate === ''
                              && userData.membershipEndDate === '' &&
                              <p className="text-center mt-10">You don't have membership <br />
                                <Link to="/account/membership-plan">Subscribe</Link>
                              </p>
                            }
                            {
                              userData && userData.membershipId !== ''
                              && userData.membershipStartDate !== ''
                              && userData.membershipEndDate !== '' &&
                              <div className="d-flex justify-content-between mb-4">
                                <div className="d-flex flex-row d-flex align-items-center">
                                  <div className="w-100">
                                    <p>Essential (Until):</p>
                                  </div>
                                </div>
                                <div className="ms-2 d-flex align-items-center">
                                  <p>{userData.membershipEndDate}</p>
                                </div>
                              </div>
                            }
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6">
                        <div className="card h-100">
                          <div className="card-body py-xxl-8">
                            <div className="row">
                              <div className="col-lg-8 col-sm-6">
                                <h4 className="mb-4">Password</h4>
                              </div>
                              <div className="col-lg-4 col-sm-6 text-end personalInfoHeaderLinkClass">
                                <Link to="/account/change-password">Update</Link>
                              </div>
                            </div>
                            <p>Update your password to prevent unauthrozed login.</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6">
                        <div className="card h-100">
                          <div className="card-body py-xxl-8">
                            <div className="row">
                              <div className="col-lg-8 col-sm-6">
                                <h4 className="mb-4">Address</h4>
                              </div>
                              <div className="col-lg-4 col-sm-6 text-end personalInfoHeaderLinkClass">
                                <Link to="/account/change-address">Update</Link>
                              </div>
                            </div>
                            <p>View and update your addresses</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6">
                        <div className="card h-100">
                          <div className="card-body py-xxl-8">
                            <div className="row">
                              <div className="col-lg-8 col-sm-6">
                                <h4 className="mb-4">Billing & payment</h4>
                              </div>
                              <div className="col-lg-4 col-sm-6 text-end personalInfoHeaderLinkClass">
                                <Link to="/account/change-billing-address">Update</Link>
                              </div>
                            </div>
                            <p>View and update payment information</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6">
                        <div className="card h-100">
                          <div className="card-body py-xxl-8">
                            <div className="row">
                              <div className="col-lg-8 col-sm-6">
                                <h4 className="mb-4">Email Preference</h4>
                              </div>
                              <div className="col-lg-4 col-sm-6 text-end personalInfoHeaderLinkClass">
                                <Link to="/account/email-preferences">Update</Link>
                              </div>
                            </div>
                            <p>Update your email and notification settings</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                }
              </div>
            </div>
          </section>
        </div>
      </Layout>
    </>
  );
};

export default AccountSetting;