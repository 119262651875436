import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Home from "./pages/Home";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsOfUse from "./pages/TermsOfUse";
import Onboarding from "./pages/Onboarding";
import OtpLogin from "./pages/OtpLogin";
import ForgotPassword from "./pages/ForgotPassword";
import AccountSetting from "./pages/AccountSetting";
import AboutUs from "./pages/AboutUs";
import CookiePolicy from "./pages/CookiePolicy";
import Contact from "./pages/Contact";
import GetInvolved from "./pages/GetInvolved";
import Events from "./pages/Events";
import EventDetails from "./components/Event/EventDetails";
import EventCheckout from "./components/Event/EventCheckout";
import Dashboard from "./pages/Dashboard";
import ViewUserSingleEvent from "./components/Event/UserEvent/ViewUserSingleEvent";
import Player from "./pages/PublicProfile/Player";
import Videos from "./pages/PublicProfile/Videos";
import CareerHighlights from "./pages/PublicProfile/CareerHighlights";
import Academics from "./pages/PublicProfile/Academics";
import AthleteEvent from "./pages/PublicProfile/AthleteEvent";
import { default as CoachRegister } from "./pages/Coach/Register";
import StudentList from "./pages/Coach/Student";
import PersonalInfo from "./components/Account/PersonalInfo";
import ChangePassword from "./components/Account/ChangePassword";
import ChangeAddress from "./components/Account/ChangeAddress";
import ChangeBillingAddress from "./components/Account/ChangeBillingAddress";
import EmailPreference from "./components/Account/EmailPreference";
import MembershipPlan from "./components/Account/Membership/MembershipPlan";
import BuyMembership from "./components/Account/Membership/BuyMembership";
import RenewMembership from "./components/Account/Membership/RenewMembership";
//import StripePayment from "./pages/StripePayment";
import NotFound from "./pages/NotFound";

const App = () => {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route index element={<Home />} />
          <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route exact path="/terms-of-use" element={<TermsOfUse />} />
          <Route exact path="/onboarding" element={<Onboarding />} />
          <Route exact path="/login-through-otp" element={<OtpLogin />} />
          <Route exact path="/forgot-password" element={<ForgotPassword />} />
          <Route exact path="/account" element={<AccountSetting />} />
          <Route exact path="/account/personal-info" element={<PersonalInfo />} />
          <Route exact path="/account/renew-membership" element={<RenewMembership />} />
          <Route exact path="/account/buy-membership" element={<BuyMembership />} />
          <Route exact path="/account/membership-plan" element={<MembershipPlan />} />
          <Route exact path="/account/change-password" element={<ChangePassword />} />
          <Route exact path="/account/change-address" element={<ChangeAddress />} />
          <Route exact path="/account/email-preferences" element={<EmailPreference />} />
          <Route exact path="/account/change-billing-address" element={<ChangeBillingAddress />} />
          <Route exact path="/about-us" element={<AboutUs />} />
          <Route exact path="/cookie-policy" element={<CookiePolicy />} />
          <Route exact path="/contact" element={<Contact />} />
          <Route exact path="/get-involved" element={<GetInvolved />} />
          <Route exact path="/events" element={<Events />} />
          <Route exact path="/events/event-details/:eventURL" element={<EventDetails />} />
          <Route exact path="/events/event-checkout/:eventURL" element={<EventCheckout />} />
          <Route exact path="/dashboard" element={<Dashboard />} />
          <Route exact path="/dashboard/view-user-event/:bookingId" element={<ViewUserSingleEvent />} />
          <Route exact path="/player/:userName" element={<Player />} />
          <Route exact path="/player/video-list/:userName" element={<Videos />} />
          <Route exact path="/player/career-highlights/:userName" element={<CareerHighlights />} />
          <Route exact path="/player/academics-details/:userName" element={<Academics />} />
          <Route exact path="/player/events/:userName" element={<AthleteEvent />} />
          <Route exact path="/coach-registration" element={<CoachRegister />} />
          <Route exact path="/coach/event/:eventName/view-student-list" element={<StudentList />} />
          {/*<Route exact path="/stripe-payment" element={<StripePayment />} />*/}
          <Route path="/404-page-not-found" element={<NotFound />} />
          <Route path="*" element={<Navigate replace to="/404-page-not-found" />} />
        </Routes>
      </Router>
    </>
  );
};

export default App;
