import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { Loader, Layout, requestApi } from '../../components/CommonFunction';
import '../../styles/custom.css';

const Register = () => {
  const [loadCoachRegisterButton, setLoadCoachRegisterButton] = useState(false);
  const [successMsg, setSuccessMsg] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [sportsName, setSportsName] = useState('');
  const [timezoneData, setTimezoneData] = useState('');
  const [countryData, setCountryData] = useState('');
  const [stateData, setStateData] = useState('');
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = useForm();

  const handleCoachRegistrationFormSubmit = async (values) => {
    isValid ? setLoadCoachRegisterButton(true) : setLoadCoachRegisterButton(false)
    values.mobileNo = parseInt(values.mobileNo);
    delete values.acceptTerms;
    
    await requestApi(
      'POST',
      'coach-register',
      {},
      {},
      values
    ).then((result) => {
      setSuccessMsg('Coach registration successfully completed.');
      
      /*await setTimeout(() => {
        setLoadCoachRegisterButton(false);
      }, 1500);*/

      setLoadCoachRegisterButton(false);
      setErrorMsg('');
      setStateData('');
      reset();
      window.scrollTo(0, 0);
    }).catch((error) => {
      setErrorMsg(error?.response?.data?.message);
      setLoadCoachRegisterButton(false);
    });    
    //setErrorMsg('Some errors are occured');
  };

  const loadCountryStateData = async (value) => {
    setErrorMsg('');
    setStateData('');
    if (value && value !== '') {
      await requestApi(
        'GET',
        'get-country-state-list',
        {},
        { 'country': value },
      ).then((result) => {
        setStateData(result.data.response);
      }).catch((error) => {
        setErrorMsg(error?.response?.data?.message);
      });
    }
  };

  useEffect(() => {
    const loadSportsName = async () => {
      await requestApi(
        'GET',
        'get-sports-name-list',
      ).then((result) => {
        setSportsName(result.data.response);
      }).catch((error) => {
        setErrorMsg(error?.response?.data?.message);
      });
    };

    const loadCountryData = async () => {
      await requestApi(
        'GET',
        'get-country-list',
      ).then((result) => {
        setCountryData(result.data.response);
      }).catch((error) => {
        setErrorMsg(error?.response?.data?.message);
      });
    };

    const loadTimezoneData = async () => {
      await requestApi(
        'GET',
        'get-timezone-list',
      ).then((result) => {
        setTimezoneData(result.data.response);
      }).catch((error) => {
        setErrorMsg(error?.response?.data?.message);
      });
    };

    loadSportsName();
    loadCountryData();
    loadTimezoneData();
  }, []);

  return (
  	<>
      {/*<div className="page-loader"></div>*/}
      
      <Layout hideMenu={true} >
        <div className="content-wrapper">
          <section className="wrapper">
            <div className="container py-16">
              <div className="col-xl-7 col-lg-9 col-md-12 m-auto">
                <h1 className="display-3 mb-10 text-center">Coach Registration</h1>

                {
                  successMsg &&
                  <div className="alert alert-success alert-icon alert-dismissible fade show text-start" role="alert">
                    <i className="uil uil-check"></i>{successMsg} <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                  </div>  
                }
                {
                  errorMsg &&
                  (typeof errorMsg === "string") &&
                  <div className="alert alert-danger alert-icon alert-dismissible fade show text-start" role="alert">
                    <i className="uil uil-exclamation-triangle"></i>{errorMsg} <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                  </div>  
                }

                <form
                  className="text-start mb-3"
                  onSubmit={handleSubmit(handleCoachRegistrationFormSubmit)}
                >
                  <div className="row g-3 mb-8">
                    <h4 className="mb-0 text-start">Personal Information</h4>
                    <div className="col-md-6">
                      <div className="form-floating">
                        <input
                          id="fullName"
                          className="form-control"
                          placeholder="Name"
                          {...register('fullName', {
                            required: true,
                            maxLength: 255,
                          })}
                        />
                        <label htmlFor="fullName">Name</label>
                        {
                          errors.fullName?.type === "required" && 
                          <div className="formFieldError">
                            Please enter your name.
                          </div>
                        }
                        {
                          errors.fullName?.type === "maxLength" && 
                          <div className="formFieldError">
                            Name length must be maximum 255 character.
                          </div>
                        }
                        {
                          errorMsg &&
                          (typeof errorMsg === "object") &&
                          errorMsg?.fullName && 
                          <div className="formFieldError">
                            {errorMsg.fullName[0]}
                          </div>
                        }
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-select-wrapper">
                        <select
                          className="form-select"
                          aria-label="select_sport"
                          {...register('sportName', {
                            required: true,
                            maxLength: 255,
                          })}
                        >
                          <option value="">Sport</option>
                          {
                            sportsName && sportsName.map((value, index) => {
                              return (
                                <option
                                  key={`sportsNameList_${index}`}
                                  value={value}
                                >
                                  {value}
                                </option>
                              );
                            })
                          }
                        </select>
                        {
                          errors.sportName?.type === "required" && 
                          <div className="formFieldError">
                            Please select your sport.
                          </div>
                        }
                        {
                          errors.sportName?.type === "maxLength" && 
                          <div className="formFieldError">
                            Sport name length must be maximum 255 character.
                          </div>
                        }
                        {
                          errorMsg &&
                          (typeof errorMsg === "object") &&
                          errorMsg?.sportName && 
                          <div className="formFieldError">
                            {errorMsg.sportName[0]}
                          </div>
                        }
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-select-wrapper">
                        <select
                          className="form-select"
                          aria-label="select_gender"
                          {...register('gender', {
                            required: true,
                          })}
                        >
                          <option value="">Gender</option>
                          <option value="M">Male</option>
                          <option value="F">Female</option>
                          <option value="O">Others</option>
                        </select>
                        {
                          errors.gender?.type === "required" && 
                          <div className="formFieldError">
                            Please select your gender.
                          </div>
                        }
                        {
                          errorMsg &&
                          (typeof errorMsg === "object") &&
                          errorMsg?.gender && 
                          <div className="formFieldError">
                            {errorMsg.gender[0]}
                          </div>
                        }
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-floating">
                        <input
                          id="dob"
                          className="form-control"
                          type="date"
                          placeholder="DOB"
                          {...register('dob', {
                            required: true,
                          })}
                        />
                        <label htmlFor="dob">DOB</label>
                        {
                          errors.dob?.type === "required" && 
                          <div className="formFieldError">
                            Please select dob.
                          </div>
                        }
                        {
                          errorMsg &&
                          (typeof errorMsg === "object") &&
                          errorMsg?.dob && 
                          <div className="formFieldError">
                            {errorMsg.dob[0]}
                          </div>
                        }
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-floating">
                        <input
                          id="email"
                          type="email"
                          className="form-control"
                          placeholder="Email"
                          {...register('email', {
                            required: true,
                            pattern: /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/, //eslint-disable-line
                            maxLength: 255,
                          })}
                        />
                        <label htmlFor="email">Email</label>
                        {
                          errors.email?.type === "required" && 
                          <div className="formFieldError">
                            Please enter your email address.
                          </div>
                        }
                        {
                          errors.email?.type === "maxLength" && 
                          <div className="formFieldError">
                            Email length must be maximum 255 character.
                          </div>
                        }
                        {
                          errors.email?.type === "pattern" && 
                          <div className="formFieldError">
                            Please enter valid email address.
                          </div>
                        }
                        {
                          errorMsg &&
                          (typeof errorMsg === "object") &&
                          errorMsg?.email && 
                          <div className="formFieldError">
                            {errorMsg.email[0]}
                          </div>
                        }
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-floating">
                        <input
                          id="mobileNo"
                          className="form-control"
                          placeholder="Mobile"
                          {...register('mobileNo', {
                            required: true,
                            minLength: 10,
                            maxLength: 10,
                            pattern: /^[0-9]+$/,
                          })}
                        />
                        <label htmlFor="mobile">Mobile No.</label>
                        {
                          errors.mobileNo?.type === "required" && 
                          <div className="formFieldError">
                            Please enter mobile number.
                          </div>
                        }
                        {
                          errors.mobileNo?.type === "minLength" && 
                          <div className="formFieldError">
                            Mobile number must be minimum 10 digit.
                          </div>
                        }
                        {
                          errors.mobileNo?.type === "maxLength" && 
                          <div className="formFieldError">
                            Mobile number must be maximum 10 digit.
                          </div>
                        }
                        {
                          errors.mobileNo?.type === "pattern" && 
                          <div className="formFieldError">
                            Mobile number must be number.
                          </div>
                        }
                        {
                          errorMsg &&
                          (typeof errorMsg === "object") &&
                          errorMsg?.mobileNo && 
                          <div className="formFieldError">
                            {errorMsg.mobileNo[0]}
                          </div>
                        }
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-floating password-field">
                        <input
                          type="password"
                          className="form-control"
                          placeholder="Password"
                          id="loginPassword"
                          {...register('password', {
                            required: true,
                            minLength: 8,
                            maxLength: 12,
                          })}
                        />
                        <span className="password-toggle">
                          <i className="uil uil-eye"></i>
                        </span>
                        <label htmlFor="loginPassword">Password</label>
                        {
                          errors.password?.type === "required" && 
                          <div className="formFieldError">
                            Please enter your password.
                          </div>
                        }
                        {
                          errors.password?.type === "minLength" && 
                          <div className="formFieldError">
                            Password length must be minimum 8 character.
                          </div>
                        }
                        {
                          errors.password?.type === "maxLength" && 
                          <div className="formFieldError">
                            Password length must be maximum 12 character.
                          </div>
                        }
                        {
                          errorMsg &&
                          (typeof errorMsg === "object") &&
                          errorMsg?.password && 
                          <div className="formFieldError">
                            {errorMsg.password[0]}
                          </div>
                        }
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-select-wrapper">
                        <select
                          className="form-select"
                          aria-label="select_timezone"
                          {...register('timeZone', {
                            required: true,
                          })}
                        >
                          <option value="">Timezone</option>
                          {
                            timezoneData &&
                            Object.keys(timezoneData).map((value, index) => {
                              return (
                                <option
                                  key={`timezoneList_${index}`}
                                  value={value}
                                >
                                  {value}
                                </option>
                              );
                            })
                          }
                        </select>
                        {
                          errors.timeZone?.type === "required" && 
                          <div className="formFieldError">
                            Please select your timezone.
                          </div>
                        }
                        {
                          errorMsg &&
                          (typeof errorMsg === "object") &&
                          errorMsg?.timeZone && 
                          <div className="formFieldError">
                            {errorMsg.timeZone[0]}
                          </div>
                        }
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-floating">
                        <input
                          id="website"
                          type="url"
                          className="form-control"
                          placeholder="Your Website"
                          {...register('website', {
                            required: true,
                          })}
                        />
                        <label htmlFor="website">Your website</label>
                        {
                          errors.website?.type === "required" && 
                          <div className="formFieldError">
                            Please enter valid website url.
                          </div>
                        }
                        {
                          errorMsg &&
                          (typeof errorMsg === "object") &&
                          errorMsg?.website && 
                          <div className="formFieldError">
                            {errorMsg.website[0]}
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                  <div className="row g-3 mb-8">
                    <h4 className="mb-0 text-start">Your Address</h4>
                    <div className="col-md-12">
                      <div className="form-floating">
                        <input
                          id="address"
                          className="form-control"
                          placeholder="address"
                          {...register('addressInformation.address', {
                            required: true,
                            maxLength: 255,
                          })}
                        />
                        <label htmlFor="address">Address</label>
                        {
                          errors.addressInformation?.address?.type === "required" && 
                          <div className="formFieldError">
                            Please enter address.
                          </div>
                        }
                        {
                          errors.addressInformation?.address?.type === "maxLength" && 
                          <div className="formFieldError">
                            Address length must be maximum 255 character.
                          </div>
                        }
                        {
                          errorMsg &&
                          (typeof errorMsg === "object") &&
                          errorMsg?.["addressInformation.address"] && 
                          <div className="formFieldError">
                            {errorMsg?.["addressInformation.address"][0]}
                          </div>
                        }
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-floating">
                        <input
                          id="address2"
                          className="form-control"
                          placeholder="Apartment or suite"
                          {...register('addressInformation.address2', {
                            maxLength: 255,
                          })}
                        />
                        <label htmlFor="address2" className="form-label">Address 2 <span className="text-muted">(Optional)</span>
                        </label>
                        {
                          errors.addressInformation?.address2?.type === "maxLength" && 
                          <div className="formFieldError">
                            Address2 length must be maximum 255 character.
                          </div>
                        }
                        {
                          errorMsg &&
                          (typeof errorMsg === "object") &&
                          errorMsg?.["addressInformation.address2"] && 
                          <div className="formFieldError">
                            {errorMsg?.["addressInformation.address2"][0]}
                          </div>
                        }
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-select-wrapper">
                        <select
                          className="form-select"
                          aria-label="select_country"
                          {...register('addressInformation.country', {
                            required: true,
                            onChange: ({ target }) => loadCountryStateData(target.value),
                          })}
                        >
                          <option value="">Country</option>
                          {
                            countryData
                            && Object.entries(countryData).map((value, index) => {
                              return (
                                <option
                                  key={`countryList_${index}`}
                                  value={value[0]}
                                >
                                  {value[1]}
                                </option>
                              );
                            })
                          }
                        </select>
                        {
                          errors.addressInformation?.country?.type === "required" && 
                          <div className="formFieldError">
                            Please select country.
                          </div>
                        }
                        {
                          errorMsg &&
                          (typeof errorMsg === "object") &&
                          errorMsg?.["addressInformation.country"] && 
                          <div className="formFieldError">
                            {errorMsg?.["addressInformation.country"][0]}
                          </div>
                        }
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-select-wrapper">
                        <select
                          className="form-select"
                          aria-label="select_state"
                          {...register('addressInformation.state', {
                            required: true,
                          })}
                        >
                          <option value="">State</option>
                          {
                            stateData
                            && Object.entries(stateData).map((value, index) => {
                              return (
                                <option
                                  key={`stateList_${index}`}
                                  value={value[0]}
                                >
                                  {value[1]}
                                </option>
                              );
                            })
                          }
                        </select>
                        {
                          errors.addressInformation?.state?.type === "required" && 
                          <div className="formFieldError">
                            Please select state.
                          </div>
                        }
                        {
                          errorMsg &&
                          (typeof errorMsg === "object") &&
                          errorMsg?.["addressInformation.state"] && 
                          <div className="formFieldError">
                            {errorMsg?.["addressInformation.state"][0]}
                          </div>
                        }
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-floating">
                        <input
                          id="city"
                          className="form-control"
                          placeholder="City"
                          {...register('addressInformation.city', {
                            required: true,
                          })}
                        />
                        <label htmlFor="city">City</label>
                        {
                          errors.addressInformation?.city?.type === "required" && 
                          <div className="formFieldError">
                            Please enter city.
                          </div>
                        }
                        {
                          errorMsg &&
                          (typeof errorMsg === "object") &&
                          errorMsg?.["addressInformation.city"] && 
                          <div className="formFieldError">
                            {errorMsg?.["addressInformation.city"][0]}
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                  <div className="row g-3 mb-8">
                    <h4 className="mb-0 text-start">About you</h4>
                    <div className="col-md-12">
                      <div className="form-floating">
                        <textarea
                          id="aboutUser"
                          className="form-control textAreaCustomSize"
                          placeholder="About You"
                          {...register('aboutUser', {
                            required: true,
                            maxLength: 500,
                          })}
                        ></textarea>
                        <label htmlFor="aboutUser"></label>
                        {
                          errors.aboutUser?.type === "required" && 
                          <div className="formFieldError">
                            Please enter something about yourself.
                          </div>
                        }
                        {
                          errors.aboutUser?.type === "maxLength" && 
                          <div className="formFieldError">
                            About yourself length must be maximum 500 character.
                          </div>
                        }
                        {
                          errorMsg &&
                          (typeof errorMsg === "object") &&
                          errorMsg?.aboutUser && 
                          <div className="formFieldError">
                            {errorMsg.aboutUser[0]}
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                  <div className="row g-3 mb-8">
                    <h4 className="mb-0 text-start">Social media</h4>
                    <div className="col-md-12">
                      <div className="form-floating">
                        <input
                          id="instagramLink"
                          type="url"
                          className="form-control"
                          placeholder="Instagram URL"
                          {...register('socialAccountLink.instagramLink', {
                            required: true,
                          })}
                        />
                        <label htmlFor="instagramLink">Instagram URL</label>
                        {
                          errors?.socialAccountLink?.instagramLink?.type === "required" && 
                          <div className="formFieldError">
                            Please enter valid instagram url.
                          </div>
                        }
                        {
                          errorMsg &&
                          (typeof errorMsg === "object") &&
                          errorMsg?.["socialAccountLink.instagramLink"] && 
                          <div className="formFieldError">
                            {errorMsg?.["socialAccountLink.instagramLink"][0]}
                          </div>
                        }
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-floating">
                        <input
                          id="twitterLink"
                          type="url"
                          className="form-control"
                          placeholder="Twitter URL"
                          {...register('socialAccountLink.twitterLink', {
                            required: true,
                          })}
                        />
                        <label htmlFor="twitterLink">Twitter URL</label>
                        {
                          errors?.socialAccountLink?.twitterLink?.type === "required" && 
                          <div className="formFieldError">
                            Please enter valid twitter url.
                          </div>
                        }
                        {
                          errorMsg &&
                          (typeof errorMsg === "object") &&
                          errorMsg?.["socialAccountLink.twitterLink"] && 
                          <div className="formFieldError">
                            {errorMsg?.["socialAccountLink.twitterLink"][0]}
                          </div>
                        }
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-floating">
                        <input
                          id="facebookLink"
                          type="url"
                          className="form-control"
                          placeholder="Facebook URL"
                          {...register('socialAccountLink.facebookLink', {
                            required: true,
                          })}
                        />
                        <label htmlFor="facebookLink">Facebook URL</label>
                        {
                          errors?.socialAccountLink?.facebookLink?.type === "required" && 
                          <div className="formFieldError">
                            Please enter facebook url.
                          </div>
                        }
                        {
                          errorMsg &&
                          (typeof errorMsg === "object") &&
                          errorMsg?.["socialAccountLink.facebookLink"] && 
                          <div className="formFieldError">
                            {errorMsg?.["socialAccountLink.facebookLink"][0]}
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                  <div className="form-check mb-4">
                    <input
                      id="acceptTerms" 
                      className="form-check-input"
                      type="checkbox"
                      {...register('acceptTerms', {
                        required: true,
                      })}
                    />
                    <label className="form-check-label" htmlFor="acceptTerms">I agree <Link to="/terms-of-use">Term of use</Link> and <Link to="/privacy-policy">Privacy policy</Link>
                    </label>
                    {
                      errors.acceptTerms?.type === "required" && 
                      <div className="formFieldError">
                        Please accept terms and privacy policy.
                      </div>
                    }
                  </div>
                  <div className="text-center">
                    <button
                      disabled={loadCoachRegisterButton}
                      className="btn btn-primary rounded-pill px-12 w-100"
                      type="submit"
                    >
                      { loadCoachRegisterButton ? <Loader text='Sign up' /> : 'Sign up' }
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </section>
        </div>
      </Layout>
    </>
  );
};

export default Register;
