import React, { useState, useEffect } from 'react';
import { useParams, Navigate } from 'react-router-dom';
import {
  Layout,
  cleanUpBeforePageLoad,
  loadJSScript,
  PageLoader,
  requestApi,
} from '../../../components/CommonFunction';
import NotFound from '../../../pages/NotFound';
import moment from 'moment';
import 'moment-timezone';
import List from 'rc-virtual-list';
import '../../../styles/custom.css';

const ViewUserSingleEvent = () => {
  const [loading, setLoading] = useState(true);
  const [userEventData, setUserEventData] = useState('');
  const { bookingId } = useParams();

  const loadSingleUserEventData = async () => {
    await requestApi(
      'GET',
      `get-single-user-event/${bookingId}`,
      { 'Authorization' : `Bearer ${localStorage.getItem('ya-user-access-token')} ` },
    ).then((result) => {
      setLoading(false);
      setUserEventData(result.data.response);
    }).catch((error) => {
      //console.log(error?.response?.data?.message);
      setLoading(false);
    });
  };

  useEffect(() => {
    setLoading(true);
    window.scrollTo(0, 0);
    if (userEventData === '' && localStorage.getItem('ya-user-access-token')) {
      loadSingleUserEventData();  
    }
    
    if (userEventData !== undefined && userEventData !== '') {
      setLoading(false);
      cleanUpBeforePageLoad();
      loadJSScript("/assets/js/theme.js", 'themeScript');
      window.scrollTo(0, 0);
    }
  }, [userEventData]);

  return (
  	<>
      {
        !localStorage.getItem('ya-user-access-token')
        ?
        <Navigate replace to="/" />
        :
        ''
      }

      <Layout isUserLogin={true} pageLoader={true}>
        {
          loading
          ?
          <PageLoader />
          :
          <>
            {
              userEventData.length !== undefined && userEventData.length === 0
              ?
              <NotFound />
              :
              <>
                <div className="content-wrapper">
                  <section className="wrapper">
                    <div className="container py-18">
                      <div className="col-lg-9 col-sm-12 m-auto">
                        <h1 className="display-1 text-center mb-6">
                          Booking Event Details 
                        </h1>
                        <div className="table-responsive">
                          <table className="ViewUserSingleEventTableClass table">
                            <tbody>
                              <tr>
                                <th>Transaction ID</th>
                                <td>{userEventData?.transactionId}</td>
                              </tr>
                              <tr>
                                <th>Event Name</th>
                                <td>{userEventData?.eventData?.name}</td>
                              </tr>
                              <tr>
                                <th>Event Date & Time</th>
                                <td>
                                  {moment(userEventData?.eventData?.date).tz(userEventData?.eventData?.timeZone).format('YYYY/MM/DD')} {`${moment(userEventData?.eventData?.date + ' ' + userEventData?.eventData?.startTime + ':00' ).tz(userEventData?.eventData?.timeZone).format('h:mmA')} - ${moment(userEventData?.eventData?.date + ' ' + userEventData?.eventData?.endTime + ':00' ).tz(userEventData?.eventData?.timeZone).format('h:mmA zz')} `}
                                </td>
                              </tr>
                              <tr>
                                <th>Total Book Ticket</th>
                                <td>
                                  {userEventData?.qty}
                                </td>
                              </tr>
                              <tr>
                                <th>Sub Total</th>
                                <td>
                                  {userEventData?.subTotal}
                                </td>
                              </tr>
                              {/*<tr>
                                <th>Shipping Price</th>
                                <td>
                                  {userEventData?.shippingPrice}
                                </td>
                              </tr>
                              <tr>
                                <th>Discount Price</th>
                                <td>
                                  {userEventData?.discountPrice}
                                </td>
                              </tr>*/}
                              <tr>
                                <th>Total Amount Paid</th>
                                <td>
                                  {userEventData?.grandTotal}
                                </td>
                              </tr>
                              <tr>
                                <th>Payment Method</th>
                                <td className="text-capitalize">
                                  {userEventData?.paymentInfo?.paymentMethod !== undefined ? userEventData?.paymentInfo?.paymentMethod : '-'}
                                </td>
                              </tr>
                              <tr>
                                <th>Status</th>
                                <td>
                                  {`${userEventData?.status === 1 ? 'Booked' : userEventData?.status === 2 ? 'Cancelled' : 'Pending'} `}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>

                      <div className="col-lg-9 col-sm-12 m-auto">
                        <h1 className="display-1 text-center mb-6">
                          Event Messages From Organization 
                        </h1>
                        {
                          userEventData?.eventMessage?.length !== undefined
                          && userEventData?.eventMessage?.length > 0
                          ?
                          <List
                            data={userEventData?.eventMessage}
                            height={250}
                            itemHeight={30}
                            itemKey="id"
                          >
                            {(item, index, props) => {
                              return (
                                <div
                                  key={`virtual-event-user-message-list_${item.id}`}
                                  className="table-responsive"
                                >
                                  <table className="ViewUserSingleEventTableClass table">
                                    <thead>
                                      <tr>
                                        <th scope="col">Sending Date</th>
                                        <th scope="col">Title</th>
                                        <th scope="col">Message</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td>{moment(item?.createdOn).format('YYYY/MM/DD')}</td>
                                        <td>{item?.title}</td>
                                        <td>{item?.message}</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>

                              );
                            }}
                          </List>
                          :
                          <p className="text-center">No messages found</p>
                        }
                      </div>
                    </div>
                  </section>
                </div>
              </>
            }
          </>
        }
      </Layout>
    </>
  );
}

export default ViewUserSingleEvent;