import React, { useState, useEffect } from 'react';
import {
  Link,
  useParams,
  Navigate,
  useLocation,
  useNavigate,
} from 'react-router-dom'; 
import {
  Layout,
  cleanUpBeforePageLoad,
  loadJSScript,
  PageLoader,
  requestApi
} from '../../components/CommonFunction';
import {
  AddNewVideoForm,
  UpdateVideoForm
} from '../../components/ModalForms';

const Videos = () => {
  const [loading, setLoading] = useState(true);
  const [successMsg, setSuccessMsg] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [userVideoList, setUserVideoList] = useState('');
  const { userName } = useParams();
  const { pathname, search } = useLocation();
  const [updateVideoId, setUpdateVideoId] = useState('');
  const navigate = useNavigate();

  const handleDeleteVideo = async (videoId) => {
    if (videoId === '') {
      return false;
    }

    await requestApi(
      'DELETE',
      `delete-athlete-user-video/${userName}/` + videoId,
      {
        'Authorization' : `Bearer ${localStorage.getItem('ya-user-access-token')}`,
      },
    ).then((result) => {
      setSuccessMsg('Your video deleted successfully.Redirecting to video page...');
      setErrorMsg('');

      setTimeout(() => {
        navigate(0);
      }, 500);
    }).catch((error) => {
      setSuccessMsg('');
      setErrorMsg(error?.response?.data?.message);
    });    
  };

  const loadAthleteUserVideoData = async () => {
    let url = `get-athlete-user-video-list/${userName}`;
    if (search !== undefined && search !== '') {
      url += search;
    }

    await requestApi(
      'GET',
      url,
      { 
        'Authorization' : `Bearer ${localStorage.getItem('ya-user-access-token')}`,
      },
    ).then((result) => {
      setErrorMsg('');
      setUserVideoList(result.data.response);
    }).catch((error) => {
      setLoading(false);
      setErrorMsg(error?.response?.data?.message);
    });
  };

  if (userVideoList === '') {
    loadAthleteUserVideoData();
  }

  useEffect(() => {
    if (userVideoList !== '' && userVideoList !== undefined) {
      setLoading(false);
      cleanUpBeforePageLoad();
      loadJSScript("/assets/js/theme.js", 'themeScript');
      window.scrollTo(0, 0);
    }
  }, [userVideoList, search]);

  return (
    <>
      {/*<div className="page-loader"></div>*/}

      {
        !localStorage.getItem('ya-user-access-token')
        ?
        <Navigate replace to="/" />
        :
        ''
      }
      
      <Layout pageLoader={true} >
        <div className="content-wrapper">
          <section className="wrapper">
            <div className="container pt-16 pb-10 pt-md-16 pb-md-10">
              <h1 className="display-3 mb-12 text-center">
                All Videos
                <Link
                  to="/#"
                  data-bs-toggle="modal"
                  data-bs-target="#modal-addNewVideo"
                  className="btn btn-primary btn-sm rounded-pill mx-2 mb-2 mb-md-0"
                >
                  + Add video
                </Link>
              </h1>
              {
                successMsg &&
                <div className="alert alert-success alert-icon alert-dismissible fade show text-start" role="alert">
                  <i className="uil uil-check"></i>{successMsg} <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                </div>  
              }
              {
                errorMsg &&
                (typeof errorMsg === "string") &&
                <div className="alert alert-danger alert-icon alert-dismissible fade show text-start" role="alert">
                  <i className="uil uil-exclamation-triangle"></i>{errorMsg} <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                </div>  
              }

              <div className="row gy-6">
                {
                  loading
                  ?
                  <PageLoader />
                  :
                  <>
                    {
                      (userVideoList !== undefined
                       && userVideoList.data !== undefined
                       && userVideoList.data.length !== 0
                      )
                      ?
                      <>
                        {
                          userVideoList
                          && userVideoList.data.map((value, index) => {
                            return (
                              <div
                                key={`athleteVideos_${index}`}
                                className="col-md-4"
                              >
                                <div
                                  key={`athleteVideos_${index}`}
                                >
                                  <article>
                                    <video
                                      className="player"
                                      playsInline
                                      controls
                                      preload="none"
                                    >
                                      <source src={value.video} /*type="video/mp4"*/ />
                                    </video>
                                    <div className="post-header">
                                      <div className="post-category">
                                        <Link
                                          to="/#"
                                          data-bs-toggle="modal"
                                          data-bs-target="#modal-updateVideos"
                                          className="hover"
                                          onClick={() => setUpdateVideoId(value.id)}
                                        >
                                          Update
                                        </Link> | <Link
                                          to="#/"
                                          className="hover"
                                          onClick={() => {
                                            if (window.confirm('Are you sure you want to delete this video?')) {
                                              handleDeleteVideo(value.id);
                                            }
                                          }}
                                        >
                                          Delete
                                        </Link>
                                      </div>
                                    </div>
                                    <div className="post-footer">
                                      <ul className="post-meta">
                                        <li className="post-date">
                                          <i className="uil uil-calendar-alt"></i>
                                          <span>
                                            {value.createdOn}
                                          </span>
                                        </li>
                                      </ul>
                                    </div>
                                  </article>
                                </div>
                              </div>
                            );
                          })
                        }

                        {
                          (userVideoList !== undefined
                           && userVideoList.data !== undefined
                           && userVideoList.paginationLinks !== undefined
                          )
                          ?
                          <nav className="d-flex justify-content-center" aria-label="pagination">
                            <ul className="pagination mb-0">
                              <li className={`page-item ${userVideoList.paginationLinks !== undefined && userVideoList.paginationLinks.previousPageUrl === null ? 'disabled' : ''} `}>
                                <Link
                                  className="page-link"
                                  to={userVideoList.paginationLinks !== undefined
                                        && userVideoList.paginationLinks.previousPageUrl !== null
                                        ? pathname + userVideoList.paginationLinks.previousPageUrl.split(userName)[1]
                                        : '#/'
                                      }
                                  onClick={() => setUserVideoList('')}
                                  aria-label="Previous"
                                >
                                  <span aria-hidden="true"><i className="uil uil-arrow-left"></i></span>
                                </Link>
                              </li>
                              {
                                userVideoList !== undefined
                                && userVideoList.data !== undefined
                                && userVideoList.paginationLinks !== undefined
                                && Object.entries(userVideoList.paginationLinks.allPaginationLink).map((pageLink, index) => {
                                  return (
                                    <li
                                      key={`videosPaginationLinks${index}`}
                                      className={`page-item ${userVideoList.paginationLinks !== undefined && userVideoList.paginationLinks.currentPage.toString() === pageLink[0].toString() ? 'active' : ''}`}
                                    >
                                      <Link
                                        className="page-link"
                                        to={pathname + pageLink[1].split(userName)[1]}
                                        onClick={() => setUserVideoList('')}
                                      >
                                        {pageLink[0]}
                                      </Link>
                                    </li>
                                  );
                                })
                              }
                              <li className={`page-item ${userVideoList.paginationLinks !== undefined && userVideoList.paginationLinks.nextPageUrl === null ? 'disabled' : ''} `}>
                                <Link
                                  className="page-link"
                                  to={userVideoList.paginationLinks !== undefined
                                        && userVideoList.paginationLinks.nextPageUrl !== null
                                        ? pathname + userVideoList.paginationLinks.nextPageUrl.split(userName)[1]
                                        :
                                        '#/'
                                      }
                                  onClick={() => setUserVideoList('')}
                                  aria-label="Next"
                                >
                                  <span aria-hidden="true"><i className="uil uil-arrow-right"></i></span>
                                </Link>
                              </li>
                            </ul>
                          </nav>
                          :
                          ''
                        }
                      </>
                      :
                      <p className="text-center">No video found</p>
                    }
                  </>
                }
              </div>
            </div>
          </section>
        </div>

        <AddNewVideoForm userName={userName} />

        <UpdateVideoForm
          userName={userName}
          updateVideoId={updateVideoId}
        />
      </Layout>
    </>
  );
};

export default Videos;