import React, { useState, useEffect } from 'react';
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom';
import {
  Layout,
  cleanUpBeforePageLoad,
  loadJSScript,
  PageLoader,
  requestApi,
} from '../components/CommonFunction';
import CoachDashboard from './Coach/Dashboard';
import moment from 'moment';
import 'moment-timezone';

const Dashboard = () => {
  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState('');
  const [userEventList, setUserEventList] = useState('');
  const [successMsg, setSuccessMsg] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const { pathname, search } = useLocation();
  const navigate = useNavigate();

  const loadDashboardData = async () => {
    await requestApi(
      'GET',
      'get-user-data',
      { 'Authorization' : `Bearer ${localStorage.getItem('ya-user-access-token')} ` },
    ).then((result) => {
      setLoading(false);
      setUserData(result.data.response);
    }).catch((error) => {
      //console.log(error?.response?.data?.message);
      setLoading(false);
      //console.log(error);
    });
  };

  const loadUserEventData = async () => {
    let url = `get-user-events-list`;
    if (search !== undefined && search !== '') {
      url += search;
    }

    await requestApi(
      'GET',
      url,
      { 'Authorization' : `Bearer ${localStorage.getItem('ya-user-access-token')} ` },
    ).then((result) => {
      setLoading(false);
      setUserEventList(result.data.response);
    }).catch((error) => {
      setLoading(false);
      //console.log(error?.response?.data?.message);
    });
  };

  const handleCancelUserEvent = async (bookEventId) => {
    if (bookEventId === '') {
      return false;
    }

    await requestApi(
      'POST',
      'cancel-single-user-event',
      {
        'Authorization' : `Bearer ${localStorage.getItem('ya-user-access-token')}`,
      },
      {},
      {
        'bookEventId': bookEventId
      }
    ).then((result) => {
      setSuccessMsg('Your event is cancelled successfully.');
      setErrorMsg('');
      window.scrollTo(0, 0);
      setTimeout(() => {
        navigate(0);
      }, 1000);
    }).catch((error) => {
      window.scrollTo(0, 0);
      setSuccessMsg('');
      setErrorMsg(error?.response?.data?.message);
    });    
  };

  if (userData !== undefined
      && userData !== ''
      && userData.userType === 0
      && localStorage.getItem('ya-user-access-token')
      && userEventList === ''
  ) {
    loadUserEventData();
  }

  useEffect(() => {
    setLoading(true);
    window.scrollTo(0, 0);
    if (userData === '' && localStorage.getItem('ya-user-access-token')) {
      loadDashboardData();  
    }
    
    if (userData !== undefined && userData !== null && userData !== '') {
      setLoading(false);
      cleanUpBeforePageLoad();
      loadJSScript("/assets/js/theme.js", 'themeScript');
      window.scrollTo(0, 0);
    }
  }, [userData]);

  return (
  	<>
      {/*<div className="page-loader"></div>*/}
    
      {
        !localStorage.getItem('ya-user-access-token')
        ?
        <Navigate replace to="/" />
        :
        ''
      }
      <Layout isUserLogin={true} pageLoader={true} >
        {
          loading
          ?
          <PageLoader />
          :
          <>
            {
              (userData !== undefined && userData?.userType === 0)
              ?
              <>
                <div className="content-wrapper">
                  <section className="wrapper">
                    <div className="container py-16">
                      <div className="col-lg-9 col-sm-12 m-auto">
                        <h1 className="display-4 mb-12 text-capitalize">
                          { `Hi, ${userData.fullName !== undefined ? userData.fullName : ''} ` }
                        </h1>

                        {
                          successMsg &&
                          <div className="alert alert-success alert-icon alert-dismissible fade show text-start" role="alert">
                            <i className="uil uil-check"></i>{successMsg} <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                          </div>  
                        }
                        {
                          errorMsg &&
                          (typeof errorMsg === "string") &&
                          <div className="alert alert-danger alert-icon alert-dismissible fade show text-start" role="alert">
                            <i className="uil uil-exclamation-triangle"></i>{errorMsg} <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                          </div>  
                        }

                        <div className="row mb-12">
                          <h4 className="mb-4">
                            My Events
                          </h4>
                          {/*<div className="table-responsive">
                            <table className="table">
                              <thead>
                                <tr>
                                  <th scope="col">Event Date</th>
                                  <th scope="col">Name</th>
                                  <th scope="col">Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td className="text-center p-16" colSpan="4">
                                    <i className="uil uil-calendar-alt"></i> No Events
                                  </td>
                                </tr>
                                <tr>
                                  <td>04/15/24 3:00PM PST</td>
                                  <td>
                                    <Link to="/events/event-details/Basketball-basic">Basketball basic</Link>
                                  </td>
                                  <td>
                                    <div className="dropdown">
                                      <Link className="dropdown-item dropdown-toggle" to="#/" role="button" id="event_actions" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <i className="uil uil-ellipsis-v"></i>
                                      </Link>
                                      <ul className="dropdown-menu" aria-labelledby="event_actions">
                                        <li>
                                          <Link className="dropdown-item" to="#/">View</Link>
                                        </li>
                                        <li>
                                          <Link className="dropdown-item" to="#/">Cancel</Link>
                                        </li>
                                      </ul>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td>03/21/24 3:00PM PST</td>
                                  <td>
                                    <Link to="/events/event-details/football-college-sport-introduction">football college sport introduction</Link>
                                  </td>
                                  <td>
                                    <i className="uil uil-ellipsis-v"></i>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>*/}

                          {
                            (userEventList !== undefined
                             && userEventList.data !== undefined
                             && userEventList.data.length !== 0
                            )
                            ?
                            <>
                              <div className="table-responsive">
                                <table className="table">
                                  <thead>
                                    <tr>
                                      <th scope="col">Event Date</th>
                                      <th scope="col">Event Name</th>
                                      <th scope="col">Status</th>
                                      <th scope="col">Action</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {
                                      userEventList
                                      && userEventList.data.map((value, index) => {
                                        return (
                                          <tr key={`athleteUserEventList_${index}`}>
                                            <td>
                                              {moment(value.eventData.date).tz(value.eventData.timeZone).format('YYYY/MM/DD')} {`${moment(value.eventData.date + ' ' + value.eventData.startTime + ':00' ).tz(value.eventData.timeZone).format('h:mmA zz')} `}
                                            </td>
                                            <td>
                                              <Link to={`/events/event-details/${value.eventData.url}`}>{value.eventData.name}</Link>
                                            </td>
                                            <td>
                                              {`${value.status === 1 ? 'Booked' : value.status === 2 ? 'Cancelled' : 'Pending'} `}
                                            </td>
                                            <td>
                                              <div className="dropdown">
                                                <Link className="dropdown-item dropdown-toggle" to="#/" role="button" id="event_actions" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                  <i className="uil uil-ellipsis-v"></i>
                                                </Link>
                                                <ul className="dropdown-menu" aria-labelledby="event_actions">
                                                  <li>
                                                    <Link
                                                      className="dropdown-item"
                                                      to={`/dashboard/view-user-event/${value.id}`}
                                                    >
                                                      View
                                                    </Link>
                                                  </li>
                                                  {
                                                    value.status === 1
                                                    ?
                                                    <li>
                                                      <Link
                                                        className="dropdown-item"
                                                        to="#/"
                                                        onClick={() => {
                                                          if (window.confirm('Are you sure you want to cancel this event?')) {
                                                            handleCancelUserEvent(value.id);
                                                          }
                                                        }}
                                                      >
                                                        Cancel
                                                      </Link>
                                                    </li>
                                                    :
                                                    ''
                                                  }
                                                </ul>
                                              </div>
                                            </td>
                                          </tr>
                                        );
                                      })
                                    }
                                  </tbody>
                                </table>
                              </div>

                              {
                                (userEventList !== undefined
                                 && userEventList.data !== undefined
                                 && userEventList.paginationLinks !== undefined
                                )
                                ?
                                <nav className="d-flex justify-content-center" aria-label="pagination">
                                  <ul className="pagination mb-0">
                                    <li className={`page-item ${userEventList.paginationLinks !== undefined && userEventList.paginationLinks.previousPageUrl === null ? 'disabled' : ''} `}>
                                      <Link
                                        className="page-link"
                                        to={userEventList.paginationLinks !== undefined
                                              && userEventList.paginationLinks.previousPageUrl !== null
                                              ? pathname + userEventList.paginationLinks.previousPageUrl.split(userEventList.paginationLinks.paginationOptions.path)[1]
                                              : '#/'
                                            }
                                        onClick={() => setUserEventList('')}
                                        aria-label="Previous"
                                      >
                                        <span aria-hidden="true"><i className="uil uil-arrow-left"></i></span>
                                      </Link>
                                    </li>
                                    {
                                      userEventList !== undefined
                                      && userEventList.data !== undefined
                                      && userEventList.paginationLinks !== undefined
                                      && Object.entries(userEventList.paginationLinks.allPaginationLink).map((pageLink, index) => {
                                        return (
                                          <li
                                            key={`athleteUserEventListPaginationLinks${index}`}
                                            className={`page-item ${userEventList.paginationLinks !== undefined && userEventList.paginationLinks.currentPage.toString() === pageLink[0].toString() ? 'active' : ''}`}
                                          >
                                            <Link
                                              className="page-link"
                                              to={pathname + pageLink[1].split(userEventList.paginationLinks.paginationOptions.path)[1]}
                                              onClick={() => setUserEventList('')}
                                            >
                                              {pageLink[0]}
                                            </Link>
                                          </li>
                                        );
                                      })
                                    }
                                    <li className={`page-item ${userEventList.paginationLinks !== undefined && userEventList.paginationLinks.nextPageUrl === null ? 'disabled' : ''} `}>
                                      <Link
                                        className="page-link"
                                        to={userEventList.paginationLinks !== undefined
                                              && userEventList.paginationLinks.nextPageUrl !== null
                                              ? pathname + userEventList.paginationLinks.nextPageUrl.split(userEventList.paginationLinks.paginationOptions.path)[1]
                                              :
                                              '#/'
                                            }
                                        onClick={() => setUserEventList('')}
                                        aria-label="Next"
                                      >
                                        <span aria-hidden="true"><i className="uil uil-arrow-right"></i></span>
                                      </Link>
                                    </li>
                                  </ul>
                                </nav>
                                :
                                ''
                              }

                            </>
                            :
                            <p className="text-center">
                              <i className="uil uil-calendar-alt"></i> No Events
                            </p>
                          }
                        </div>
                        <hr />
                        <div className="row mb-8">
                          <h4 className="mb-4">Athlete profiles</h4>
                          <div className="grid">
                            <div className="row isotope gy-6">
                              <div className="item col-md-12 text-center">
                                <div className="card">
                                  <div className="card-body">
                                    {
                                      userData
                                      && userData?.athleteProfile !== undefined
                                      && userData?.athleteProfile.length === 0
                                      && <p>No profile yet</p>
                                    }
                                    <Link to="/onboarding" className="btn btn-primary btn-sm rounded-pill mx-1 mb-2 mb-md-0">+ Athlete</Link>
                                  </div>
                                </div>
                              </div>
                              {/*<div className="item col-md-6 col-xl-4">
                                <div className="card">
                                  <div className="card-body">
                                    <Link to="/player/coriss-ambady" className="text-dark">
                                      <blockquote className="icon mb-0">
                                        <div className="blockquote-details mb-4">
                                          <img className="rounded-circle w-12" src="./assets/img/avatars/te1.jpg" srcSet="./assets/img/avatars/te1@2x.jpg 2x" alt="" />
                                          <div className="info">
                                            <h5 className="mb-1">Coriss Ambady</h5>
                                            <p className="mb-0">Class 2030</p>
                                          </div>
                                        </div>
                                        <p>“Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor. Vestibulum id ligula porta felis euismod semper..”</p>
                                      </blockquote>
                                    </Link>
                                  </div>
                                </div>
                              </div>
                              <div className="item col-md-6 col-xl-4">
                                <div className="card">
                                  <div className="card-body">
                                    <Link to="/player/coriss-ambady" className="text-dark">
                                      <blockquote className="icon mb-0">
                                        <div className="blockquote-details mb-4">
                                          <img className="rounded-circle w-12" src="./assets/img/avatars/te1.jpg" srcSet="./assets/img/avatars/te1@2x.jpg 2x" alt="" />
                                          <div className="info">
                                            <h5 className="mb-1">Coriss Ambady</h5>
                                            <p className="mb-0">Class 2030</p>
                                          </div>
                                        </div>
                                        <p>“Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor. Vestibulum id ligula porta felis euismod semper..”</p>
                                      </blockquote>
                                    </Link>
                                  </div>
                                </div>
                              </div>
                              <div className="item col-md-6 col-xl-4">
                                <div className="card">
                                  <div className="card-body">
                                    <Link to="/player/coriss-ambady" className="text-dark">
                                      <blockquote className="icon mb-0">
                                        <div className="blockquote-details mb-4">
                                          <img className="rounded-circle w-12" src="./assets/img/avatars/te1.jpg" srcSet="./assets/img/avatars/te1@2x.jpg 2x" alt="" />
                                          <div className="info">
                                            <h5 className="mb-1">Coriss Ambady</h5>
                                            <p className="mb-0">Class 2030</p>
                                          </div>
                                        </div>
                                        <p>“Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor. Vestibulum id ligula porta felis euismod semper..”</p>
                                      </blockquote>
                                    </Link>
                                  </div>
                                </div>
                              </div>*/}

                              {
                                (userData !== undefined
                                 && userData?.athleteProfile !== undefined
                                 && userData.athleteProfile.length !== 0
                                )
                                ?
                                userData.athleteProfile.map((value, index) => {
                                  return (
                                    <div key={`athleteProfile_${index}`} className="item col-md-6 col-xl-4">
                                      <div className="card">
                                        <div className="card-body">
                                          <Link
                                            to={`/player/${value.publicProfileName}`}
                                            className="text-dark"
                                          >
                                            <blockquote className="icon mb-0">
                                              <div className="blockquote-details mb-4">
                                                <img
                                                  className="rounded-circle w-12"
                                                  src={
                                                    (value.personalInformation.profilePhoto !== undefined
                                                     && value.personalInformation.profilePhoto !== '')
                                                    ?
                                                    value.personalInformation.profilePhoto
                                                    :
                                                    "./assets/img/avatars/default-user.png"
                                                  }
                                                  srcSet={
                                                    (value.personalInformation.profilePhoto !== undefined
                                                      && value.personalInformation.profilePhoto !== '')
                                                    ?
                                                    value.personalInformation.profilePhoto
                                                    :
                                                    "./assets/img/avatars/default-user.png"
                                                  }
                                                  alt=""
                                                />
                                                <div className="info">
                                                  <h5 className="mb-1">{value.personalInformation.name}</h5>
                                                  <p className="mb-0">
                                                    { value.schoolInformation.highSchool.graduationYear && `Class ${value.schoolInformation.highSchool.graduationYear} `}
                                                  </p>
                                                </div>
                                              </div>
                                              {/*<p>
                                                “Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor. Vestibulum id ligula porta felis euismod semper..”
                                              </p>*/}
                                              <p>
                                                { 
                                                  (value.aboutMe !== undefined
                                                  && value.aboutMe !== '')
                                                  ?
                                                  `“ ${value?.aboutMe.substr(0, 150)}..”`
                                                  :
                                                  ""
                                                }
                                              </p>
                                            </blockquote>
                                          </Link>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })
                                :
                                <p>No profile yet</p>
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </>
              :
              (userData !== undefined && userData?.userType === 2)
              ?
              <CoachDashboard userData={userData} />
              :
              ''
            }
          </>
        }
      </Layout>
    </>
  );
};

export default Dashboard;

