import React, { useState } from 'react';
import { 
	ForgotPasswordStepOne,
	ForgotPasswordStepTwo,
	ForgotPasswordStepThree,
	ForgotPasswordStepFour,
} from '../components/ForgotPassword';

const ForgotPassword = () => {
	const [forgotPasswordStep, setForgotPasswordStep] = useState(1);
	const [formData, setFormData] = useState({});

	const prevStep = () => {
		setForgotPasswordStep(forgotPasswordStep - 1);
	};

	const nextStep = () => {
		setForgotPasswordStep(forgotPasswordStep + 1);
	};

	const handleFormData = (values) => {
		setFormData({...formData, [values.target.name]: values.target.value });
	};
 
	switch (forgotPasswordStep) {
	  case 1: 
	    return (
	      <ForgotPasswordStepOne
	      	nextStep={nextStep}
	      	handleFormData={handleFormData}
	      	formData={formData}
	      />
	    );
	  case 2: 
	    return (
	      <ForgotPasswordStepTwo
	      	prevStep={prevStep}
	      	nextStep={nextStep}
	      	handleFormData={handleFormData}
	      	formData={formData}
	      />
	    );
	  case 3: 
	    return (
	      <ForgotPasswordStepThree
	      	nextStep={nextStep}
	      	formData={formData}
	      />
	    );
	  case 4: 
	    return (
	      <ForgotPasswordStepFour />
	    );
	  default: 
	     return (
	     	<ForgotPasswordStepOne />
	    );
	}
};

export default ForgotPassword;