import React, { useState, useEffect } from 'react';
import {
  Layout,
  cleanUpBeforePageLoad,
  loadJSScript,
  PageLoader,
  requestApi
} from '../CommonFunction';
import NotFound from '../../pages/NotFound';
import { useParams, Link } from 'react-router-dom';
import moment from 'moment';
import 'moment-timezone';

const EventDetails = () => {
  const { eventURL } = useParams();
  const [loading, setLoading] = useState(true);
  const [errorMsg, setErrorMsg] = useState('');
  const [eventData, setEventData] = useState('');

  const loadEventDetailData = async () => {
    await requestApi(
      'GET',
      `get-single-event-details/${eventURL}`,
    ).then((result) => {
      setEventData(result.data.response);
    }).catch((error) => {
      setErrorMsg(error?.response?.data?.message);
    });
  };

  if (eventData === '') {
    loadEventDetailData();
  }

  useEffect(() => {
    if (eventData !== '' && eventData !== undefined) {
      setLoading(false);
      cleanUpBeforePageLoad();
      loadJSScript("/assets/js/theme.js", 'themeScript');
      window.scrollTo(0, 0);
    }
  }, [eventData]);

  return (
  	<>
      {/*<div className="page-loader"></div>*/}

      <Layout pageLoader={true} >
        {
          loading
          ?
          <PageLoader />
          :
          <>
            {
              eventData.length !== undefined && eventData.length === 0
              ?
              <NotFound />
              :
              <>
                <div className="content-wrapper">
                  <section className="wrapper">
                    <div className="container py-12">
                      {
                        errorMsg &&
                        (typeof errorMsg === "string") &&
                        <div className="alert alert-danger alert-icon alert-dismissible fade show text-start" role="alert">
                          <i className="uil uil-exclamation-triangle"></i>{errorMsg} <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                        </div>  
                      }

                      <nav className="d-inline-block" aria-label="breadcrumb">
                        <ol className="breadcrumb mb-6">
                          <li className="breadcrumb-item">
                            <Link to="/events">Events</Link>
                          </li>
                          <li className="breadcrumb-item active" aria-current="page">
                            {eventData.eventName}
                          </li>
                        </ol>
                      </nav>
                      <div className="row gx-md-8 gx-xl-16 gy-8">
                        <h1 className="mb-1">{eventData.eventName}</h1>
                        <div className="col-lg-7">
                          <figure className="card-img-top mb-8">
                            <img src={eventData.imageURL} alt="" />
                          </figure>
                          <div className="classic-view">
                            <article className="post">
                              <div className="post-content mb-5">
                                <h3>Details</h3>
                                <p>
                                  {eventData.eventDetails}
                                </p>
                                <h3>Booked Tickets / Total Tickets</h3>
                                <p>{eventData.totlBookedTickets} / {eventData.maxCapacity}</p>
                                <h6 className="fs-16 mb-0">Age Group</h6>
                                <p className="mb-4">{ eventData.ageGroup != null ? ` ${eventData.ageGroup?.min}-${eventData.ageGroup.max} ` : '-'}</p>
                                <h6 className="fs-16 mb-0">Class level</h6>
                                <p className="mb-8 text-capitalize">{eventData.classLevel}</p>
                              </div>
                            </article>
                          </div>
                          <hr className="my-4" />
                          <h3 className="mb-6">About Coach</h3>
                          <div className="author-info d-md-flex align-items-center mb-3">
                            <div className="d-flex align-items-center">
                              <figure className="user-avatar">
                                <img className="rounded-circle" alt="" src="/assets/img/avatars/default-user.png" />
                              </figure>
                              <div>
                                <h6>{eventData.coachData?.coachName}</h6>
                                <span className="post-meta fs-15">
                                  {`${eventData.coachData?.sportName} Coach`}
                                </span>
                              </div>
                            </div>
                          </div>
                          <p>
                            {eventData.coachData?.coachDetails}
                          </p>
                          <nav className="nav social">
                            <a
                              href={eventData.coachData?.socialAccountLinks?.twitterLink}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <i className="uil uil-twitter"></i>
                            </a>
                            <a
                              href={eventData.coachData?.socialAccountLinks?.facebookLink}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <i className="uil uil-facebook-f"></i>
                            </a>
                            <a
                              href={eventData.coachData?.socialAccountLinks?.instagramLink}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <i className="uil uil-instagram"></i>
                            </a>
                            <Link to="#/">
                              <i className="uil uil-youtube"></i>
                            </Link>
                          </nav>
                        </div>
                        <div className="col-lg-5">
                          <div className="mx-1">
                            <h3 className="mb-0">Date & Time</h3>
                            <p className="mb-4">
                              <i className="uil uil-calendar-alt"></i> {moment(eventData.eventDate).tz(eventData.timeZone).format('dddd, MMMM D, YYYY')} {`${moment(eventData.eventDate + ' ' + eventData.eventStartTime + ':00' ).tz(eventData.timeZone).format('h:mmA')} - ${moment(eventData.eventDate + ' ' + eventData.eventEndTime + ':00' ).tz(eventData.timeZone).format('h:mmA zz')} `}
                            </p>
                            <h3 className="mb-0">Location</h3>
                            <p className="mb-4">
                              <i className="uil uil-location-pin-alt"></i> {eventData.eventType === 'online' ? 'Virtual' : eventData.eventType === 'inperson' ? eventData.location : ''}
                            </p>
                            <h3 className="mb-0">Price</h3>
                            <p className="mb-8">{eventData.priceCurrency}{parseFloat(eventData.price).toFixed(2)}/Person</p>
                          </div>
                          <form>
                            <div className="mb-10 d-flex">
                              {
                                ((moment().tz(eventData.timeZone) < moment(eventData.eventDate).tz(eventData.timeZone))
                                && (eventData.totlBookedTickets < eventData.maxCapacity))
                                ?
                                <div className="flex-grow-1 mx-2">
                                  <Link
                                    to={ !localStorage.getItem('ya-user-access-token') ? '#/' : `/events/event-checkout/${eventURL}` }
                                    className="btn btn-primary btn-icon btn-icon-start rounded w-100"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    title={ !localStorage.getItem('ya-user-access-token') ? 'Please login to checkout': '' }
                                  >
                                    RSVP
                                  </Link>
                                </div>
                                :
                                <div className="flex-grow-1 mx-2">
                                  <Link
                                    to={null}
                                    className="btn btn-soft-ash btn-icon btn-icon-start rounded w-100"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                  >
                                    RSVP
                                  </Link>
                                </div>
                              }
                              <div className="dropdown share-dropdown btn-group">
                                <button className="btn btn-outline-primary btn-icon btn-icon-start rounded dropdown-toggle px-3 h-100" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                  <i className="uil uil-share"></i>
                                </button>
                                <div className="dropdown-menu">
                                  <Link
                                    className="dropdown-item"
                                    target="_blank"
                                    to={`https://twitter.com/share?&url=${window.location.href}`}
                                  >
                                    <i className="uil uil-twitter"></i>Twitter
                                  </Link>
                                  <Link
                                    className="dropdown-item"
                                    target="_blank"
                                    to={`https://www.facebook.com/sharer.php?u=${window.location.href}`}
                                  >
                                    <i className="uil uil-facebook-f"></i>Facebook
                                  </Link>
                                  <Link className="dropdown-item" to="#/">
                                    <i className="uil uil-instagram"></i>Instgram
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </form>
                          <div className="map mx-1">
                            <h3 className="mb-2">Map view</h3>
                            <iframe
                              title="Event Details Map"
                              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d25387.23478654725!2d-122.06115399490332!3d37.309248660190086!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x808fb4571bd377ab%3A0x394d3fe1a3e178b4!2sCupertino%2C%20CA%2C%20USA!5e0!3m2!1sen!2str!4v1645437305701!5m2!1sen!2str"
                              style={{ width: '100%', height: '500px', border: '0' }}
                              allowFullScreen
                            ></iframe>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </>
            }
          </>
        }
      </Layout>
    </>
  );
};

export default EventDetails;