import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  Layout,
  cleanUpBeforePageLoad,
  loadJSScript,
  PageLoader
} from '../components/CommonFunction';

const Home = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (loading) {
      setTimeout(() => {
        setLoading(false);
        cleanUpBeforePageLoad();
        loadJSScript("/assets/js/theme.js", 'themeScript');
        window.scrollTo(0, 0);
      }, 1000);
    }
  }, [loading]);

  return (
    <>
      {/*<div className="page-loader"></div>*/}
      
      <Layout pageLoader={true} >
        {
          loading
          ?
          <PageLoader />
          :
          <div className="content-wrapper">
            <section className="wrapper bg-gray">
              <div className="container pt-18 text-center">
                <div className="row">
                  <div className="col-lg-8 mx-auto text-center" data-cues="slideInDown" data-group="page-title" data-delay="600">
                    <h2 className="fs-16 text-uppercase ls-xl mb-4">Non-profit community for student athletes.</h2>
                    <h1 className="display-1 fs-58 mb-8">Your Sports, Your Passion.</h1>
                    {
                      localStorage.getItem('ya-user-access-token') !== undefined
                      && localStorage.getItem('ya-user-access-token') !== ''
                      ?
                      ''
                      :
                      <div className="d-flex justify-content-center mb-5 mb-md-0" data-cues="slideInDown" data-group="page-title-buttons" data-delay="900">
                        <span>
                          <Link
                            to="/#"
                            className="btn btn-primary btn-lg rounded-pill mx-1 mb-2 mb-md-0"
                            data-bs-toggle="modal"
                            data-bs-target="#modal-signup"
                          >
                            Sign up free membership
                          </Link>
                        </span>
                      </div>
                    }
                  </div>
                </div>
              </div>
              <figure className="position-absoute" style={{ bottom: 0, left: 0, zIndex: 2, }}>
                <img src="./assets/img/photos/bg11.jpg" alt="" />
              </figure>
            </section>
            <section className="wrapper">
              <div className="container py-14 text-center">
                <div className="col-lg-10 m-auto">
                    <h1 className="display-4">Our Community</h1>
                    <p>At YoungAthlete, we're dedicated to fostering a love for sports and empowering the next generation of athletes to thrive. From soccer to basketball, swimming to track and field, our programs offer students at all skill levels the opportunity to develop their talents in a supportive and encouraging environment. Join us in celebrating the joy of sportsmanship, camaraderie, and personal growth. Together, let's inspire greatness and create memories that last a lifetime!</p>
                    <Link to="/about-us" className="hover">Learn more -></Link>
                </div>
              </div>
            </section>
            <section className="wrapper bg-gray">
              <div className="container py-12">
                <div className="row">
                  <div className="col-lg-6 mb-6">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="row gx-md-5 gy-5">
                          <div className="col-md-10 offset-md-2">
                            <figure className="rounded">
                              <img src="./assets/img/photos/ab11.jpg" srcSet="./assets/img/photos/ab11.jpg 2x" alt="" />
                            </figure>
                          </div>
                          <div className="col-md-12 mb-4">
                            <figure className="rounded">
                              <img src="./assets/img/photos/ab22.jpg" srcSet="./assets/img/photos/ab22.jpg 2x" alt="" />
                            </figure>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <figure className="rounded">
                          <img src="./assets/img/photos/ab33.jpg" srcSet="./assets/img/photos/ab33.jpg 2x" alt="" />
                        </figure>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <h1 className="display-4">Become a Member</h1>
                    <p>unlocks members-only resources, exclusive opportunities to unleash your great protentials!</p>
                    <div className="d-flex flex-row mb-6">
                      <div>
                          <span className="icon btn btn-circle btn-primary pe-none me-5"><span className="number fs-18">1</span></span>
                      </div>
                      <div>
                          <h4 className="mb-1">Junior Kickstart</h4>
                          <p className="mb-0">Learn new sports with our highly experienced coach to discover youth protentials.</p>
                      </div>
                    </div>
                    <div className="d-flex flex-row mb-6">
                      <div>
                          <span className="icon btn btn-circle btn-primary pe-none me-5"><span className="number fs-18">2</span></span>
                      </div>
                      <div>
                          <h4 className="mb-1">Recuriting and Carrer</h4>
                          <p className="mb-0">Exclusive meetings and seminars from industry professionals to help student athletes on the college recuriting and professional careers.</p>
                      </div>
                    </div>
                    <div className="d-flex flex-row mb-6">
                      <div>
                          <span className="icon btn btn-circle btn-primary pe-none me-5"><span className="number fs-18">3</span></span>
                      </div>
                      <div>
                        <h4 className="mb-1">Athlete Profile</h4>
                        <p className="mb-6">Build your online profile to connect, share and grow with fellow members.</p>
                        {
                          localStorage.getItem('ya-user-access-token') !== undefined
                          && localStorage.getItem('ya-user-access-token') !== ''
                          ?
                          ''
                          :
                          <Link to="/#" data-bs-toggle="modal" data-bs-target="#modal-signup">Sign up -> </Link>
                        }  
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section className="wrapper">
              <div className="container py-12">
                <div className="row">
                  <div className="col-lg-9 col-xl-8 col-xxl-7 mx-auto text-center">
                    <h1 className="display-4 mb-10">Our Events</h1>
                  </div>
                </div>
                <div className="swiper-container grid-view mb-6" data-margin="30" data-dots="true" data-items-xl="3" data-items-md="2" data-items-xs="1">
                  <div className="swiper">
                    <div className="swiper-wrapper">
                      <div className="swiper-slide">
                        <article>
                          <figure className="rounded mb-5">
                            <Link to="/#">
                              <img src="./assets/img/photos/b4.jpg" alt="" />
                            </Link>
                          </figure>
                          <div className="post-header">
                            <h3>
                              <Link className="link-dark" to="/#">Ligula tristique quis risus</Link>
                            </h3>
                          </div>
                          <div className="post-footer">
                            <ul className="post-meta">
                              <li className="post-date">
                                <i className="uil uil-calendar-alt"></i>
                                <span>Apr 14, 2022</span>
                              </li>
                            </ul>
                          </div>
                        </article>
                      </div>
                      <div className="swiper-slide">
                        <article>
                          <figure className="rounded mb-5">
                            <Link to="/#">
                              <img src="./assets/img/photos/b5.jpg" alt="" />
                            </Link>
                          </figure>
                          <div className="post-header">
                            <h3>
                              <Link to="/#" className="link-dark">Nullam id dolor elit id nibh</Link>
                            </h3>
                          </div>
                          <div className="post-footer">
                            <ul className="post-meta">
                              <li className="post-date">
                                <i className="uil uil-calendar-alt"></i>
                                <span>Mar 29, 2022</span>
                              </li>
                            </ul>
                          </div>
                        </article>
                      </div>
                      <div className="swiper-slide">
                        <article>
                          <figure className="rounded mb-5">
                            <Link to="/#">
                              <img src="./assets/img/photos/b6.jpg" alt="" />
                            </Link>
                          </figure>
                          <div className="post-header">
                            <h3>
                              <Link to="/#" className="link-dark">Ultricies fusce porta elit</Link>
                            </h3>
                          </div>
                          <div className="post-footer">
                            <ul className="post-meta">
                              <li className="post-date">
                                <i className="uil uil-calendar-alt"></i>
                                <span>Feb 26, 2022</span>
                              </li>
                            </ul>
                          </div>
                        </article>
                      </div>
                      <div className="swiper-slide">
                        <article>
                          <figure className="overlay overlay-1 hover-scale rounded mb-5">
                            <Link to="/#">
                              <img src="./assets/img/photos/b6.jpg" alt="" />
                            </Link>
                            <figcaption>
                              <h5 className="from-top mb-0">Read More</h5>
                            </figcaption>
                          </figure>
                          <div className="post-header">
                            <h3>
                              <Link to="/#" className="link-dark">Morbi leo risus porta eget</Link>
                            </h3>
                          </div>
                          <div className="post-footer">
                            <ul className="post-meta">
                              <li className="post-date">
                                <i className="uil uil-calendar-alt"></i>
                                <span>7 Jan 2022</span>
                              </li>
                            </ul>
                          </div>
                        </article>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section className="wrapper bg-gray">
              <div className="container py-12">
                <div className="row gx-lg-8 gx-xl-12 gy-10 align-items-center">
                  <div className="col-lg-4">
                    <h1 className="display-5 mb-5">Meet Sport Ambassador</h1>
                    <p>Our ambassadors work with coaches and local communities to provide junior sport classes.</p>
                    <Link to="/get-involved" className="hover">Learn more -></Link>
                  </div>
                  <div className="col-lg-8">
                    <div className="swiper-container text-center mb-6" data-margin="30" data-dots="true" data-items-xl="3" data-items-md="2" data-items-xs="1">
                      <div className="swiper">
                        <div className="swiper-wrapper">
                          <div className="swiper-slide">
                            <img className="rounded-circle w-20 mx-auto mb-4" src="./assets/img/avatars/t1.jpg" srcSet="./assets/img/avatars/t1@2x.jpg 2x" alt="" />
                            <h4 className="mb-0">Cory Zamora</h4>
                            <div className="mb-2">San Francisco, CA</div>
                            <div className="badge badge-lg bg-primary rounded-pill px-4 py-2">Track and field</div>
                          </div>
                          <div className="swiper-slide">
                            <img className="rounded-circle w-20 mx-auto mb-4" src="./assets/img/avatars/t2.jpg" srcSet="./assets/img/avatars/t2@2x.jpg 2x" alt="" />
                            <h4 className="mb-1">Coriss Ambady</h4>
                            <div className="mb-2">Newyork, NY</div>
                            <div className="badge badge-lg bg-primary rounded-pill px-4 py-2">Tennis</div>
                          </div>
                          <div className="swiper-slide">
                            <img className="rounded-circle w-20 mx-auto mb-4" src="./assets/img/avatars/t3.jpg" srcSet="./assets/img/avatars/t3@2x.jpg 2x" alt="" />
                            <h4 className="mb-1">Nikolas Brooten</h4>
                            <div className="mb-2">San Francisco, CA</div>
                            <div className="badge badge-lg bg-primary rounded-pill px-4 py-2">Fencing</div>
                          </div>
                          <div className="swiper-slide">
                            <img className="rounded-circle w-20 mx-auto mb-4" src="./assets/img/avatars/t4.jpg" srcSet="./assets/img/avatars/t4@2x.jpg 2x" alt="" />
                            <h4 className="mb-1">Jackie Sanders</h4>
                            <div className="mb-2">San Francisco, CA</div>
                            <div className="badge badge-lg bg-primary rounded-pill px-4 py-2">Swimming</div>
                          </div>
                          <div className="swiper-slide">
                            <img className="rounded-circle w-20 mx-auto mb-4" src="./assets/img/avatars/t5.jpg" srcSet="./assets/img/avatars/t5@2x.jpg 2x" alt="" />
                            <h4 className="mb-1">Tina Geller</h4>
                            <div className="mb-2">San Francisco, CA</div>
                            <div className="badge badge-lg bg-primary rounded-pill px-4 py-2">Golf</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section className="wrapper">
              <div className="container-card mt-12">
                <div className="card image-wrapper bg-full bg-image bg-overlay bg-overlay-light-500 mb-14" data-image-src="./assets/img/photos/bg22.png" /*style={{ background-image: url('./assets/img/photos/bg22.png') }}*/ >
                  <div className="card-body py-14 px-0">
                    <div className="container">
                      <div className="row text-center">
                        <div className="col-xl-11 col-xxl-10 mx-auto">
                          <h3 className="display-4 mb-6 px-lg-5">We believe in action, not just words. Through our Kickstart program, we help juniors to learn sports, discover their protentials and support them to thrive in sports and life!</h3>
                        </div>
                      </div>
                      <div className="d-flex justify-content-center">
                        <Link to="/#" className="btn btn-primary btn-lg rounded-pill mx-1">Join us today</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        }
      </Layout>
    </>
  );
};

export default Home;