import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, Link } from 'react-router-dom';
import {
  Loader,
  Layout,
  cleanUpBeforePageLoad,
  loadJSScript,
  PageLoader,
  requestApi,
} from './CommonFunction';
import '../styles/custom.css';

export const OtpLoginStepOne = ({ nextStep, handleFormData, formData }) => {
  const [sendOTPButton, setSendOTPButton] = useState(false);
  const [successMsg, setSuccessMsg] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm();

  const handleSendOTPFormSubmit = async (values) => {
    isValid ? setSendOTPButton(true) : setSendOTPButton(false)

    await requestApi(
      'POST',
      'user-login-through-otp',
      {},
      {},
      values
    ).then((result) => {
      setSuccessMsg('OTP send successfully.Please verify.');
      // setTimeout(() => {
      //   setSendOTPButton(false);
      // }, 1500);
      setSendOTPButton(false);
      setErrorMsg('');
      nextStep();
    }).catch((error) => {
      setErrorMsg(error?.response?.data?.message);
      setSendOTPButton(false);
    });

    //setErrorMsg('Some error is occured.');

    // setTimeout(() => {
    //   nextStep();
    // }, 2000);
  };

	return (
		<>
      {/*<div className="page-loader"></div>*/}

      <Layout>
  			<div className="content-wrapper">
  			  <section className="wrapper bg-soft-primary">
            <div className="container py-16 text-center">
              <div className="card col-sm-12 col-lg-8 m-auto">
                <div className="card-body py-xxl-12">     
                  <h1 className="display-1 fs-58 mb-10">Login Through OTP</h1>
                  <p className="mb-7">Enter the email address where you'd like to receive a one time passcode.</p> 
                  <div className="row gx-md-0 gx-xl-12 text-center">
                    <div className="col-lg-10 col-sm-12 m-auto">
                      {
                        successMsg &&
                        <div className="alert alert-success alert-icon alert-dismissible fade show text-start" role="alert">
                          <i className="uil uil-check"></i>{successMsg} <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                        </div>  
                      }
                      {
                        errorMsg &&
                        (typeof errorMsg === "string") &&
                        <div className="alert alert-danger alert-icon alert-dismissible fade show text-start" role="alert">
                          <i className="uil uil-exclamation-triangle"></i>{errorMsg} <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                        </div>  
                      }

                      <form
                        className="text-start mb-3"
                        onSubmit={handleSubmit(handleSendOTPFormSubmit)}
                      >
                        <div className="form-floating mb-4">
                          <input
                            type="email"
                            className="form-control"
                            placeholder="Email"
                            id="otpLoginEmail"
                            {...register('email', {
                              required: true,
                              pattern: /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/, //eslint-disable-line
                              onChange: handleFormData,
                              value: formData?.email
                            })}
                          />
                          <label htmlFor="otpEmail">Email</label>
                          {
                            errors.email?.type === "required" && 
                            <div className="formFieldError">
                              Please enter your email address.
                            </div>
                          }
                          {
                            errors.email?.type === "pattern" && 
                            <div className="formFieldError">
                              Please enter valid email address.
                            </div>
                          }
                          {
                            errorMsg &&
                            (typeof errorMsg === "object") &&
                            <div className="formFieldError">
                              {errorMsg[0]}
                            </div>  
                          }
                        </div>
                        <div className="text-center">
                          <p className="mb-6">
                            <button
                              disabled={sendOTPButton}
                              className="btn btn-primary rounded-pill btn-lg mx-1 mb-2 mb-md-0"
                              type="submit"
                            >
                              { sendOTPButton ? <Loader text='Send OTP' /> : 'Send OTP' }
                            </button>
                          </p> 
                        </div>
                      </form> 
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>   
  			</div>
      </Layout>
		</>
	);
};

export const OtpLoginStepTwo = ({ prevStep, handleFormData, formData }) => {
  const [loading, setLoading] = useState(true);
  const [verifyOTPButton, setVerifyOTPButton] = useState(false);
  const [successMsg, setSuccessMsg] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm();
  const navigate = useNavigate();

  const handleVerifyOTPFormSubmit = async (values) => {
    isValid ? setVerifyOTPButton(true) : setVerifyOTPButton(false)
    
    await requestApi(
      'POST',
      'verify-user-login-otp',
      {},
      {},
      values
    ).then((result) => {
      setSuccessMsg('OTP verify successfully.');
      localStorage.setItem('ya-user-access-token', result.data.response);
      //setTimeout(() => {
      //   setVerifyOTPButton(false);
      // }, 1500);
      setVerifyOTPButton(false);
      setErrorMsg('');
      navigate('/dashboard');
    }).catch((error) => {
      setErrorMsg(error?.response?.data?.message);
      setVerifyOTPButton(false);
    });

    // setTimeout(() => {
    //   navigate('/dashboard');
    // }, 2000);
  };

  useEffect(() => {
    if (loading) {
      setTimeout(() => {
        setLoading(false);
        cleanUpBeforePageLoad();
        loadJSScript("/assets/js/theme.js", 'themeScript');
        window.scrollTo(0, 0);
      }, 20);
    }
  }, [loading]);

  return (
    <>
      {/*<div className="page-loader"></div>*/}
      
      <Layout pageLoader={true} >
        {
          loading
          ?
          <PageLoader />
          :
          <div className="content-wrapper">
            <section className="wrapper bg-soft-primary">
              <div className="container py-8 text-center">
                <div className="card col-sm-12 col-lg-8 m-auto">
                  <div className="card-body py-xxl-12">
                    <h1 className="display-1 fs-58 mb-10">Verify it's you</h1>
                    <p className="mb-7">
                      {`We sent you a 6 digit passcode to ${formData?.email} to verify it's really you that want to sign in. OTP expire in 1 minute.`}
                    </p>
                    <div className="row gx-md-0 gx-xl-12 text-center">
                      <div className="col-lg-10 col-sm-12 m-auto">
                        {
                          successMsg &&
                          <div className="alert alert-success alert-icon alert-dismissible fade show text-start" role="alert">
                            <i className="uil uil-check"></i>{successMsg} <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                          </div>  
                        }
            
                        {
                          errorMsg &&
                          (typeof errorMsg === "string") &&
                          <div className="alert alert-danger alert-icon alert-dismissible fade show text-start" role="alert">
                            <i className="uil uil-exclamation-triangle"></i>{errorMsg} <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                          </div>  
                        }

                        <form
                          className="text-start mb-3"
                          onSubmit={handleSubmit(handleVerifyOTPFormSubmit)}
                        >
                          <div className="form-floating mb-4">
                            <input
                              className="form-control"
                              type="hidden"
                              placeholder="Email"
                              id="otpEmail"
                              {...register('email', {
                                required: true,
                              })}
                              value={formData?.email}
                            />
                          </div>
                          <div className="form-floating mb-4">
                            <input
                              className="form-control"
                              placeholder="OTP Code"
                              id="otpCode"
                              {...register('otp', {
                                required: true,
                                minLength: 6,
                                maxLength: 6,
                                pattern: /^[0-9]+$/,
                                onChange: handleFormData,
                              })}
                            />
                            <label htmlFor="otp">OTP</label>
                            {
                              errors.otp?.type === "required" && 
                              <div className="formFieldError">
                                Please enter 6 digit OTP.
                              </div>
                            }
                            {
                              errors.otp?.type === "minLength" && 
                              <div className="formFieldError">
                                OTP code must be minimum 6 digit.
                              </div>
                            }
                            {
                              errors.otp?.type === "maxLength" && 
                              <div className="formFieldError">
                                OTP code must be maximum 6 digit.
                              </div>
                            }
                            {
                              errors.otp?.type === "pattern" && 
                              <div className="formFieldError">
                                OTP code must be number.
                              </div>
                            }
                            {
                              errorMsg &&
                              (typeof errorMsg === "object") &&
                              <div className="formFieldError">
                                {errorMsg[1]}
                              </div>  
                            }
                          </div>
                          <div className="text-center">
                            <p className="mb-6">
                              <button
                                disabled={verifyOTPButton}
                                className="btn btn-primary rounded-pill btn-lg mx-1 mb-2 mb-md-0"
                                type="submit"
                              >
                                { verifyOTPButton ? <Loader text='Submit Code' /> : 'Submit Code' }
                              </button>
                            </p>
                            <p className="mb-6">Did not receive code? <Link to="#/" onClick={prevStep} >Request a new code </Link>
                            </p>
                            <p>if you can't find the email, check spam/junk folder. For futher assistance, please <Link to="/contact">contact us</Link>. </p>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        }
      </Layout>
    </>
  );
};