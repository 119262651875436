import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import moment from 'moment';
import 'moment-timezone';
import {
  Layout,
  cleanUpBeforePageLoad,
  loadJSScript,
  PageLoader,
  requestApi
} from '../components/CommonFunction';

const Events = () => {
  const [loading, setLoading] = useState(true);
  const [eventList, setEventList] = useState('');
  const [sportsName, setSportsName] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const { pathname, search } = useLocation();
  const [pageSearch, setPageSearch] = useState(search);
  const [sportNameFilter, setSportNameFilter] = useState('');
  const [eventTypeFilter, setEventTypeFilter] = useState('');
  const [locationFilter, setLocationFilter] = useState('');

  const loadEventData = async () => {
    let url = `get-event-list`;
    if (pageSearch !== undefined && pageSearch !== '') {
      url += pageSearch;
    }

    await requestApi(
      'GET',
      url,
      {},
      {
        sportName: sportNameFilter,
        eventType: eventTypeFilter,
        location: locationFilter
      }
    ).then((result) => {
      setErrorMsg('');
      setLoading(false);
      setEventList(result.data.response);
    }).catch((error) => {
      setLoading(false);
      setErrorMsg(error?.response?.data?.message);
    });
  };

  const loadSportsName = async () => {
    await requestApi(
      'GET',
      'get-sports-name-list',
    ).then((result) => {
      setSportsName(result.data.response);
    }).catch((error) => {
      setErrorMsg(error?.response?.data?.message);
    });
  };

  if (eventList === '') {
    loadEventData();
  }

  if (sportsName === '') {
    loadSportsName();
  }

  useEffect(() => {
    setLoading(true);
    if (eventList !== '' && eventList !== undefined) {
      setLoading(false);
      cleanUpBeforePageLoad();
      loadJSScript("/assets/js/theme.js", 'themeScript');
      window.scrollTo(0, 0);
    }
  }, [eventList, search]);

  return (
    <>
      {/*<div className="page-loader"></div>*/}
      
      <Layout pageLoader={true} >
        <div className="content-wrapper">
          <section className="wrapper">
            <div className="container pt-16 pb-10 pt-md-16 pb-md-10">
              <h1 className="display-3 mb-12 text-center">All Events</h1>
              {
                errorMsg &&
                (typeof errorMsg === "string") &&
                <div className="alert alert-danger alert-icon alert-dismissible fade show text-start" role="alert">
                  <i className="uil uil-exclamation-triangle"></i>{errorMsg} <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                </div>  
              }
              <div className="row">
                <div className="col-xl-10 col-sm-12 m-auto mb-8">
                  <form className="filter-form mb-8">
                    <div className="row g-3">
                      <div className="col-md-4">
                        <div className="form-select-wrapper">
                          <select
                            className="form-select"
                            aria-label=""
                            name="sportName"
                            onChange={({ target }) => {
                              window.history.replaceState(null, '', '/events');
                              setPageSearch('');
                              setEventList('');
                              setSportNameFilter(target.value);
                            }}
                          >
                            <option value="">Sports</option>
                            {
                              sportsName && sportsName.map((value, index) => {
                                return (
                                  <option
                                    key={`sportsNameList_${index}`}
                                    value={value}
                                  >
                                    {value}
                                  </option>
                                );
                              })
                            }
                          </select>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-select-wrapper">
                          <select
                            className="form-select"
                            aria-label=""
                            name="eventType"
                            onChange={({ target }) => {
                              window.history.replaceState(null, '', '/events');
                              setPageSearch('');
                              setEventList('');
                              setEventTypeFilter(target.value);
                            }}
                          >
                            <option value="">Type</option>
                            <option value="inperson">In person</option>
                            <option value="online">Online</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-select-wrapper">
                          <select
                            className="form-select"
                            aria-label=""
                            name="location"
                            onChange={({ target }) => {
                              window.history.replaceState(null, '', '/events');
                              setPageSearch('');
                              setEventList('');
                              setLocationFilter(target.value);
                            }}
                          >
                            <option value="">Location</option>
                            <option value="Chicago,US">Chicago, US</option>
                            <option value="Michigan,US">Michigan, US</option>
                            <option value="New York,US">New York, US</option>
                            <option value="Los Angles,US">Los Angles, US</option>
                            <option value="Moscow,Russia">Moscow, Russia</option>
                            <option value="Sydney,Australia">Sydney, Australia</option>
                            <option value="Birmingham,UK">Birmingham, UK</option>
                            <option value="Manchester,UK">Manchester, UK</option>
                            <option value="Beijing,China">Beijing, China</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </form>
                  {
                    loading
                    ?
                    <PageLoader />
                    :
                    <>
                      {
                        (eventList !== undefined
                         && eventList.data !== undefined
                         && eventList.data.length !== 0
                        )
                        ?
                        <>
                          {/*<Link to="/events/event-details/Golf-123-a-beginner-golf" className="mb-4">
                            <div className="row justify-content-between align-items-center">
                              <div className="col-lg-2 col-sm-2 col-md-2 col-3 mb-2 d-flex align-items-center text-body">
                                <span className="badge rounded bg-primary text-center w-14 h-14 pt-4">
                                  <small>NOV</small>
                                  <h1 className="pt-1 text-white">9</h1>
                                </span>
                              </div>
                              <div className="col-9">
                                <small>Thursday 5:00PM - 6:00PM </small>
                                <h4 className="mb-1">Golf 123 - a beginner golf </h4>
                                <small>Golf <span className="px-2"> | </span>San Francisco, CA <span className="px-2"> | </span>Lesson <span className="px-2">|</span>$5.00 </small>
                                <p>Discover golf world! This course allows students to further develop their golf swing and short game. This course will also review golf concepts, rules and etiquette.</p>
                              </div>
                              <div className="d-none d-lg-block col-1 text-center text-body">
                                <i className="uil uil-angle-right-b"></i>
                              </div>
                            </div>
                          </Link>
                          <hr className="my-8" />
                          <Link to="/events/event-details/Baseball-camp" className="mb-4">
                            <div className="row justify-content-between align-items-center">
                              <div className="col-lg-2 col-sm-2 col-md-2 col-3 mb-2 d-flex align-items-center text-body">
                                <span className="badge rounded bg-primary text-center w-14 h-14 pt-4">
                                  <small>NOV</small>
                                  <h1 className="pt-1 text-white">22</h1>
                                </span>
                              </div>
                              <div className="col-9">
                                <small>Thursday 5:00PM - 6:00PM </small>
                                <h4 className="mb-1">Baseball camp</h4>
                                <small>Baseball <span className="px-2"> | </span>Virtual <span className="px-2"> | </span>Seminar <span className="px-2">|</span>Free </small>
                                <p>Discover golf world! This course allows students to further develop their golf swing and short game. This course will also review golf concepts, rules and etiquette.</p>
                              </div>
                              <div className="d-none d-lg-block col-1 text-center text-body">
                                <i className="uil uil-angle-right-b"></i>
                              </div>
                            </div>
                          </Link>
                          <hr className="my-8" />
                          <Link to="/events/event-details/Golf-123-a-beginner-golf-new" className="mb-4">
                            <div className="row justify-content-between align-items-center">
                              <div className="col-lg-2 col-sm-2 col-md-2 col-3 mb-2 d-flex align-items-center text-body">
                                <span className="badge rounded bg-primary text-center w-14 h-14 pt-4">
                                  <small>NOV</small>
                                  <h1 className="pt-1 text-white">9</h1>
                                </span>
                              </div>
                              <div className="col-9">
                                <small>Thursday 5:00PM - 6:00PM </small>
                                <h4 className="mb-1">Golf 123 - a beginner golf new </h4>
                                <small>Golf <span className="px-2"> | </span>San Francisco, CA <span className="px-2"> | </span>Seminar <span className="px-2">|</span>$5.00 </small>
                                <p>Discover golf world! This course allows students to further develop their golf swing and short game. This course will also review golf concepts, rules and etiquette.</p>
                              </div>
                              <div className="d-none d-lg-block col-1 text-center text-body">
                                <i className="uil uil-angle-right-b"></i>
                              </div>
                            </div>
                          </Link>*/}

                          {
                            eventList.data.map((value, index) => {
                              return (
                                <div key={`eventsListingData_${index}`}>
                                  <Link
                                    to={`/events/event-details/${value.eventURL}`}
                                    className="mb-4"
                                  >
                                    <div className="row justify-content-between align-items-center">
                                      <div className="col-lg-2 col-sm-2 col-md-2 col-3 mb-2 d-flex align-items-center text-body">
                                        <span className="badge rounded bg-primary text-center w-14 h-14 pt-4">
                                          <small className="text-uppercase">
                                            {moment(value.eventDate).tz(value.timeZone).format('MMM')}
                                          </small>
                                          <h1 className="pt-1 text-white">
                                            {moment(value.eventDate).tz(value.timeZone).format('D')}
                                          </h1>
                                        </span>
                                      </div>
                                      <div className="col-9">
                                        <small>
                                          {moment(value.eventDate).tz(value.timeZone).format('dddd')} {`${moment(value.eventDate + ' ' + value.eventStartTime + ':00' ).tz(value.timeZone).format('h:mmA')} - ${moment(value.eventDate + ' ' + value.eventEndTime + ':00' ).tz(value.timeZone).format('h:mmA zz')} `}
                                          {/* moment().tz(value.timeZone) > moment(value.eventDate).tz(value.timeZone) ? <span className='badge bg-red rounded-pill'>Expired</span> : '' */}            
                                        </small>
                                        <h4 className="mb-1">
                                          {value.eventName}
                                        </h4>
                                        <small>
                                          <span>
                                            {value.sportName}
                                          </span>
                                          <span className="px-2"> | </span>
                                          <span className="text-capitalize">
                                            {value.eventType === 'online' ? 'Virtual' : value.eventType === 'inperson' ? value.location : ''}
                                          </span>
                                          <span className="px-2"> | </span>
                                          <span className="text-capitalize">
                                            {value.category}
                                          </span>
                                          <span className="px-2">|</span>
                                          <span className="text-capitalize">
                                            {value.price !== 0 ? `$${parseFloat(value.price).toFixed(2)}` : 'Free'}
                                          </span>
                                        </small>
                                        <p>
                                          {value.eventDetails.substr(0, 150)}...
                                        </p>
                                      </div>
                                      <div className="d-none d-lg-block col-1 text-center text-body">
                                        <i className="uil uil-angle-right-b"></i>
                                      </div>
                                    </div>
                                  </Link>
                                  { eventList.data.length !== index + 1
                                    && <hr className="my-8" />
                                  }
                                </div>
                              );
                            })
                          }

                          {
                            (eventList.paginationLinks !== undefined
                            )
                            ?
                            <nav className="mt-6 d-flex justify-content-center" aria-label="navigation">
                              <ul className="pagination">
                                <li
                                  className={`page-item ${eventList.paginationLinks.previousPageUrl === null ? 'disabled' : ''} `}
                                >
                                  <Link
                                    className="page-link"
                                    to={eventList.paginationLinks.previousPageUrl !== null
                                        ? pathname + eventList.paginationLinks.previousPageUrl.split(eventList.paginationLinks.paginationOptions.path)[1]
                                        : '#/'
                                      }
                                    onClick={() => {
                                      setPageSearch(eventList.paginationLinks.previousPageUrl.split(eventList.paginationLinks.paginationOptions.path)[1]);
                                      setEventList('');
                                    }}
                                    tabIndex="-1"
                                    aria-label="Previous"
                                  >
                                    <span aria-hidden="true">
                                      <i className="uil uil-arrow-left"></i>
                                    </span>
                                  </Link>
                                </li>
                                {
                                  Object.entries(eventList.paginationLinks.allPaginationLink).map((pageLink, index) => {
                                    return (
                                      <li
                                        key={`eventPaginationLinks${index}`}
                                        className={`page-item ${eventList.paginationLinks.currentPage.toString() === pageLink[0].toString() ? 'active' : ''}`}
                                      >
                                        <Link
                                          className="page-link"
                                          to={pathname + pageLink[1].split(eventList.paginationLinks.paginationOptions.path)[1]}
                                          onClick={() => {
                                            setPageSearch(pageLink[1].split(eventList.paginationLinks.paginationOptions.path)[1]);
                                            setEventList('');
                                          }}
                                        >
                                          {pageLink[0]}
                                        </Link>
                                      </li>
                                    );
                                  })
                                }
                                <li
                                  className={`page-item ${eventList.paginationLinks.nextPageUrl === null ? 'disabled' : ''} `}
                                >
                                  <Link
                                    className="page-link"
                                    to={eventList.paginationLinks.nextPageUrl !== null
                                        ? pathname + eventList.paginationLinks.nextPageUrl.split(eventList.paginationLinks.paginationOptions.path)[1]
                                        :
                                        '#/'
                                      }
                                    onClick={() => {
                                      setPageSearch(eventList.paginationLinks.nextPageUrl.split(eventList.paginationLinks.paginationOptions.path)[1]);
                                      setEventList('');
                                    }}
                                    aria-label="Next"
                                  >
                                    <span aria-hidden="true">
                                      <i className="uil uil-arrow-right"></i>
                                    </span>
                                  </Link>
                                </li>
                              </ul>
                            </nav>
                            :
                            ''
                          }
                        </>
                        :
                        <p className="text-center">No events found</p>
                      }
                    </>
                  }
                </div>
              </div>
            </div>
          </section>
        </div>
      </Layout>
    </>
  );
};

export default Events;