import React, { useState, useEffect } from 'react';
import { Link, Navigate, useNavigate, useLocation } from 'react-router-dom';
import {
  Layout,
  cleanUpBeforePageLoad,
  loadJSScript,
  PageLoader,
  requestApi,
} from '../../components/CommonFunction';
import moment from 'moment';
import 'moment-timezone';

const CoachDashboard = ({ userData }) => {
  const [loading, setLoading] = useState(true);
  const [coachAssignedEventList, setCoachAssignedEventList] = useState('');
  const { pathname, search } = useLocation();
  const navigate = useNavigate();

  const loadCoachAssignedEventData = async () => {
    let url = `get-coach-assigned-event-list`;
    if (search !== undefined && search !== '') {
      url += search;
    }

    await requestApi(
      'GET',
      url,
      { 'Authorization' : `Bearer ${localStorage.getItem('ya-user-access-token')} ` },
    ).then((result) => {
      setLoading(false);
      setCoachAssignedEventList(result.data.response);
    }).catch((error) => {
      setLoading(false);
      //console.log(error?.response?.data?.message);
    });
  };

  if (userData !== undefined
      && userData !== ''
      && userData.userType === 2
      && localStorage.getItem('ya-user-access-token')
      && coachAssignedEventList === ''
  ) {
    loadCoachAssignedEventData();
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    if (userData === '' && localStorage.getItem('ya-user-access-token')) {
      navigate('/');
    }
    
    if (userData !== undefined
        && userData !== null
        && userData !== ''
        && coachAssignedEventList !== ''
    ) {
      setLoading(false);
      cleanUpBeforePageLoad();
      loadJSScript("/assets/js/theme.js", 'themeScript');
      window.scrollTo(0, 0);
    }
  }, [userData, navigate, coachAssignedEventList]);

  return (
  	<>
      {/*<div className="page-loader"></div>*/}
    
      {
        !localStorage.getItem('ya-user-access-token')
        ?
        <Navigate replace to="/" />
        :
        ''
      }
      <Layout isUserLogin={true} pageLoader={true} >
        {
          loading
          ?
          <PageLoader />
          :
          <>
            {
              (userData !== undefined && userData?.userType === 2)
              ?
              <>
                <div className="content-wrapper">
                  <section className="wrapper">
                    <div className="container py-16">
                      <div className="col-lg-9 col-sm-12 m-auto">
                        <h1 className="display-4 mb-12 text-capitalize">
                          { `Hi, ${userData.fullName !== undefined ? userData.fullName : ''} ` }
                        </h1>
                        <div className="row mb-12">
                          <h4 className="mb-4">
                            My Assigned Events
                          </h4>
                          {
                            (coachAssignedEventList !== undefined
                             && coachAssignedEventList.data !== undefined
                             && coachAssignedEventList.data.length !== 0
                            )
                            ?
                            <>
                              <div className="table-responsive">
                                <table className="table">
                                  <thead>
                                    <tr>
                                      <th scope="col">Event Date</th>
                                      <th scope="col">Event Name</th>
                                      <th scope="col">Total Register Student</th>
                                      <th scope="col">Action</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {/*<tr>
                                      <td className="text-center p-16" colSpan="4">
                                        <i className="uil uil-calendar-alt"></i> No Events
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>04/15/24 3:00PM PST</td>
                                      <td>
                                        <Link to="/events/event-details/Basketball-basic">Basketball basic</Link>
                                      </td>
                                      <td>0</td>
                                      <td>
                                        <Link to="/coach/event/Basketball-basic/view-student-list">
                                          View Student List
                                        </Link>
                                      </td>
                                    </tr>*/}

                                    {
                                      coachAssignedEventList
                                      && coachAssignedEventList.data.map((value, index) => {
                                        return (
                                          <tr key={`coachAssignedEventList_${index}`}>
                                            <td>
                                              {moment(value.eventDate).tz(value.timeZone).format('YYYY/MM/DD')} {`${moment(value.eventDate + ' ' + value.eventStartTime + ':00' ).tz(value.timeZone).format('h:mmA')}-${moment(value.eventDate + ' ' + value.eventEndTime + ':00' ).tz(value.timeZone).format('h:mmA zz')} `}
                                            </td>
                                            <td>
                                              <Link to={`/events/event-details/${value.eventURL}`}>{value.eventName}</Link>
                                            </td>
                                            <td>{value.totalRegisterStudent}</td>
                                            <td>
                                              {
                                                value.totalRegisterStudent > 0
                                                ?
                                                <Link to={`/coach/event/${value.eventName}/view-student-list`}>
                                                  View Student List
                                                </Link>
                                                :
                                                '-'
                                              }
                                            </td>
                                          </tr>
                                        );
                                      })
                                    }
                                  </tbody>
                                </table>
                              </div>
                              {
                                (coachAssignedEventList !== undefined
                                 && coachAssignedEventList.data !== undefined
                                 && coachAssignedEventList.paginationLinks !== undefined
                                )
                                ?
                                <nav className="d-flex justify-content-center" aria-label="pagination">
                                  <ul className="pagination mb-0">
                                    <li className={`page-item ${coachAssignedEventList.paginationLinks !== undefined && coachAssignedEventList.paginationLinks.previousPageUrl === null ? 'disabled' : ''} `}>
                                      <Link
                                        className="page-link"
                                        to={coachAssignedEventList.paginationLinks !== undefined
                                          && coachAssignedEventList.paginationLinks.previousPageUrl !== null
                                          ? pathname + coachAssignedEventList.paginationLinks.previousPageUrl.split(coachAssignedEventList.paginationLinks.paginationOptions.path)[1]
                                          : '#/'
                                        }
                                        onClick={() => {
                                          setLoading(true);
                                          setCoachAssignedEventList('');
                                        }}
                                        aria-label="Previous"
                                      >
                                        <span aria-hidden="true"><i className="uil uil-arrow-left"></i></span>
                                      </Link>
                                    </li>
                                    {
                                      Object.entries(coachAssignedEventList.paginationLinks.allPaginationLink).map((pageLink, index) => {
                                        return (
                                          <li
                                            key={`coachAssignedEventListPaginationLinks${index}`}
                                            className={`page-item ${coachAssignedEventList.paginationLinks !== undefined && coachAssignedEventList.paginationLinks.currentPage.toString() === pageLink[0].toString() ? 'active' : ''}`}
                                          >
                                            <Link
                                              className="page-link"
                                              to={pathname + pageLink[1].split(coachAssignedEventList.paginationLinks.paginationOptions.path)[1]}
                                              onClick={() => {
                                                setLoading(true);
                                                setCoachAssignedEventList('');
                                              }}
                                            >
                                              {pageLink[0]}
                                            </Link>
                                          </li>
                                        );
                                      })
                                    }
                                    <li className={`page-item ${coachAssignedEventList.paginationLinks !== undefined && coachAssignedEventList.paginationLinks.nextPageUrl === null ? 'disabled' : ''} `}>
                                      <Link
                                        className="page-link"
                                        to={coachAssignedEventList.paginationLinks !== undefined
                                          && coachAssignedEventList.paginationLinks.nextPageUrl !== null
                                          ? pathname + coachAssignedEventList.paginationLinks.nextPageUrl.split(coachAssignedEventList.paginationLinks.paginationOptions.path)[1]
                                          :
                                          '#/'
                                        }
                                        onClick={() => {
                                          setLoading(true);
                                          setCoachAssignedEventList('');
                                        }}
                                        aria-label="Next"
                                      >
                                        <span aria-hidden="true"><i className="uil uil-arrow-right"></i></span>
                                      </Link>
                                    </li>
                                  </ul>
                                </nav>
                                :
                                ''
                              }
                            </>
                            :
                            <p className="text-center">
                              <i className="uil uil-calendar-alt"></i> No Events
                            </p>
                          }
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </>
              :
              ''
            }
          </>
        }
      </Layout>
    </>
  );
};

export default CoachDashboard;