import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import {
  PersonalInformation,
  AboutMe,
  //MyVideo,
} from '../../components/ModalForms';
import {
  Layout,
  cleanUpBeforePageLoad,
  loadJSScript,
  PageLoader,
  requestApi
} from '../../components/CommonFunction';
import NotFound from '../NotFound';
import { useParams, Link } from 'react-router-dom';

const Player = () => {
  const { userName } = useParams();
  const [loading, setLoading] = useState(true);
  const [publicProfileData, setPublicProfileData] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [successMsg, setSuccessMsg] = useState('');
  const {
    register
  } = useForm();

  const loadPublicProfileData = async () => {
    await requestApi(
      'GET',
      `user-public-profile/${userName}`,
    ).then((result) => {
      setPublicProfileData(result.data.response);
    }).catch((error) => {
      setErrorMsg(error?.response?.data?.message);
    });
  };

  const handleUpdateUserAthleteProfilePhotoFormSubmit = async (values) => {
    const athletePhoto = {
      personalInformation: {
        profilePhoto: values
      }
    };

    await requestApi(
      'POST',
      `update-user-athlete-profile-photo/${userName}`,
      { 
        'Authorization' : `Bearer ${localStorage.getItem('ya-user-access-token')}`,
        'Content-Type' : 'multipart/form-data'
      },
      {},
      athletePhoto
    ).then((result) => {
      setSuccessMsg('Your profile photo has been updated.');
      loadPublicProfileData();
      setErrorMsg('');
    }).catch((error) => {
      setSuccessMsg('');
      setErrorMsg(error?.response?.data?.message);
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    if (publicProfileData === '') {
      loadPublicProfileData();
    }

    if (publicProfileData !== undefined
        && publicProfileData !== ''
    ) {
      setLoading(false);
      cleanUpBeforePageLoad();
      loadJSScript("/assets/js/theme.js", 'themeScript');
      window.scrollTo(0, 0);
    }
    // eslint-disable-next-line
  }, [publicProfileData]);

  return (
  	<>
      {/*<div className="page-loader"></div>*/}
      
      <Layout pageLoader={true} >
        {
          loading
          ?
          <PageLoader />
          :
          <>
            <div className="content-wrapper">
              {
                /*errorMsg && errorMsg === "User public profile doesn`t exist"*/
                publicProfileData.length !== undefined && publicProfileData.length === 0
                ?
                <NotFound />
                :
                <>
                  <section className="wrapper bg-gray">
                    <div className="container pt-16">
                      {
                        successMsg &&
                        <div className="alert alert-success alert-icon alert-dismissible fade show text-start" role="alert">
                          <i className="uil uil-check"></i>{successMsg} <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                        </div>  
                      }
                      {
                        errorMsg &&
                        (typeof errorMsg === "string") &&
                        <div className="alert alert-danger alert-icon alert-dismissible fade show text-start" role="alert">
                          <i className="uil uil-exclamation-triangle"></i>{errorMsg} <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                        </div>  
                      }

                      <div className="row gy-10 gy-md-13 gy-lg-0 align-items-center">
                        <div className="col-md-8 col-lg-5 d-flex position-relative mx-auto" data-cues="slideInDown" data-group="header">
                          <div className="img-mask mask-1">
                            <img
                              src={
                                (publicProfileData?.personalInformation?.profilePhoto !== undefined
                                 && publicProfileData?.personalInformation?.profilePhoto !== '')
                                ?
                                publicProfileData?.personalInformation?.profilePhoto
                                :
                                "/assets/img/avatars/default-user.png"
                              }
                              srcSet={
                                (publicProfileData?.personalInformation?.profilePhoto !== undefined
                                 && publicProfileData?.personalInformation?.profilePhoto !== '')
                                ?
                                publicProfileData?.personalInformation?.profilePhoto
                                :
                                "/assets/img/avatars/default-user.png"
                              }
                              alt=""
                            />
                            {
                              localStorage.getItem('ya-user-access-token')
                              ?
                              <form
                                className="text-start mb-3"
                              >
                                <p className="mt-6 text-center">
                                  <input
                                    type="file"
                                    accept=".jpg,.jpeg,.png"
                                    id="athleteProfilePhoto"
                                    className="d-none"
                                    {...register('personalInformation.profilePhoto', {
                                      onChange: ({ target }) => {
                                        handleUpdateUserAthleteProfilePhotoFormSubmit(target.files[0]);
                                      }
                                    })}
                                  />
                                  <label
                                    className="personalUserAthleteProfilePhotoElementLabel"
                                    htmlFor="athleteProfilePhoto"
                                  >
                                    Update photo
                                  </label>
                                  
                                </p>
                                {
                                  errorMsg &&
                                  (typeof errorMsg === "object") &&
                                  errorMsg['personalInformation.profilePhoto'] && 
                                  <div className="formFieldError text-center">
                                    {errorMsg['personalInformation.profilePhoto'][0]}
                                  </div>
                                }
                              </form>
                              :
                              ''
                            }
                          </div>
                        </div>
                        <div className="col-lg-6 offset-lg-1 col-xxl-5 text-center text-lg-start" data-cues="slideInDown" data-group="page-title" data-delay="600">
                          <div
                            className="badge badge-lg bg-primary rounded-pill mb-4 px-4 py-2"
                          >
                            { 
                              publicProfileData?.personalInformation?.gender === 'M'
                              ?
                              `Men's ${publicProfileData?.personalInformation?.sportName}`
                              :
                              publicProfileData?.personalInformation?.gender === 'F'
                              ?
                              `Women's ${publicProfileData?.personalInformation?.sportName}`
                              :
                              ''
                            }
                          </div>
                          <h1 className="display-1 mb-4">
                            { publicProfileData?.personalInformation?.name.toUpperCase().replace('-', ' ') }
                            {
                              localStorage.getItem('ya-user-access-token')
                              ?
                              <span className="fs-14 mx-5">
                                <Link
                                  to="#/"
                                  data-bs-toggle="modal"
                                  data-bs-target="#modal-personalInfomation"
                                >
                                  Update
                                </Link>
                              </span>
                              :
                              ''
                            }
                          </h1>
                          <div className="social mb-8">
                            <a
                              href={publicProfileData?.socialMediaInfo?.twitterURL}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <i className="uil uil-twitter"></i>
                            </a>
                            <a
                              href={publicProfileData?.socialMediaInfo?.facebookURL}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <i className="uil uil-facebook-f"></i>
                            </a>
                            <a
                              href={publicProfileData?.socialMediaInfo?.instagramURL}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <i className="uil uil-instagram"></i>
                            </a>
                            <Link to="#/">
                              <i className="uil uil-youtube"></i>
                            </Link>
                          </div>
                          <div className="table-responsive">
                            <table className="table table-borderless table-hover mb-6">
                              <thead>
                                <tr>
                                  <th>HEIGHT <br />
                                    {publicProfileData?.athleticInformation?.heightInFeat}'{publicProfileData?.athleticInformation?.heightInInch}"
                                  </th>
                                  <th>WEIGHT <br />{publicProfileData?.athleticInformation?.weight}lb </th>
                                  <th className="text-capitalize">DOMINANT HAND <br />{publicProfileData?.athleticInformation?.dominantHand} </th>
                                </tr>
                              </thead>
                            </table>
                          </div>
                          <div className="table-responsive">
                            <table className="table table-hover">
                              <tbody>
                                <tr>
                                  <th>Class:</th>
                                  <td>{publicProfileData?.schoolInformation?.highSchool?.graduationYear}</td>
                                </tr>
                                <tr>
                                  <th>Current School:</th>
                                  <td>{publicProfileData?.schoolInformation?.highSchool?.name}</td>
                                </tr>
                                <tr>
                                  <th>Home Town:</th>
                                  <td>
                                    {
                                      (publicProfileData?.userHomeAddress?.city !== undefined
                                      && publicProfileData?.userHomeAddress?.city !== '')
                                      ? `${publicProfileData?.userHomeAddress?.city}, `
                                      :
                                      ''
                                    }
                                    {
                                      (publicProfileData?.userHomeAddress?.state !== undefined
                                      && publicProfileData?.userHomeAddress?.state !== '')
                                      ? `${publicProfileData?.userHomeAddress?.state}, `
                                      :
                                      ''
                                    }
                                    {
                                      (publicProfileData?.userHomeAddress?.country !== undefined
                                      && publicProfileData?.userHomeAddress?.country !== '')
                                      ? publicProfileData?.userHomeAddress?.country
                                      :
                                      ''
                                    }
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                  <section className="wrapper">
                    <div className="container py-12">
                      <div className="col-lg-9 m-auto">
                        <h1 className="display-4 mb-10 text-center">About me
                          {
                            localStorage.getItem('ya-user-access-token')
                            ?
                            <span className="fs-14 mx-5">
                              <Link
                                to="#/"
                                data-bs-toggle="modal"
                                data-bs-target="#modal-aboutMe"
                              >
                                Update
                              </Link>
                            </span>
                            :
                            ''
                          }
                        </h1>
                        <p className="mb-0">
                          {publicProfileData?.aboutMe}
                        </p>
                      </div>
                    </div>
                  </section>
                  <section className="wrapper bg-gray">
                    <div className="container py-14 py-md-16">
                      <h1 className="display-4 mb-3 text-center">My Videos
                        {
                          localStorage.getItem('ya-user-access-token')
                          ?
                          <span className="fs-14 mx-5">
                            <Link
                              to={`/player/video-list/${userName}`}
                              //data-bs-toggle="modal"
                              //data-bs-target="#modal-myVideos"
                            >
                              {/*Update*/}
                              All Videos
                            </Link>
                          </span>
                          :
                          ''
                        }
                      </h1>
                      <div className="position-relative">
                        <div className="shape rounded-circle bg-soft-yellow rellax w-16 h-16" data-rellax-speed="1" style={{ bottom: '0.5rem', right: '-1.7rem' }}></div>
                        <div className="shape bg-dot primary rellax w-16 h-16" data-rellax-speed="1" style={{ top: '-1rem', left: '-1.7rem' }}></div>
                        <div className="swiper-container dots-closer mb-6" data-margin="0" data-dots="true" data-items-xl="3" data-items-md="2" data-items-xs="1">
                          <div className="swiper">
                            <div className="swiper-wrapper">
                              {/*<div className="swiper-slide">
                                <div className="item-inner">
                                  <div className="p-4">
                                    <iframe width="358" height="200" src="https://www.youtube.com/embed/O0THbv6om4U?si=lTX-9DhzzTW5e2_2" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                                  </div>
                                </div>
                              </div>
                              <div className="swiper-slide">
                                <div className="item-inner">
                                  <div className="p-4">
                                    <iframe width="358" height="200" src="https://www.youtube.com/embed/O0THbv6om4U?si=lTX-9DhzzTW5e2_2" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                                  </div>
                                </div>
                              </div>
                              <div className="swiper-slide">
                                <div className="item-inner">
                                  <div className="p-4">
                                    <iframe width="358" height="200" src="https://www.youtube.com/embed/O0THbv6om4U?si=lTX-9DhzzTW5e2_2" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                                  </div>
                                </div>
                              </div>
                              <div className="swiper-slide">
                                <div className="item-inner">
                                  <div className="p-4">
                                    <iframe width="358" height="200" src="https://www.youtube.com/embed/O0THbv6om4U?si=lTX-9DhzzTW5e2_2" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                                  </div>
                                </div>
                              </div>
                              <div className="swiper-slide">
                                <div className="item-inner">
                                  <div className="p-4">
                                    <iframe width="358" height="200" src="https://www.youtube.com/embed/O0THbv6om4U?si=lTX-9DhzzTW5e2_2" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                                  </div>
                                </div>
                              </div>
                              <div className="swiper-slide">
                                <div className="item-inner">
                                  <div className="p-4">
                                    <iframe width="358" height="200" src="https://www.youtube.com/embed/O0THbv6om4U?si=lTX-9DhzzTW5e2_2" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                                  </div>
                                </div>
                              </div>*/}

                              {
                                publicProfileData
                                && publicProfileData.videos !== undefined
                                && publicProfileData.videos.map((value, index) => {
                                  return (
                                    <div
                                      key={`athleteVideo_${index}`}
                                      className="swiper-slide"
                                    >
                                      <div className="item-inner">
                                        <div className="p-4">
                                          <video
                                            //poster="./assets/img/photos/movie.jpg"
                                            width="500"
                                            height="200"
                                            className="player"
                                            playsInline
                                            controls
                                            preload="none"
                                          >
                                            <source
                                              src={value.video}
                                              //type="video/mp4"
                                            />
                                          </video>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                  <section className="wrapper">
                    <div className="container py-14 py-md-16">
                      <h1 className="display-4 mb-6 text-center">Career Highlights
                        {
                          localStorage.getItem('ya-user-access-token')
                          ?
                          <span className="fs-14 mx-5">
                            <Link to={`/player/career-highlights/${userName}`}>
                              {/*Update*/}
                              All Career Highlights
                            </Link>
                          </span>
                          :
                          ''
                        }
                      </h1>
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th className="ps-0">
                                <div className="h4 mb-0 text-start">Date</div>
                              </th>
                              <th>
                                <div className="h4 text-start mb-0">Event</div>
                              </th>
                              <th>
                                <div className="h4 mb-0">{/*Award*/}Description</div>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {/*<tr>
                              <td className="ps-0"> May, 2022 </td>
                              <td className="d-flex flex-row align-items-center">
                                <Link
                                  to="#/"
                                >
                                  United Soccer coaches all-america third team
                                </Link>
                              </td>
                              <td> 3rd place </td>
                            </tr>
                            <tr>
                              <td className="ps-0"> May, 2022 </td>
                              <td className="d-flex flex-row align-items-center">
                                <Link
                                  to="#/"
                                >
                                  United Soccer coaches all-america third team
                                </Link>
                              </td>
                              <td> 3rd place </td>
                            </tr>*/}
                            {
                              publicProfileData
                              && publicProfileData.careerHighlights !== undefined
                              && publicProfileData.careerHighlights.length !== 0
                              ?
                              publicProfileData.careerHighlights.map((value, index) => {
                                return (
                                  <tr key={`athleteUserCareerHighlights_${index}`}>
                                    <td className="ps-0">{value.userReadableDate}</td>
                                    <td className="d-flex flex-row align-items-center">
                                      {value.eventName}
                                    </td>
                                    <td>{value.description}</td>
                                  </tr>
                                );
                              })
                              :
                              null
                            }
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </section>
                  <section className="wrapper bg-gray">
                    <div className="container py-14 py-md-16">
                      <h1 className="display-4 mb-6 text-center">Latest Events
                        {
                          localStorage.getItem('ya-user-access-token')
                          ?
                          <span className="fs-14 mx-5">
                            <Link to={`/player/events/${userName}`}>
                              {/*Update*/}
                              All Events
                            </Link>
                          </span>
                          :
                          ''
                        } 
                      </h1>
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th className="ps-0">
                                <div className="h4 mb-0 text-start">Date</div>
                              </th>
                              <th>
                                <div className="h4 text-start mb-0">Event</div>
                              </th>
                              <th>
                                <div className="h4 mb-0">Location</div>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {/*<tr>
                              <td className="ps-0"> May 19, 2024 </td>
                              <td className="d-flex flex-row align-items-center">
                                <Link to="#/">United Soccer coaches all-america third team</Link>
                              </td>
                              <td> San Francisco, CA </td>
                            </tr>
                            <tr>
                              <td className="ps-0"> May, 2022 </td>
                              <td className="d-flex flex-row align-items-center">
                                <Link to="#/">United Soccer coaches all-america third team</Link>
                              </td>
                              <td> New york, NY </td>
                            </tr>*/}
                            {
                              publicProfileData
                              && publicProfileData.eventList !== undefined
                              && publicProfileData.eventList.length !== 0
                              ?
                              publicProfileData.eventList.map((value, index) => {
                                return (
                                  <tr key={`athleteUserEvents_${index}`}>
                                    <td className="ps-0">{value.userReadableDate}</td>
                                    <td className="d-flex flex-row align-items-center">
                                      {value.eventName}
                                    </td>
                                    <td>{value.location}</td>
                                  </tr>
                                );
                              })
                              :
                              null
                            }
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </section>
                  <section className="wrapper">
                    <div className="container py-14 py-md-16">
                      <h1 className="display-4 mb-6 text-center">Academics
                        {
                          localStorage.getItem('ya-user-access-token')
                          ?
                          <span className="fs-14 mx-5">
                            <Link to={`/player/academics-details/${userName}`}>
                              {/*Update*/}
                              All Academics Details
                            </Link>
                          </span>
                          :
                          ''
                        }
                      </h1>
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th className="ps-0">
                                <div className="h4 mb-0 text-start">Date</div>
                              </th>
                              <th>
                                <div className="h4 text-start mb-0">Name</div>
                              </th>
                              <th>
                                <div className="h4 mb-0">Result</div>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {/*<tr>
                              <td className="ps-0"> May, 2022 </td>
                              <td className="d-flex flex-row align-items-center">
                                <Link to="#/">United Soccer coaches all-america third team</Link>
                              </td>
                              <td> 3rd place </td>
                            </tr>
                            <tr>
                              <td className="ps-0"> May, 2022 </td>
                              <td className="d-flex flex-row align-items-center">
                                <Link to="#/">United Soccer coaches all-america third team</Link>
                              </td>
                              <td> 3rd place </td>
                            </tr>*/}
                            {
                              publicProfileData
                              && publicProfileData.academicDetails !== undefined
                              && publicProfileData.academicDetails.length !== 0
                              ?
                              publicProfileData.academicDetails.map((value, index) => {
                                return (
                                  <tr key={`athleteUserAcademicDetails_${index}`}>
                                    <td className="ps-0">{value.userReadableDate}</td>
                                    <td className="d-flex flex-row align-items-center">
                                      {value.academicName}
                                    </td>
                                    <td>
                                      { `${value.result} ${value.resultType === 'marks' ? 'Marks' : value.resultType === 'grades' ? 'GPA' : value.resultType === 'percentage' ? '%' : '' } `}
                                    </td>
                                  </tr>
                                );
                              })
                              :
                              null
                            }
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </section>
                </>
              }
            </div>

            {
              /*!errorMsg &&*/
              !publicProfileData.length &&
              <>
              <PersonalInformation
                userName={userName}
                publicProfileData={publicProfileData}
              />

              <AboutMe
                userName={userName}
                publicProfileData={publicProfileData}
              />

              {/*<MyVideo />*/}
              </>
            }
          </>
        }
      </Layout>
    </>
  );
};

export default Player;