import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  Layout,
  cleanUpBeforePageLoad,
  loadJSScript,
  PageLoader
} from '../components/CommonFunction';

const GetInvolved = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (loading) {
      setTimeout(() => {
        setLoading(false);
        cleanUpBeforePageLoad();
        loadJSScript("/assets/js/theme.js", 'themeScript');
        window.scrollTo(0, 0);
      }, 1000);
    }
  }, [loading]);

  return (
  	<>
      {/*<div className="page-loader"></div>*/}
      
      <Layout pageLoader={true} >
        {
          loading
          ?
          <PageLoader />
          :
          <div className="content-wrapper">
            <section className="wrapper bg-gray">
              <div className="container-fluid py-16">
                <div className="swiper-container swiper-auto swiper-container-0" data-margin="30" data-dots="true" data-nav="true" data-centered="true" data-loop="true" data-items-auto="true">
                  <div className="swiper swiper-initialized swiper-horizontal swiper-backface-hidden">
                    <div className="swiper-wrapper">
                      <div className="swiper-slide" role="group" aria-label="1 / 6" data-swiper-slide-index="1" style={{ marginRight: '30px' }}
                      >
                        <figure className="rounded">
                          <img src="./assets/img/photos/cf5.jpg" alt="" />
                        </figure>
                      </div>
                      <div className="swiper-slide" role="group" aria-label="2 / 6" data-swiper-slide-index="2" style={{ marginRight: '30px' }}>
                        <figure className="rounded">
                          <img src="./assets/img/photos/cf6.jpg" alt="" />
                        </figure>
                      </div>
                      <div className="swiper-slide" role="group" aria-label="3 / 6" data-swiper-slide-index="3" style={{ marginRight: '30px' }}>
                        <figure className="rounded">
                          <img src="./assets/img/photos/cf1.jpg" alt="" />
                        </figure>
                      </div>
                      <div className="swiper-slide swiper-slide-prev" role="group" aria-label="4 / 6" data-swiper-slide-index="4" style={{ marginRight: '30px' }}>
                        <figure className="rounded">
                          <img src="./assets/img/photos/cf2.jpg" alt="" />
                        </figure>
                      </div>
                      <div className="swiper-slide swiper-slide-active" role="group" aria-label="5 / 6" data-swiper-slide-index="5" style={{ marginRight: '30px' }}>
                        <figure className="rounded">
                          <img src="./assets/img/photos/cf3.jpg" alt="" />
                        </figure>
                      </div>
                      <div className="swiper-slide swiper-slide-next" role="group" aria-label="6 / 6" data-swiper-slide-index="6" style={{ marginRight: '30px' }}>
                        <figure className="rounded">
                          <img src="./assets/img/photos/cf4.jpg" alt="" />
                        </figure>
                      </div>
                    </div>
                    <span className="swiper-notification" aria-live="assertive" aria-atomic="true"></span>
                  </div>
                </div>
              </div>
            </section>
            <section className="wrapper">
              <div className="container py-14 py-md-16">
                <div className="row">
                  <div className="col-10 mx-auto">
                    <h2 className="display-1">Your involvement matters. Coaching, volunteering or donate to us, any helps are appricicated and it will make great impact to our student athletes and community!</h2>
                  </div>
                </div>
              </div>
            </section>
            <section className="wrapper bg-gray">
              <div className="container py-12">
                <div className="row gy-6 gx-lg-8 align-items-center">
                  <div className="col-lg-5 order-lg-2">
                  <figure className="rounded position-relative"> <img src="./assets/img/photos/g6.jpg" alt="" />
                 </figure>
                  </div>
                  <div className="col-lg-7">
                    <h3 className="display-4 mb-6">Be a Sport Ambassador</h3>
                          <p>Ready to lead the program to help kids into sports? Do you or your organization work with students? Consider becoming a <strong>sport ambassador</strong>! </p>
                    <p className="mb-6">Sport Ambassadors work with coaches to organize kickstart events that encourage junior sports and discover the protentials</p>
                    <p>
                      <Link to="#/" className="btn btn-primary btn-lg rounded-pill">Apply</Link>
                    </p>
                  </div>
                </div>
              </div>
            </section>
            <section className="wrapper bg-light">
              <div className="container py-12">
                <div className="row gy-6 gx-lg-8 align-items-center">
                  <div className="col-lg-5">
                 <figure className="rounded position-relative"><img src="./assets/img/photos/g5.jpg" alt="" />
                 </figure>
                  </div>
                  <div className="col-lg-7">
                    <h3 className="display-4 mb-6">Be a volunteer coach</h3>
                    <p className="mb-6">Coaching is a way to give back to the community. Your can influence juniors not just in the sport, but also in their character and values. Coaching can be a lot of fun! It allows you to engage with the sport in a new way, be creative in teaching and training, and enjoy the energy and enthusiasm of young athletes!
                    </p>
                    <p>
                      <Link to="#/" className="btn btn-primary btn-lg rounded-pill">Apply</Link>
                    </p>
                  </div>
                </div>
              </div>
            </section>
            <section className="wrapper bg-gray mb-12">
              <div className="container py-12">
                <div className="row gy-6 gx-lg-8 align-items-center">
                  <div className="col-lg-5 order-lg-2">
                  <figure className="rounded position-relative"><img src="./assets/img/photos/g2.jpg" alt="" />
                 </figure> 
                  </div>
                  <div className="col-lg-7">
                    <h3 className="display-4 mb-6">Donate to our programs</h3>
                    <p className="mb-6">Your donation keeps our program running, brings opportunities to our young athletes and It builds and strengthens our community!</p>
                    <p>
                      <Link to="#/" className="btn btn-primary btn-lg rounded-pill">Donate today</Link>
                    </p>
                  </div>
                </div>
              </div>
            </section>
            <section className="wrapper">
              <div className="container-card">
                <div className="card image-wrapper bg-full bg-image bg-overlay bg-overlay-light-500 mb-14" data-image-src="./assets/img/photos/bg22.png" /*style={{ background-image: url('./assets/img/photos/bg22.png') }}*/ >
                  <div className="card-body py-14 px-0">
                    <div className="container">
                      <div className="row text-center">
                        <div className="col-xl-11 col-xxl-10 mx-auto">
                          <h3 className="display-4 mb-6 px-lg-5">We believe in action, not just words. Through our Kickstart program, we help juniors to learn sports, discover their protentials and support them to thrive in sports and life!</h3>
                        </div>
                      </div>
                      <div className="d-flex justify-content-center">
                        <Link to="/#" className="btn btn-primary btn-lg rounded-pill mx-1">Join us today</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        }
      </Layout>
    </>
  );
};

export default GetInvolved;