import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Link, Navigate } from 'react-router-dom';
import {
  Loader,
  Layout,
  cleanUpBeforePageLoad,
  loadJSScript,
  PageLoader,
  requestApi,
} from '../CommonFunction';

const ChangePassword = () => {
  const [loading, setLoading] = useState(true);
  const [changePasswordButton, setChangePasswordButton] = useState(false);
  const [successMsg, setSuccessMsg] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = useForm();

  const handleChangePasswordFormSubmit = async (values) => {
    isValid ? setChangePasswordButton(true) : setChangePasswordButton(false)

    await requestApi(
      'POST',
      'change-user-password',
      { 
        'Authorization' : `Bearer ${localStorage.getItem('ya-user-access-token')}`,
      },
      {},
      values
    ).then((result) => {
      setSuccessMsg('Your password has been updated.');
      // setTimeout(async () => {
      //   setChangePasswordButton(false);
      //   reset();
      // }, 1500);
      setChangePasswordButton(false);
      setErrorMsg('');
      reset();
    }).catch((error) => {
      setSuccessMsg('');
      setErrorMsg(error?.response?.data?.message);
      setChangePasswordButton(false);
    });
    //setErrorMsg('Some errors are occured.');
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    if (loading) {
      setTimeout(() => {
        setLoading(false);
        cleanUpBeforePageLoad();
        loadJSScript("/assets/js/theme.js", 'themeScript');
        window.scrollTo(0, 0);
      }, 1000);
    }
  }, [loading]);

  return (
    <>
      {/*<div className="page-loader"></div>*/}

      {
        !localStorage.getItem('ya-user-access-token')
        ?
        <Navigate replace to="/" />
        :
        ''
      }

      <Layout isUserLogin={true} pageLoader={true} >
        <div className="content-wrapper">
          <section className="wrapper">
            <div className="container py-14">
              <div className="col-xl-7 col-lg-9 col-md-12 m-auto">
                <nav className="d-inline-block" aria-label="breadcrumb">
                  <ol className="breadcrumb mb-6">
                    <li className="breadcrumb-item">
                      <Link to="/account">Account</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">Change password</li>
                  </ol>
                </nav>
                {/*<div className="alert alert-success alert-icon alert-dismissible fade show" role="alert">
                  <i className="uil uil-check-circle"></i>Your password has been updated. <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                </div>*/}

                {
                  successMsg &&
                  <div className="alert alert-success alert-icon alert-dismissible fade show text-start" role="alert">
                    <i className="uil uil-check"></i>{successMsg} <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                  </div>  
                }
                {
                  errorMsg &&
                  (typeof errorMsg === "string") &&
                  <div className="alert alert-danger alert-icon alert-dismissible fade show text-start" role="alert">
                    <i className="uil uil-exclamation-triangle"></i>{errorMsg} <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                  </div>  
                }

                <h2 className="mb-6">Change Password</h2>
                {
                  loading
                  ?
                  <PageLoader />
                  :
                  <form
                    className="text-start mb-3"
                    onSubmit={handleSubmit(handleChangePasswordFormSubmit)}
                  >
                    <div className="row g-3 mb-12">
                      <div className="col-12">
                        <div className="form-floating password-field">
                          <input
                            id="currentPassword"
                            type="password"
                            className="form-control"
                            placeholder="Current Password"
                            {...register('currentPassword', {
                              required: true,
                              minLength: 8,
                              maxLength: 12,
                            })}
                          />
                          <span className="password-toggle">
                            <i className="uil uil-eye"></i>
                          </span>
                          <label htmlFor="currentPassword">Current Password</label>
                          {/*<div className="invalid-feedback"> Password doesn't match. </div>*/}

                          {
                            errors.currentPassword?.type === "required" && 
                            <div className="formFieldError">
                              Please enter your current password.
                            </div>
                          }
                          {
                            errors.currentPassword?.type === "minLength" && 
                            <div className="formFieldError">
                              Current password length must be minimum 8 character.
                            </div>
                          }
                          {
                            errors.currentPassword?.type === "maxLength" && 
                            <div className="formFieldError">
                              Current password length must be maximum 12 character.
                            </div>
                          }
                          {
                            errorMsg &&
                            (typeof errorMsg === "object") &&
                            errorMsg?.currentPassword && 
                            <div className="formFieldError">
                              {errorMsg.currentPassword[0]}
                            </div>
                          }
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-floating password-field">
                          <input
                            id="newPassword"
                            type="password"
                            className="form-control"
                            placeholder="New Password"
                            required=""
                            {...register('newPassword', {
                              required: true,
                              minLength: 8,
                              maxLength: 12,
                            })}
                          />
                          <span className="password-toggle">
                            <i className="uil uil-eye"></i>
                          </span>
                          <label htmlFor="newPassword">New Password</label>
                          {/*<div className="invalid-feedback"> Password doesn't meet the requirement. </div>*/}
                          
                          {
                            errors.newPassword?.type === "required" && 
                            <div className="formFieldError">
                              Please enter your new password.
                            </div>
                          }
                          {
                            errors.newPassword?.type === "minLength" && 
                            <div className="formFieldError">
                              New password length must be minimum 8 character.
                            </div>
                          }
                          {
                            errors.newPassword?.type === "maxLength" && 
                            <div className="formFieldError">
                              New password length must be maximum 12 character.
                            </div>
                          }
                          {
                            errorMsg &&
                            (typeof errorMsg === "object") &&
                            errorMsg?.newPassword && 
                            <div className="formFieldError">
                              {errorMsg.newPassword[0]}
                            </div>
                          }
                        </div>
                      </div>
                      {/*<p className="mb-0">your password must be 8-32 characters, with at least 1 uppercase.</p>*/}
                      <p className="mb-0">your password must be 8-12 characters.</p>
                    </div>
                    <button
                      disabled={changePasswordButton}
                      className="btn btn-primary rounded-pill btn-lg w-100 mb-2"
                      type="submit"
                    >
                      { changePasswordButton ? <Loader text='Update' /> : 'Update' }
                    </button>
                  </form>
                }
              </div>
            </div>
          </section>
        </div>
      </Layout>
    </>
  );
};

export default ChangePassword;