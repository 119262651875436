import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import {
  Loader,
  Layout,
  requestApi,
} from '../components/CommonFunction';
import '../styles/custom.css';

const Contact = () => {
  const [loadContactButton, setLoadContactButton] = useState(false);
  const [successMsg, setSuccessMsg] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = useForm();
  
  const handleContactFormSubmit = async (values) => {
    isValid ? setLoadContactButton(true) : setLoadContactButton(false)

    await requestApi(
      'POST',
      'save-contact',
      {},
      {},
      values
    ).then((result) => {
      setSuccessMsg('Thank you. Your message has been sent.');
      // setTimeout(() => {
      //   setLoadContactButton(false);
      // }, 1500);
      setLoadContactButton(false);
      reset();
      setErrorMsg('');
    }).catch((error) => {
      setErrorMsg(error?.response?.data?.message);
      setLoadContactButton(false);
    });
    //setErrorMsg('Some error is occured.');
  };

  return (
  	<>
      {/*<div className="page-loader"></div>*/}
      
      <Layout>
        <div className="content-wrapper">
          <section className="wrapper bg-gray py-16 mb-12">
            <div className="container text-center">
              <div className="row">
                <div className="col-12 mx-auto">
                  <h1 className="display-3 mb-3">Contact</h1>
                  <p>It almost always begins with a conversation.</p>
                </div>
              </div>
            </div>
          </section>
          <section className="wrapper">
            <div className="container pb-14 pb-md-16">
              <div className="row">
                <div className="col mt-n18 mb-12">
                  <div className="card shadow-lg">
                    <div className="row gx-0">
                      <div className="col-lg-6 image-wrapper bg-image bg-cover rounded-top rounded-lg-start" data-image-src="./assets/img/photos/tm1.jpg"></div>
                      <div className="col-lg-6">
                        <div className="p-10 p-md-11 p-lg-13">
                          <p className="lead fs-lg">Let's make something great together. We would like to hear from you.</p>
                          <p>We're just one click away. If you have questions suggestions or need assistance, feel free to contact us using the form below. </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-xl-10 mx-auto">
                  <div className="row gy-10 gx-lg-8 gx-xl-12">
                    <div className="col-lg-8">
                      {/*<div className="alert alert-success alert-icon alert-dismissible fade show" role="alert">
                        <i className="uil uil-check-circle"></i>Thank you. Your message has been sent. <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                      </div>*/}

                      {
                        successMsg &&
                        <div className="alert alert-success alert-icon alert-dismissible fade show text-start" role="alert">
                          <i className="uil uil-check"></i>{successMsg} <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                        </div>  
                      }
                      {
                        errorMsg &&
                        (typeof errorMsg === "string") &&
                        <div className="alert alert-danger alert-icon alert-dismissible fade show text-start" role="alert">
                          <i className="uil uil-exclamation-triangle"></i>{errorMsg} <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                        </div>  
                      }

                      <form
                        className="text-start mb-3"
                        onSubmit={handleSubmit(handleContactFormSubmit)}
                      >
                        <div className="messages"></div>
                        <div className="row gx-4">
                          <div className="col-md-12">
                            <div className="form-floating mb-4">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Name"
                                id="name"
                                {...register('name', {
                                  required: true
                                })}
                              />
                              <label htmlFor="name">Your Name *</label>
                              {
                                errors.name?.type === "required" && 
                                <div className="formFieldError">
                                  Please enter your name.
                                </div>
                              }
                              {
                                errorMsg &&
                                (typeof errorMsg === "object") &&
                                errorMsg?.name && 
                                <div className="formFieldError">
                                  {errorMsg.name[0]}
                                </div>
                              }
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-floating mb-4">
                              <input
                                type="email"
                                className="form-control"
                                placeholder="Email"
                                id="contactEmail"
                                {...register('email', {
                                  required: true,
                                  pattern: /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/  //eslint-disable-line
                                })}
                              />
                              <label htmlFor="email">Email *</label>
                              {
                                errors.email?.type === "required" && 
                                <div className="formFieldError">
                                  Please enter your email address.
                                </div>
                              }
                              {
                                errors.email?.type === "pattern" && 
                                <div className="formFieldError">
                                  Please enter valid email address.
                                </div>
                              }
                              {
                                errorMsg &&
                                (typeof errorMsg === "object") &&
                                errorMsg?.email && 
                                <div className="formFieldError">
                                  {errorMsg.email[0]}
                                </div>
                              }
                            </div>
                          </div>
                          <div className="col-12">
                            <div className="form-floating mb-4">
                              <textarea
                                id="message"
                                className="form-control textAreaCustomSize"
                                placeholder="Your message"
                                {...register('message', {
                                  required: true                  
                                })}
                              >
                              </textarea>
                              <label htmlFor="message">Message *</label>
                              {
                                errors.message?.type === "required" && 
                                <div className="formFieldError">
                                  Please enter your message.
                                </div>
                              }
                              {
                                errorMsg &&
                                (typeof errorMsg === "object") &&
                                errorMsg?.message && 
                                <div className="formFieldError">
                                  {errorMsg.message[0]}
                                </div>
                              }
                            </div>
                          </div>
                          <div className="col-12">
                            <button
                              disabled={loadContactButton}
                              className="btn btn-primary rounded-pill btn-send mb-3"
                              type="submit"
                            >
                              { loadContactButton ? <Loader text='Send message' /> : 'Send message' }
                            </button>
                            <p className="text-muted">
                              <strong>*</strong> These fields are required.
                            </p>
                          </div>
                        </div>
                      </form>
                    </div>
                    <div className="col-lg-4">
                      <div className="d-flex flex-row">
                        <div>
                          <div className="icon text-primary fs-28 me-4 mt-n1">
                            <i className="uil uil-location-pin-alt"></i>
                          </div>
                        </div>
                        <div>
                          <h5 className="mb-1">Address</h5>
                          <address>Moonshine St. 14/05 Light City, London, United Kingdom</address>
                        </div>
                      </div>
                      <div className="d-flex flex-row">
                        <div>
                          <div className="icon text-primary fs-28 me-4 mt-n1">
                            <i className="uil uil-envelope"></i>
                          </div>
                        </div>
                        <div>
                          <h5 className="mb-1">E-mail</h5>
                          <p className="mb-0">
                            <Link to="/#" className="link-body">
                              <span className="__cf_email__" data-cfemail="6417050a00060b1c240109050d084a070b09">[email&#160;protected]</span>
                            </Link>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="wrapper bg-light">
            <div className="map">
              <iframe
                title="googleMaps"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d25387.23478654725!2d-122.06115399490332!3d37.309248660190086!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x808fb4571bd377ab%3A0x394d3fe1a3e178b4!2sCupertino%2C%20CA%2C%20USA!5e0!3m2!1sen!2str!4v1645437305701!5m2!1sen!2str"
                style={{ width: '100%', height: '500px', border: '0' }}
                allowFullScreen
              >
              </iframe>
            </div>
          </section>
        </div>
      </Layout>
    </>
  );
};

export default Contact;