import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Link, Navigate } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import {
  Loader,
  Layout,
  cleanUpBeforePageLoad,
  loadJSScript,
  PageLoader,
  requestApi,
} from '../CommonFunction';

const PersonalInfo = () => {
  const [loading, setLoading] = useState(true);
  const [userPersonalInfoData, setUserPersonalInfoData] = useState('');
  const [timezoneData, setTimezoneData] = useState('');
  const [personalInfoButton, setPersonalInfoButton] = useState(false);
  const [successMsg, setSuccessMsg] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [userPhoto, setUserPhoto] = useState('');
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isValid },
  } = useForm();

  const handlePersonalInfoFormSubmit = async (values) => {
    isValid ? setPersonalInfoButton(true) : setPersonalInfoButton(false)
    values.profilePhoto = userPhoto;
    await requestApi(
      'POST',
      'update-user-personal-info',
      { 
        'Authorization' : `Bearer ${localStorage.getItem('ya-user-access-token')}`,
        'Content-Type' : 'multipart/form-data'
      },
      {},
      values
    ).then((result) => {
      setSuccessMsg('Your information has been updated.');
      
      // setTimeout(() => {
      //   setPersonalInfoButton(false);
      // }, 1500);
      setPersonalInfoButton(false)
      setErrorMsg('');
      window.location.reload();
    }).catch((error) => {
      setErrorMsg(error?.response?.data?.message);
      setPersonalInfoButton(false);
    });
    //setErrorMsg('Some errors are occured.');
  };

  const loadUserPersonalInfoData = async () => {
    await requestApi(
      'GET',
      'get-user-data',
      { 'Authorization' : `Bearer ${localStorage.getItem('ya-user-access-token')} ` },
    ).then((result) => {
      setUserPersonalInfoData(result.data.response);
    }).catch((error) => {
      setErrorMsg(error?.response?.data?.message);
    });
  };

  const loadTimezoneData = async () => {
    await requestApi(
      'GET',
      'get-timezone-list',
    ).then((result) => {
      setTimezoneData(result.data.response);
    }).catch((error) => {
      setErrorMsg(error?.response?.data?.message);
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    if (
        userPersonalInfoData === '' 
        && timezoneData === ''
        && localStorage.getItem('ya-user-access-token')
      ) {
      loadUserPersonalInfoData();
      loadTimezoneData();
    }

    if (
        userPersonalInfoData !== undefined
        && userPersonalInfoData !== null
        && timezoneData !== undefined
        && timezoneData !== null
    ) {
      setTimeout(() => {
        setLoading(false);
        cleanUpBeforePageLoad();
        loadJSScript("/assets/js/theme.js", 'themeScript');
        window.scrollTo(0, 0);
      }, 1000);
      setValue('fullName', userPersonalInfoData?.fullName);
      setValue('email', userPersonalInfoData?.email);
      setValue('mobileNo', userPersonalInfoData?.mobileNo);
      setValue('timeZone', userPersonalInfoData?.timeZone);
    }
  }, [userPersonalInfoData, timezoneData, setValue]);

  return (
    <>
      {/*<div className="page-loader"></div>*/}

      {
        !localStorage.getItem('ya-user-access-token')
        ?
        <Navigate replace to="/" />
        :
        ''
      }

      <Layout isUserLogin={true} pageLoader={true} >
        <div className="content-wrapper">
          <section className="wrapper">
            <div className="container py-14">
              <div className="col-xl-7 col-lg-9 col-md-12 m-auto">
                <nav className="d-inline-block" aria-label="breadcrumb">
                  <ol className="breadcrumb mb-6">
                    <li className="breadcrumb-item">
                      <Link to="/account">Account</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">Personal information</li>
                  </ol>
                </nav>
                {/*<div className="alert alert-success alert-icon alert-dismissible fade show" role="alert">
                  <i className="uil uil-check-circle"></i>Your information has been updated. <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                </div>*/}

                {
                  successMsg &&
                  <div className="alert alert-success alert-icon alert-dismissible fade show text-start" role="alert">
                    <i className="uil uil-check"></i>{successMsg} <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                  </div>  
                }
                {
                  errorMsg &&
                  (typeof errorMsg === "string") &&
                  <div className="alert alert-danger alert-icon alert-dismissible fade show text-start" role="alert">
                    <i className="uil uil-exclamation-triangle"></i>{errorMsg} <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                  </div>  
                }

                <h2 className="mb-6">Personal information</h2>
                {
                  loading
                  ?
                  <PageLoader />
                  :
                  <form
                    className="text-start mb-3"
                    onSubmit={handleSubmit(handlePersonalInfoFormSubmit)}
                  >
                    <div className="row g-3 mb-12">
                      <div className="col-lg-2 col-md-2 col-sm-2 col-4 m-auto text-center">
                        {/*<img
                          className="avatar w-15 mb-2"
                          src="/assets/img/avatars/te1.jpg"
                          srcSet="/assets/img/avatars/te1@2x.jpg 2x"
                          alt=""
                        />*/}
                        <Avatar
                          src={userPersonalInfoData && userPersonalInfoData.profilePhoto}
                          srcSet={userPersonalInfoData && userPersonalInfoData.profilePhoto}
                          alt=""
                          sx={{ width: 100, height: 100 }}
                        >
                          {userPersonalInfoData && userPersonalInfoData.profilePhoto}
                        </Avatar>
                        <input
                          type="file"
                          accept=".jpg,.jpeg,.png"
                          id="profilePhoto"
                          className="form-control d-none"
                          {...register('profilePhoto', {
                            //required: true,
                            onChange: ({ target }) => {
                              setUserPhoto(target.files[0]);
                            }
                          })}
                        />
                        <label
                          className="personalUserProfilePhotoElementLabel"
                          htmlFor="profilePhoto"
                        >
                          Change
                        </label>
                      </div>
                      {
                        errors.profilePhoto?.type === "required" && 
                        <div className="formFieldError text-center">
                          Please upload profile photo.
                        </div>
                      }
                      {
                        errorMsg &&
                        (typeof errorMsg === "object") &&
                        errorMsg?.profilePhoto && 
                        <div className="formFieldError text-center">
                          {errorMsg.profilePhoto[0]}
                        </div>
                      }
                      <div className="col-12">
                        <div className="form-floating">
                          <input
                            id="name"
                            className="form-control"
                            placeholder="Name"
                            {...register('fullName', {
                              required: true,
                              maxLength: 255,
                              value: userPersonalInfoData && userPersonalInfoData.fullName
                            })}
                          />
                          <label htmlFor="name">Name</label>
                          {
                            errors.fullName?.type === "required" && 
                            <div className="formFieldError">
                              Please enter your name.
                            </div>
                          }
                          {
                            errors.fullName?.type === "maxLength" && 
                            <div className="formFieldError">
                              Name may not be greater than 255 character.
                            </div>
                          }
                          {
                            errorMsg &&
                            (typeof errorMsg === "object") &&
                            errorMsg?.fullName && 
                            <div className="formFieldError">
                              {errorMsg.fullName[0]}
                            </div>
                          }
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="form-floating">
                          <input
                            id="email"
                            className="form-control"
                            placeholder="Email"
                            disabled={true}
                            value={userPersonalInfoData && userPersonalInfoData.email}
                          />
                          <label htmlFor="email">Email</label>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="form-floating">
                          <input
                            id="mobileNo"
                            className="form-control"
                            placeholder="Mobile"
                            {...register('mobileNo', {
                              required: true,
                              minLength: 10,
                              maxLength: 10,
                              pattern: /^[0-9]+$/,
                              value: userPersonalInfoData && userPersonalInfoData.mobileNo
                            })}
                          />
                          <label htmlFor="mobileNo">Mobile</label>
                          {
                            errors.mobileNo?.type === "required" && 
                            <div className="formFieldError">
                              Please enter mobile number.
                            </div>
                          }
                          {
                            errors.mobileNo?.type === "minLength" && 
                            <div className="formFieldError">
                              Mobile number must be minimum 10 digit.
                            </div>
                          }
                          {
                            errors.mobileNo?.type === "maxLength" && 
                            <div className="formFieldError">
                              Mobile number must be maximum 10 digit.
                            </div>
                          }
                          {
                            errors.mobileNo?.type === "pattern" && 
                            <div className="formFieldError">
                              Mobile number must be number.
                            </div>
                          }
                          {
                            errorMsg &&
                            (typeof errorMsg === "object") &&
                            errorMsg?.mobileNo && 
                            <div className="formFieldError">
                              {errorMsg.mobileNo[0]}
                            </div>
                          }
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-select-wrapper">
                          <select
                            id="timeZone"
                            className="form-select"
                             {...register("timeZone", {
                              required: true,
                              value: userPersonalInfoData && userPersonalInfoData.timeZone
                             })}
                          >
                            <option value="">Time Zone</option>
                            
                            {
                              timezoneData &&
                              Object.keys(timezoneData).map((value, index) => {
                                return (
                                  <option
                                    key={`timezone_${index}`}
                                    value={value}
                                  >
                                    {value}
                                  </option>
                                );
                              })
                            }
                          </select>
                          {
                            errors.timeZone?.type === "required" && 
                            <div className="formFieldError">
                              Please select your timezone.
                            </div>
                          }
                          {
                            errorMsg &&
                            (typeof errorMsg === "object") &&
                            errorMsg?.timeZone && 
                            <div className="formFieldError">
                              {errorMsg.timeZone[0]}
                            </div>
                          }
                        </div>
                      </div>
                    </div>
                    <button
                      disabled={personalInfoButton}
                      className="btn btn-primary rounded-pill btn-lg w-100 mb-2"
                      type="submit"
                    >
                      { personalInfoButton ? <Loader text='Update' /> : 'Update' }
                    </button>
                  </form>
                }
              </div>
            </div>
          </section>
        </div>
      </Layout>
    </>
  );
};

export default PersonalInfo;