import React, { useEffect } from 'react';
import axios from 'axios';
import Navbar from './Navbar';
import Footer from './Footer';
import { LoginRegister } from './ModalForms';
import $ from 'jquery';
import CircularProgress from '@mui/material/CircularProgress';
import '../styles/custom.css';

export const loadJSScript = (url, scriptId) => {
  const removeScript = document.getElementById(scriptId);
  if (removeScript != null) {
    removeScript.remove();
  }

  const script = document.createElement('script');
    script.src = url;
    script.id = scriptId;
    script.async = true;
    document.body.appendChild(script);

    /*return () => {
      document.body.removeChild(script);
    }*/
};

export const cleanUpBeforePageLoad = () => {
  /* For close modal if any modal is open */
  $('body').removeClass('modal-open');
  $('body').removeAttr('style');
  $(".modal-backdrop").remove();
  /* For close modal if any modal is open */

  /* To remove previously added sticky header on pageload */
  $('.navbar-clone.fixed').remove();
  /* To remove previously added sticky header on pageload */
};

export const Loader = (props) => {
  return (
    <>
      <span
        className="spinner-border spinner-border-sm mx-1"
        role="status"
        aria-hidden="true"
      ></span>{props.text && props.text}
    </>
  );
};

export const PageLoader = () => {
  return (
    <div className="pageLoaderClass bg-transparent">
      <CircularProgress />
    </div>
  );
};

export const Layout = (props) => {
  useEffect(() => {
    cleanUpBeforePageLoad();

    if (!(props.pageLoader && props.pageLoader === true)) {
      loadJSScript("/assets/js/theme.js", 'themeScript');
      window.scrollTo(0, 0);
    }
  });

  return (
    <>
      <Navbar hideMenu={props.hideMenu} isUserLogin={props.isUserLogin} />
        {props.children}
      <Footer />
      { (props.hideMenu || props.isUserLogin || localStorage.getItem('ya-user-access-token')) ? '' : <LoginRegister /> }
    </>
  );
};

export const requestApi = async (
  method = 'GET',
  url,
  headers,
  params = {},
  data = {}
) => {
  const appendHeaders = {
    ...headers, 'Accept' : 'application/json'
  };

  const apiResult = await axios.request({
    method: method,
    url: url,
    baseURL: process.env.REACT_APP_API_URL,
    headers: appendHeaders,
    params: params,
    data: data
  });

  return apiResult;
};