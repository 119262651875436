import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  Layout,
  cleanUpBeforePageLoad,
  loadJSScript,
  PageLoader
} from '../components/CommonFunction';

const AboutUs = () => {
	const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (loading) {
      setTimeout(() => {
        setLoading(false);
        cleanUpBeforePageLoad();
        loadJSScript("/assets/js/theme.js", 'themeScript');
        window.scrollTo(0, 0);
      }, 1000);
    }
  }, [loading]);

  return (
  	<>
  		{/*<div className="page-loader"></div>*/}
  		
  		<Layout pageLoader={true} >
  			{
          loading
          ?
          <PageLoader />
          :
			  	<div className="content-wrapper">	
					  <section className="wrapper bg-gray">
					    <div className="container py-16 mb-lg-8">
					      <div className="col-lg-9 m-auto mb-12 text-center">
					        <h1 className="display-1 lh-xxl">We exist to enable student athletes to reach their potentials in sport and life.</h1>
					      </div>
					      <div className="position-relative">
					        <div
					        	className="shape bg-dot primary rellax w-17 h-21"
					        	data-rellax-speed="1"
					        	style={{ top: '-2.5rem', right: '-2.7rem', }}
					        >
					        </div>
					        <figure className="rounded">
					          <img src="./assets/img/photos/about18.jpg" srcSet="./assets/img/photos/about18@2x.jpg 2x" alt="" />
					        </figure>
					      </div>
					    </div>
					  </section>
					  <section className="wrapper">
					    <div className="container py-6">
					      <div className="col-lg-8 m-auto">
					        <h1 className="display-6 mb-10">Youngathlete is a 501c non-profit organization dedicated to fostering a culture of sportsmanship, nurturing talent, and building meaningful connections among its members.</h1>
					        <p className="mb-4">Our mission is to create a supportive and inclusive environment where student athletes can thrive both academically and athletically.</p>
					        <p className="mb-4">We are committed to promoting sportsmanship as a core value, encouraging fair play, respect, and integrity on and off the field. By upholding these principles, we aim to develop well-rounded individuals who not only excel in their chosen sports but also embody the values of teamwork, perseverance, and ethical conduct.</p>
					        <p className="mb-4">As advocates for talent sponsorship, we strive to identify and support emerging athletes, providing them with resources, mentorship, and opportunities to maximize their potential. Through targeted initiatives, we aim to bridge the gap between talent and recognition, ensuring that deserving student athletes receive the acknowledgment and support they need to reach new heights in their athletic endeavors.</p>
					        <p className="mb-4">In our pursuit of connection, we seek to build a tight-knit community that transcends boundaries. By facilitating networking events, collaborative projects, and mentorship programs, we aim to forge lasting relationships among our members. We believe that a strong sense of community enhances the overall student athlete experience, creating a network of support that extends beyond the playing field.</p>
					        <p className="mb-4">In essence, Youngathlete is more than an organization—it is a platform for personal growth, sportsmanship, talent development, and community building. Together, we strive to create an environment where every student athlete can flourish, realizing their potential and leaving a lasting impact on the world of sports and beyond.</p>
					      </div>
					    </div>
					  </section>
					  <section className="wrapper bg-gray">
					    <div className="container py-12">
					      <div className="row gy-10 align-items-center">
					        <div className="text-center">
					          <h1 className="display-4">2024 Executive Team</h1>
					          <p className="m-0">Our ambassadors works with coach to help our junior athletes.</p>
					        </div>
					        <div className="col-lg-12">
					          <div className="swiper-container text-center mb-6" data-margin="30" data-dots="true" data-items-xl="4" data-items-md="3" data-items-xs="1">
					            <div className="swiper">
					              <div className="swiper-wrapper">
					                <div className="swiper-slide">
					                  <img className="rounded-circle w-20 mx-auto mb-4" src="./assets/img/avatars/t1.jpg" srcSet="./assets/img/avatars/t1@2x.jpg 2x" alt="" />
					                  <h4 className="mb-0">Cory Zamora</h4>
					                  <div className="mb-2">President</div>
					                </div>
					                <div className="swiper-slide">
					                  <img className="rounded-circle w-20 mx-auto mb-4" src="./assets/img/avatars/t2.jpg" srcSet="./assets/img/avatars/t2@2x.jpg 2x" alt="" />
					                  <h4 className="mb-1">Coriss Ambady</h4>
					                  <div className="mb-2">VP Marketing</div>
					                </div>
					                <div className="swiper-slide">
					                  <img className="rounded-circle w-20 mx-auto mb-4" src="./assets/img/avatars/t3.jpg" srcSet="./assets/img/avatars/t3@2x.jpg 2x" alt="" />
					                  <h4 className="mb-1">Nikolas Brooten</h4>
					                  <div className="mb-2">VP Operation</div>
					                </div>
					                <div className="swiper-slide">
					                  <img className="rounded-circle w-20 mx-auto mb-4" src="./assets/img/avatars/t4.jpg" srcSet="./assets/img/avatars/t4@2x.jpg 2x" alt="" />
					                  <h4 className="mb-1">Jackie Sanders</h4>
					                  <div className="mb-2">VP Program</div>
					                </div>
					                <div className="swiper-slide">
					                  <img className="rounded-circle w-20 mx-auto mb-4" src="./assets/img/avatars/t5.jpg" srcSet="./assets/img/avatars/t5@2x.jpg 2x" alt="" />
					                  <h4 className="mb-1">Tina Geller</h4>
					                  <div className="mb-2">VP Sponsorship</div>
					                </div>
					              </div>
					            </div>
					          </div>
					        </div>
					      </div>
					    </div>
					  </section>
            <section className="wrapper">
              <div className="container-card mt-12">
                <div className="card image-wrapper bg-full bg-image bg-overlay bg-overlay-light-500 mb-14" data-image-src="./assets/img/photos/bg22.png" /*style={{ background-image: url('./assets/img/photos/bg22.png') }}*/ >
                  <div className="card-body py-14 px-0">
                    <div className="container">
                      <div className="row text-center">
                        <div className="col-xl-11 col-xxl-10 mx-auto">
                          <h3 className="display-4 mb-6 px-lg-5">We believe in action, not just words. Through our Kickstart program, we help juniors to learn sports, discover their protentials and support them to thrive in sports and life!</h3>
                        </div>
                      </div>
                      <div className="d-flex justify-content-center">
                        <Link to="/#" className="btn btn-primary btn-lg rounded-pill mx-1">Join us today</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>      
					</div>
				}
	  	</Layout>
		</>
  );
};

export default AboutUs;