import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import {
  useNavigate,
  Link,
  Navigate,
} from 'react-router-dom';
import {
  Loader,
  Layout,
  cleanUpBeforePageLoad,
  loadJSScript,
  PageLoader,
  requestApi,
} from './CommonFunction';

export const OnBoardStepOne = ({
  nextStep,
  handleOnBoardFormData,
  onBoardFormData
}) => {
  const [loading, setLoading] = useState(true);
  const [sportsName, setSportsName] = useState('');
  //const selectedSportColor = '#a07cc5';
  const [selectedSportName, setSelectedSportName] = useState(onBoardFormData?.personalInformation?.sportName);
  const [selectSportButton, setSelectSportButton] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm();

  const handleSelectSportFormSubmit = async (values) => {
    handleOnBoardFormData(values);
    isValid ? setSelectSportButton(true) : setSelectSportButton(false)
    await setTimeout(() => {
      setSelectSportButton(false);
    }, 1500);

    setTimeout(() => {
      nextStep();
    }, 2000);
  };

  const loadSportsName = async () => {
    await requestApi(
      'GET',
      'get-sports-name-list',
    ).then((result) => {
      setSportsName(result.data.response);
    }).catch((error) => {
      setErrorMsg(error?.response?.data?.message);
    });
  };

  useEffect(() => {
    if (sportsName === '' && localStorage.getItem('ya-user-access-token')) {
      loadSportsName();
    }

    if (sportsName !== undefined && sportsName !== null) {
      setTimeout(() => {
        setLoading(false);
        cleanUpBeforePageLoad();
        loadJSScript("/assets/js/theme.js", 'themeScript');
        window.scrollTo(0, 0);
      }, 1000);
    }
  }, [sportsName]);

	return (
		<>
      {/*<div className="page-loader"></div>*/}

      {
        !localStorage.getItem('ya-user-access-token')
        ?
        <Navigate replace to="/" />
        :
        ''
      }
      
      <Layout hideMenu={true} pageLoader={true} >
        {
          loading
          ?
          <PageLoader />
          :
          <div className="content-wrapper">
            <section className="wrapper">
              <div className="container py-16">
                <form
                  className="text-start mb-3"
                  onSubmit={handleSubmit(handleSelectSportFormSubmit)}
                >
                  <div className="col-xl-7 col-lg-9 col-md-12 m-auto text-center">
                    <h1 className="display-3 mb-6">Ok, let's get started.</h1>
                    <p className="mb-10">What sport do you play, choose one.</p>

                    {
                      errorMsg &&
                      (typeof errorMsg === "string") &&
                      <div className="alert alert-danger alert-icon alert-dismissible fade show text-start" role="alert">
                        <i className="uil uil-exclamation-triangle"></i>{errorMsg} <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                      </div>  
                    }
                    
                    <ul className="list-inline mb-8 text-center">
                      {
                        sportsName && sportsName.map((value, index) => {
                          return (
                            <li
                              key={`sportsName_${index}`}
                              className="list-inline-item me-2 mb-2"
                            >
                              <input
                                id={`sportsName_${value}`}
                                type="radio"
                                className="btn-input"
                                {...register('personalInformation.sportName', {
                                  required: true,
                                  //onChange: handleOnBoardFormData,
                                  onChange: (e) => setSelectedSportName(e.target.value)
                                })}
                                style={{
                                  //background: (onBoardFormData?.personalInformation?.sportName === value ? selectedSportColor : '')
                                }}
                                value={value}
                                defaultChecked={onBoardFormData?.personalInformation?.sportName === value ? true : false}
                              />
                              <label
                                className="btn btn-primary btn-sm rounded"
                                style={{
                                  //background: (onBoardFormData?.personalInformation?.sportName === value ? selectedSportColor : '')
                                  //outline: (onBoardFormData?.personalInformation?.sportName === value ? '3px solid #262b32' : '')
                                  outline: (selectedSportName === value ? '3px solid #262b32' : '')
                                }}
                                htmlFor={`sportsName_${value}`}
                              >
                                {value}
                              </label>
                            </li>
                          );
                        })
                      }
                      {
                        errors.personalInformation?.sportName?.type === "required" && 
                        <div className="formFieldError">
                          Please select one sport.
                        </div>
                      }
                    </ul>
                    <div className="text-center">
                      <button
                        disabled={selectSportButton}
                        className="btn btn-primary rounded-pill px-12"
                      >
                        { selectSportButton ? <Loader text='Next' /> : 'Next' }
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </section>
          </div>
        }
      </Layout>
		</>
	);
};

export const OnBoardStepTwo = ({
  prevStep,
  nextStep,
  handleOnBoardFormData,
  onBoardFormData
}) => {
  const [loading, setLoading] = useState(true);
  const [athleticInfoButton, setAthleticInfoButton] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm();

  const handleAthleticInfoFormSubmit = async (values) => {
    handleOnBoardFormData(values);
    isValid ? setAthleticInfoButton(true) : setAthleticInfoButton(false)
    await setTimeout(() => {
      setAthleticInfoButton(false);
    }, 1500);

    setTimeout(() => {
      nextStep();
    }, 2000);
  };

  useEffect(() => {
    if (loading) {
      setTimeout(() => {
        setLoading(false);
        cleanUpBeforePageLoad();
        loadJSScript("/assets/js/theme.js", 'themeScript');
        window.scrollTo(0, 0);
      }, 1000);
    }
  }, [loading]);

  return (
    <>
      {/*<div className="page-loader"></div>*/}

      {
        !localStorage.getItem('ya-user-access-token')
        ?
        <Navigate replace to="/" />
        :
        ''
      }
      
      <Layout hideMenu={true} pageLoader={true} >
        <div className="content-wrapper">
          <section className="wrapper">
            <div className="container py-16">
              <div className="col-xl-7 col-lg-9 col-md-12 m-auto text-center">
                <h1 className="display-3 mb-6 text-center">Athletic information</h1>
                {
                  loading
                  ?
                  <PageLoader />
                  :
                  <>
                    <p className="mb-10">Enter your basic athletic information.</p>
                    <form
                      className="text-start mb-3"
                      onSubmit={handleSubmit(handleAthleticInfoFormSubmit)}
                    >
                      <div className="row g-3 mb-12">
                        <div className="col-md-6">
                          <h4 className="text-start">Height</h4>
                          <div className="row g-3">
                            <div className="col-6">
                              <div className="form-floating">
                                <input
                                  id="heightInFeat"
                                  className="form-control"
                                  placeholder="Ft *"
                                  {...register('athleticInformation.heightInFeat', {
                                    required: true,
                                    min: 3,
                                    max: 7,
                                    pattern: /^\d+(\.\d+)?$/,
                                    value: onBoardFormData?.athleticInformation?.heightInFeat,
                                    //onChange: handleOnBoardFormData,
                                  })}
                                />
                                <label htmlFor="heightInFeat">Ft *</label>
                                {
                                  errors.athleticInformation?.heightInFeat?.type === "required" && 
                                  <div className="formFieldError">
                                    Please enter your height in feat.
                                  </div>
                                }
                                {
                                  errors.athleticInformation?.heightInFeat?.type === "min" && 
                                  <div className="formFieldError">
                                    Height in feat must be minimum 3.
                                  </div>
                                }
                                {
                                  errors.athleticInformation?.heightInFeat?.type === "max" && 
                                  <div className="formFieldError">
                                    Height in feat must be maximum 7.
                                  </div>
                                }
                                {
                                  errors.athleticInformation?.heightInFeat?.type === "pattern" && 
                                  <div className="formFieldError">
                                    Height in feat accept only number with decimal points.
                                  </div>
                                }
                              </div>
                            </div>
                            <div className="col-6">
                              <div className="form-floating">
                                <input
                                  id="heightInInch"
                                  className="form-control"
                                  placeholder="In *"
                                  {...register('athleticInformation.heightInInch', {
                                    required: true,
                                    min: 0,
                                    max: 11,
                                    pattern: /^\d+(\.\d+)?$/,
                                    value: onBoardFormData?.athleticInformation?.heightInInch,
                                    //onChange: handleOnBoardFormData,
                                  })}
                                />
                                <label htmlFor="heightInInch">In *</label>
                                {
                                  errors.athleticInformation?.heightInInch?.type === "required" && 
                                  <div className="formFieldError">
                                    Please enter your height in inch.
                                  </div>
                                }
                                {
                                  errors.athleticInformation?.heightInInch?.type === "min" && 
                                  <div className="formFieldError">
                                    Height in inch must be minimum 0.
                                  </div>
                                }
                                {
                                  errors.athleticInformation?.heightInInch?.type === "max" && 
                                  <div className="formFieldError">
                                    Height in inch must be maximum 11.
                                  </div>
                                }
                                {
                                  errors.athleticInformation?.heightInInch?.type === "pattern" && 
                                  <div className="formFieldError">
                                    Height in inch accept only number with decimal points.
                                  </div>
                                }
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <h4 className="text-start">Weight</h4>
                          <div className="form-floating">
                            <input
                              id="weight"
                              className="form-control"
                              placeholder="Lb *"
                              {...register('athleticInformation.weight', {
                                required: true,
                                min: 0,
                                max: 500,
                                pattern: /^\d+(\.\d+)?$/,
                                value: onBoardFormData?.athleticInformation?.weight,
                                //onChange: handleOnBoardFormData,
                              })}
                            />
                            <label htmlFor="weight">Lb *</label>
                            {
                              errors.athleticInformation?.weight?.type === "required" && 
                              <div className="formFieldError">
                                Please enter your weight.
                              </div>
                            }
                            {
                              errors.athleticInformation?.weight?.type === "min" && 
                              <div className="formFieldError">
                                Weight must be minimum 0.
                              </div>
                            }
                            {
                              errors.athleticInformation?.weight?.type === "max" && 
                              <div className="formFieldError">
                                Weight must be maximum 500.
                              </div>
                            }
                            {
                              errors.athleticInformation?.weight?.type === "pattern" && 
                              <div className="formFieldError">
                                Weight accept only number with decimal points.
                              </div>
                            }
                          </div>
                        </div>
                        <div className="col-md-6">
                          <h4 className="text-start">Position</h4>
                          <div className="form-floating">
                            <input
                              id="position"
                              className="form-control"
                              placeholder="Position"
                              {...register('athleticInformation.position', {
                                required: true,
                                maxLength: 255,
                                value: onBoardFormData?.athleticInformation?.position,
                                //onChange: handleOnBoardFormData
                              })}
                            />
                            <label htmlFor="position">Position</label>
                            {
                              errors.athleticInformation?.position?.type === "required" && 
                              <div className="formFieldError">
                                Please enter your position.
                              </div>
                            }
                            {
                              errors.athleticInformation?.position?.type === "maxLength" && 
                              <div className="formFieldError">
                                Position may not be greater than 255 character.
                              </div>
                            }
                          </div>
                        </div>
                        <div className="col-md-6">
                          <h4 className="text-start">Dominant hand</h4>
                          <div className="form-select-wrapper">
                            <select
                              className="form-select"
                              aria-label="Dominant_hand"
                              label="dominantHand"
                              {...register('athleticInformation.dominantHand', {
                                required: true,
                                value: onBoardFormData?.athleticInformation?.dominantHand,
                                //onChange: handleOnBoardFormData,
                              })}
                            >
                              <option value="left">Left</option>
                              <option value="right">Right</option>
                            </select>
                            {
                              errors.athleticInformation?.dominantHand?.type === "required" && 
                              <div className="formFieldError">
                                Please select your dominant hand.
                              </div>
                            }
                          </div>
                        </div>
                        <div className="text-center">
                          <Link to="#/" onClick={prevStep} className="m-3">Back</Link>
                          <button
                            disabled={athleticInfoButton}
                            className="btn btn-primary rounded-pill px-12"
                            type="submit"
                          >
                            { athleticInfoButton ? <Loader text='Next' /> : 'Next' }
                          </button>
                        </div>
                      </div>
                    </form>
                  </>
                }
              </div>
            </div>
          </section>
        </div>
      </Layout>
    </>
  );
};

export const OnBoardStepThree = ({
  prevStep,
  handleOnBoardFormData,
  onBoardFormData,
  handleAthletProfileFormSubmit
}) => {
  const [loading, setLoading] = useState(true);
  const [athleticPersonalInfoButton, setAthleticPersonalInfoButton] = useState(false);
  const [countryData, setCountryData] = useState('');
  const [stateData, setStateData] = useState('');
  const [successMsg, setSuccessMsg] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm();
  const navigate = useNavigate();

  const handleAthleticPersonalInfoFormSubmit = async (values) => {
    handleOnBoardFormData(values);
    isValid ? setAthleticPersonalInfoButton(true) : setAthleticPersonalInfoButton(false)
    
    Object.entries(onBoardFormData).map((val, index) => {
      if (val[0] in values) {
        values[val[0]] = {
          ...onBoardFormData[val[0]], ...values[val[0]]
        }
      }
      return values;
    });

    const newData = {
      ...onBoardFormData, ...values
    };

    await handleAthletProfileFormSubmit(newData)
      .then((result) => {
        setAthleticPersonalInfoButton(false);
        // setTimeout(() => {
        //   setAthleticPersonalInfoButton(false);
        // }, 1500);
        window.scrollTo(0, 0);
        setSuccessMsg('Your details updated successfully.');
        setErrorMsg('');

        setTimeout(() => {
          navigate('/player/' + result.data.response.publicProfileName);
        }, 100);
      }).catch((error) => {
        setSuccessMsg('');
        setErrorMsg(error?.response?.data?.message);
        setAthleticPersonalInfoButton(false);
      });
  };

  const loadCountryData = async () => {
    setErrorMsg('');
    await requestApi(
      'GET',
      'get-country-list',
    ).then((result) => {
      setCountryData(result.data.response);
    }).catch((error) => {
      setErrorMsg(error?.response?.data?.message);
    });
  };

  const loadCountryStateData = async (value) => {
    setErrorMsg('');
    setStateData('');
    if (value && value !== '') {
      await requestApi(
        'GET',
        'get-country-state-list',
        {},
        { 'country': value },
      ).then((result) => {
        setStateData(result.data.response);
      }).catch((error) => {
        setErrorMsg(error?.response?.data?.message);
      });
    }
  };

  useEffect(() => {
    if (countryData === '') {
      loadCountryData();
    }

    if (countryData !== undefined
        && countryData !== null
        && onBoardFormData !== undefined
        && onBoardFormData !== null
      ) {
      loadCountryStateData(onBoardFormData?.homeAddress?.country);

      setTimeout(() => {
        setLoading(false);
        cleanUpBeforePageLoad();
        loadJSScript("/assets/js/theme.js", 'themeScript');
        window.scrollTo(0, 0);
      }, 1000);
    }
  }, [countryData, onBoardFormData]);

  return (
    <>
      {/*<div className="page-loader"></div>*/}

      {
        !localStorage.getItem('ya-user-access-token')
        ?
        <Navigate replace to="/" />
        :
        ''
      }
      
      <Layout hideMenu={true} pageLoader={true} >
        <div className="content-wrapper">
          <section className="wrapper">
            <div className="container py-16">
              <div className="col-xl-7 col-lg-9 col-md-12 m-auto">
                <h1 className="display-3 mb-10 text-center">About the athlete</h1>

                {
                  successMsg &&
                  <div className="alert alert-success alert-icon alert-dismissible fade show text-start" role="alert">
                    <i className="uil uil-check"></i>{successMsg} <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                  </div>  
                }
                {
                  errorMsg &&
                  (typeof errorMsg === "string") &&
                  <div className="alert alert-danger alert-icon alert-dismissible fade show text-start" role="alert">
                    <i className="uil uil-exclamation-triangle"></i>{errorMsg} <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                  </div>  
                }
                {
                  errorMsg &&
                  (typeof errorMsg === "object") &&
                  Object.entries(errorMsg).map((value, index) => {
                    return (
                      <div key={`onBoardError${index}`} className="formFieldError">
                        {value[1][0]}
                      </div>
                    );
                  })
                }

                {
                  loading
                  ?
                  <PageLoader />
                  :
                  <form
                    className="text-start mb-3"
                    onSubmit={handleSubmit(handleAthleticPersonalInfoFormSubmit)}
                  >
                    <div className="row g-3 mb-8">
                      <h4 className="mb-0">Personal Information</h4>
                      <div className="col-md-6">
                        <div className="form-floating">
                          <input
                            id="name"
                            className="form-control"
                            placeholder="Name"
                            {...register('personalInformation.name', {
                              required: true,
                              maxLength: 255,
                              //onChange: handleOnBoardFormData,
                              value: onBoardFormData?.personalInformation?.name,
                            })}
                          />
                          <label htmlFor="name">Name</label>
                          {
                            errors.personalInformation?.name?.type === "required" && 
                            <div className="formFieldError">
                              Please enter your name.
                            </div>
                          }
                          {
                            errors.personalInformation?.name?.type === "maxLength" && 
                            <div className="formFieldError">
                              Name may not be greater than 255 character.
                            </div>
                          }
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-floating">
                          <input
                            id="email"
                            type="email"
                            className="form-control"
                            placeholder="Email"
                            {...register('personalInformation.email', {
                              required: true,
                              pattern: /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/, //eslint-disable-line
                              maxLength: 255,
                              //onChange: handleOnBoardFormData,
                              value: onBoardFormData?.personalInformation?.email,
                            })}
                          />
                          <label htmlFor="email">Email</label>
                          {
                            errors.personalInformation?.email?.type === "required" && 
                            <div className="formFieldError">
                              Please enter your email address.
                            </div>
                          }
                          {
                            errors.personalInformation?.email?.type === "pattern" && 
                            <div className="formFieldError">
                              Please enter valid email address.
                            </div>
                          }
                          {
                            errors.personalInformation?.email?.type === "maxLength" && 
                            <div className="formFieldError">
                              Email may not be greater than 255 character.
                            </div>
                          }
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-select-wrapper">
                          <select
                            className="form-select"
                            aria-label="select_gender"
                            {...register('personalInformation.gender', {
                              required: true,
                              //onChange: handleOnBoardFormData,
                              value: onBoardFormData?.personalInformation?.gender,
                            })}
                          >
                            <option value="">Gender</option>
                            <option value="M">Male</option>
                            <option value="F">Female</option>
                          </select>
                          {
                            errors.personalInformation?.gender?.type === "required" && 
                            <div className="formFieldError">
                              Please select your gender.
                            </div>
                          }
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-floating">
                          <input
                            id="mobileNo"
                            className="form-control"
                            placeholder="Mobile"
                            {...register('personalInformation.mobileNo', {
                              required: true,
                              minLength: 10,
                              maxLength: 10,
                              pattern: /^[0-9]+$/,
                              //onChange: handleOnBoardFormData,
                              value: onBoardFormData?.personalInformation?.mobileNo,
                            })}
                          />
                          <label htmlFor="mobileNo">Mobile</label>
                          {
                            errors.personalInformation?.mobileNo?.type === "required" && 
                            <div className="formFieldError">
                              Please enter mobile number.
                            </div>
                          }
                          {
                            errors.personalInformation?.mobileNo?.type === "minLength" && 
                            <div className="formFieldError">
                              Mobile number must be minimum 10 digit.
                            </div>
                          }
                          {
                            errors.personalInformation?.mobileNo?.type === "maxLength" && 
                            <div className="formFieldError">
                              Mobile number must be maximum 10 digit.
                            </div>
                          }
                          {
                            errors.personalInformation?.mobileNo?.type === "pattern" && 
                            <div className="formFieldError">
                              Mobile number must be number.
                            </div>
                          }
                        </div>
                      </div>
                    </div>
                    <div className="row g-3 mb-8">
                      <h4 className="mb-0">Hometown</h4>
                      <div className="col-md-4">
                        <div className="form-select-wrapper">
                          <select
                            className="form-select"
                            aria-label="select_country"
                            {...register('homeAddress.country', {
                              required: true,
                              onChange: ({ target }) => loadCountryStateData(target.value),
                              value: onBoardFormData?.homeAddress?.country,
                            })}
                          >
                            <option value="">Country</option>
                            {
                              countryData &&
                              Object.entries(countryData).map((value, index) => {
                                return (
                                  <option
                                    key={`country_${index}`}
                                    value={value[0]}
                                  >
                                  {value[1]}
                                  </option>
                                );
                              })
                            }
                          </select>
                          {
                            errors.homeAddress?.country?.type === "required" && 
                            <div className="formFieldError">
                              Please select your country.
                            </div>
                          }
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-select-wrapper">
                          <select
                            className="form-select"
                            aria-label="select_state"
                            {...register('homeAddress.state', {
                              required: true,
                              //onChange: handleOnBoardFormData,
                              value: onBoardFormData?.homeAddress?.state,
                            })}
                          >
                            <option value="">State</option>
                            {
                              stateData &&
                              Object.entries(stateData).map((value, index) => {
                                return (
                                  <option
                                    key={`state_${index}`}
                                    value={value[0]}
                                  >
                                  {value[1]}
                                  </option>
                                );
                              })
                            }
                          </select>
                          {
                            errors.homeAddress?.state?.type === "required" && 
                            <div className="formFieldError">
                              Please select your state.
                            </div>
                          }
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-floating">
                          <input
                            id="city"
                            className="form-control"
                            placeholder="City"
                            {...register('homeAddress.city', {
                              required: true,
                              //onChange: handleOnBoardFormData,
                              value: onBoardFormData?.homeAddress?.city,
                            })}
                          />
                          <label htmlFor="city">City</label>
                          {
                            errors.homeAddress?.city?.type === "required" && 
                            <div className="formFieldError">
                              Please enter city.
                            </div>
                          }
                        </div>
                      </div>
                    </div>
                    <div className="row g-3 mb-8">
                      <h4 className="mb-0">School</h4>
                      <div className="col-md-8">
                        <div className="form-floating">
                          <input
                            className="form-control"
                            placeholder="High School"
                            id="highSchool"
                            {...register('schoolInformation.highSchool.name', {
                              required: true,
                              maxLength: 255,
                              //onChange: handleOnBoardFormData,
                              value: onBoardFormData?.schoolInformation?.highSchool?.name,
                            })}
                          />
                          <label htmlFor="highSchool">High School</label>
                          {
                            errors.schoolInformation?.highSchool?.name?.type === "required" && 
                            <div className="formFieldError">
                              Please enter high school name.
                            </div>
                          }
                          {
                            errors.schoolInformation?.highSchool?.name?.type === "maxLength" && 
                            <div className="formFieldError">
                              High school name may not be greater than 255 character.
                            </div>
                          }
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-select-wrapper">
                          <select
                            className="form-select"
                            aria-label="select_hightschool_graduation_year"
                            {...register('schoolInformation.highSchool.graduationYear', {
                              required: true,
                              //onChange: handleOnBoardFormData,
                              value: onBoardFormData?.schoolInformation?.highSchool?.graduationYear,
                            })}
                          >
                            <option value="">Graduation Year</option>
                            <option value="2024">2024</option>
                            <option value="2025">2025</option>
                          </select>
                          {
                            errors.schoolInformation?.highSchool?.graduationYear?.type === "required" && 
                            <div className="formFieldError">
                              Please select your high school graduation year.
                            </div>
                          }
                        </div>
                      </div>
                      <div className="col-md-8">
                        <div className="form-floating">
                          <input
                            id="middleSchool"
                            className="form-control"
                            placeholder="Middle School"
                            {...register('schoolInformation.middleSchool.name', {
                              required: true,
                              maxLength: 255,
                              //onChange: handleOnBoardFormData,
                              value: onBoardFormData?.schoolInformation?.middleSchool.name,
                            })}
                          />
                          <label htmlFor="middleSchool">Middle School</label>
                          {
                            errors.schoolInformation?.middleSchool?.name?.type === "required" && 
                            <div className="formFieldError">
                              Please enter middle school name.
                            </div>
                          }
                          {
                            errors.schoolInformation?.middleSchool?.name?.type === "maxLength" && 
                            <div className="formFieldError">
                              Middle school name may not be greater than 255 character.
                            </div>
                          }
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-select-wrapper">
                          <select
                            className="form-select"
                            aria-label="select_middleschool_graduation_year"
                            {...register('schoolInformation.middleSchool.graduationYear', {
                              required: true,
                              //onChange: handleOnBoardFormData,
                              value: onBoardFormData?.schoolInformation?.middleSchool?.graduationYear,
                            })}
                          >
                            <option value="">Graduation Year</option>
                            <option value="2024">2024</option>
                            <option value="2025">2025</option>
                          </select>
                          {
                            errors.schoolInformation?.middleSchool?.graduationYear?.type === "required" && 
                            <div className="formFieldError">
                              Please select your middle school graduation year.
                            </div>
                          }
                        </div>
                      </div>
                    </div>
                    <div className="row g-3 mb-8">
                      <h4 className="mb-0">Social media</h4>
                      <div className="col-md-12">
                        <div className="form-floating">
                          <input
                            id="instagramURL"
                            type="url"
                            className="form-control"
                            placeholder="Instagram URL"
                            {...register('socialMediaInfo.instagramURL', {
                              required: true,
                              //onChange: handleOnBoardFormData,
                              value: onBoardFormData?.socialMediaInfo?.instagramURL,
                            })}
                          />
                          <label htmlFor="instagramURL">Instagram URL</label>
                          {
                            errors.socialMediaInfo?.instagramURL?.type === "required" && 
                            <div className="formFieldError">
                              Please enter instagram url.
                            </div>
                          }
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-floating">
                          <input
                            id="twitterURL"
                            type="url"
                            className="form-control"
                            placeholder="Twitter URL"
                            {...register('socialMediaInfo.twitterURL', {
                              required: true,
                              //onChange: handleOnBoardFormData,
                              value: onBoardFormData?.socialMediaInfo?.twitterURL,
                            })}
                          />
                          <label htmlFor="twitterURL">Twitter URL</label>
                          {
                            errors.socialMediaInfo?.twitterURL?.type === "required" && 
                            <div className="formFieldError">
                              Please enter twitter url.
                            </div>
                          }
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-floating">
                          <input
                            id="facebookURL"
                            type="url"
                            className="form-control"
                            placeholder="Facebook URL"
                            {...register('socialMediaInfo.facebookURL', {
                              required: true,
                              //onChange: handleOnBoardFormData,
                              value: onBoardFormData?.socialMediaInfo?.facebookURL,
                            })}
                          />
                          <label htmlFor="facebookURL">Facebook URL</label>
                          {
                            errors.socialMediaInfo?.facebookURL?.type === "required" && 
                            <div className="formFieldError">
                              Please enter facebook url.
                            </div>
                          }
                        </div>
                      </div>
                    </div>
                    <div className="text-center">
                      <Link to="#/" onClick={prevStep} className="m-3">Back</Link>
                      <button
                        disabled={athleticPersonalInfoButton}
                        className="btn btn-primary rounded-pill px-12"
                        type="submit"
                      >
                        { athleticPersonalInfoButton ? <Loader text='Create' /> : 'Create' }
                      </button>
                    </div>
                  </form>
                }
              </div>
            </div>
          </section>
        </div>
      </Layout>
    </>
  );
};