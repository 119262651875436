import React, { useState, useEffect } from 'react';
import {
  Elements,
  useStripe,
  useElements,
  PaymentElement,
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useForm } from 'react-hook-form';
import {
  Loader,
  Layout,
  cleanUpBeforePageLoad,
  loadJSScript,
  PageLoader,
  requestApi,
} from '../CommonFunction';
import {
  useParams,
  Link,
  Navigate,
  useNavigate,
} from 'react-router-dom';
import '../../styles/custom.css';
import moment from 'moment';
import 'moment-timezone';
moment.locale('en-US');

const EventCheckoutWaiver = ({
  nextStep,
  eventDetails,
  handleEventCheckoutFormData,
  eventCheckoutFormData,
}) => {
  const [loading, setLoading] = useState(true);
  const [loadEventCheckoutWaiverButton, setLoadEventCheckoutWaiverButton] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm();

  const handleEventCheckoutWaiverFormSubmit = async (values) => {
    isValid ? setLoadEventCheckoutWaiverButton(true) : setLoadEventCheckoutWaiverButton(false)
    await setTimeout(() => {
      setLoadEventCheckoutWaiverButton(false);
    }, 1500);

    setTimeout(() => {
      nextStep();
    }, 2000);
  };

  useEffect(() => {
    if (loading) {
      setTimeout(() => {
        setLoading(false);
        cleanUpBeforePageLoad();
        loadJSScript("/assets/js/theme.js", 'themeScript');
        window.scrollTo(0, 0);
      }, 1000);
    }
  }, [loading]);

  return (
  	<>
      {/*<div className="page-loader"></div>*/}

      {
        !localStorage.getItem('ya-user-access-token')
        ?
        <Navigate replace to="/" />
        :
        ''
      }

      <Layout hideMenu={true} pageLoader={true} >
        {
          loading
          ?
          <PageLoader />
          :
          <div className="content-wrapper">
            <section className="wrapper">
              <div className="container py-16">
                <div className="row">
                  <form
                    className="text-start mb-3"
                    onSubmit={handleSubmit(handleEventCheckoutWaiverFormSubmit)}
                  >
                    <div className="col-xl-7 col-lg-9 col-md-12 m-auto">
                      {/*<div className="alert alert-danger alert-icon alert-dismissible fade show" role="alert">
                        <i className="uil uil-exclamation-triangle"></i>You must agree to the waiver or you cannot participate. <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                      </div>*/}
                      <h1 className="display-3 mb-6 text-center">Review and Accept Waiver</h1>
                      <div className="form-floating mb-4">
                        <div className="card shadow-none bg-soft-ash eventCheckoutWaiverClass">
                          <div className="card-body">
                            <p>
                              By indicating your acceptance, you understand, agree, warrant and covenant as follows:
                            </p>
                            <p>
                              I understand that participating in this event is potentially hazardous, and that I should not enter and participate unless I am medically able and properly trained. In consideration of the acceptance of this entry, I assume full and complete responsibility for any injury or accident which may occur while I am traveling to or from the event, during the event, or while I am on the premises of the event. I also am aware of and assume all risks associated with participating in this event, including but not limited to being struck by a golf ball or club; being struck by lightning; effects of weather; slipping on wet surfaces; slip and falls associated with the choice of spikes; accidents with golf cars; inconsiderate play; horseplay; defects in course design; and premises defects and hazards.  I, for myself and my heirs and executors, hereby waive, release and forever discharge Youngathlete LLC (“Youngathlete”), Youngathlete-affiliated event organizers, sponsors, promoters, and each of their agents, representatives, successors and assigns, and all other persons associated with Youngathlete-affiliated events (hereafter collectively referred to as “Released Parties”), for my all liabilities, claims, actions, or damages that I may have against them arising out of or in any way connected with my participation in Youngathlete-affiliated events. I understand that this waiver includes any claims, whether caused by negligence, the action or inaction of any of the above parties, or otherwise.
                            </p>
                            <p>
                              AGREEMENT AND LIABILITY WAIVER (the "Agreement and Waiver")
                            </p>
                            <p>
                              1. Authority to Register and/or to Act as Agent. You represent and warrant that you have full legal authority to complete this Agreement and Waiver on behalf of yourself and/or any party you are registering (the "Registered Parties"), including full authority to make use of the credit or debit card to which registration fees will be charged.  If you are accepting this Agreement and Waiver on behalf of a child under the age of 18 or an incapacitated adult you represent and warrant that you are the parent or legal guardian of that party and have the legal authority to enter into this agreement on their behalf and by proceeding with this event registration, you agree that the terms of this Agreement and Waiver shall apply equally to all Registered Parties. If you are accepting this Agreement and Waiver on behalf of a child under 13, you agree and consent to the collection of that child’s information which you provide for the purposes of registration in Youngathlete-affiliated events or for this Agreement and Waiver.
                            </p>
                            <p>
                              2. Waiver. YOU UNDERSTAND THAT PARTICIPATION IN OR ATTENDANCE AT GOLFING EVENTS IS POTENTIALLY HAZARDOUS, AND THAT A REGISTERED PARTY SHOULD NOT PARTICIPATE UNLESS THEY ARE MEDICALLY ABLE AND PROPERLY TRAINED. YOU UNDERSTAND THAT EVENTS MAY BE HELD ON FACILITIES OPEN TO THE PUBLIC DURING THE EVENT AND UPON WHICH HAZARDS ARE TO BE EXPECTED. PARTICIPATION CARRIES WITH IT CERTAIN INHERENT RISKS THAT CANNOT BE ELIMINATED COMPLETELY RANGING FROM MINOR INJURIES TO CATASTROPHIC INJURIES INCLUDING DEATH. YOU UNDERSTAND AND AGREE THAT IN CONSIDERATION OF BEING PERMITTED TO PARTICIPATE IN Youngathlete-AFFILIATED EVENTS, YOU AND ANY REGISTERED PARTY, THE HEIRS, PERSONAL REPRESENTATIVES OR ASSIGNS OF YOU OR THE REGISTERED PARTY DO HEREBY RELEASE, WAIVE, DISCHARGE AND CONVENANT NOT TO SUE RELEASED PARTIES FOR ANY AND ALL LIABILITY FROM ANY AND ALL CLAIMS ARISING FROM PARTICIPATION IN Youngathlete-AFFILIATED EVENTS, BY YOU OR ANY REGISTERED PARTY.
                            </p>
                            <p>
                              3. Limitation of Liability; Disclaimer of Warranties.
                            </p>
                            <p>
                              4. Indemnification. You agree to indemnify and hold each of Released Parties harmless from and against any and all damages, costs, claims or demands, including reasonable attorneys' fees, made by any third party due to or arising from or relating to your participation in or attendance at Youngathlete-affiliated events or the violation of any term of this Agreement and Waiver as well as the Youngathlete Policies located at: http://www.Youngathlete.com by you.
                            </p>
                            <p>
                              5. Applicable Law; Consent to Jurisdiction. By completing this Agreement and Waiver, you agree that the statutes and laws of the State of California, without regard to the conflict of laws principles thereof, will apply to all matters relating to this Agreement and Waiver. You agree that exclusive jurisdiction for any dispute with Active resides in the courts of the State of California and you further agree and expressly consent to the exercise of personal jurisdiction in the courts of the State of California in connection with any dispute including any claim involving Released Parties.
                            </p>
                            <p>
                              6. Severability. You further expressly agree that this Agreement and Waiver is intended to be as broad and inclusive as is permitted by the law of the State of California and that if any provision of this Agreement and Waiver shall be found to be unlawful, void, or for any reason unenforceable, then that provision shall be deemed severable from this Agreement and Waiver and shall not affect the validity and enforceability of any remaining provisions. 
                            </p>
                            <p>
                              BY INDICATING YOUR ACCEPTANCE OF THIS AGREEMENT AND WAIVER, YOU ARE AFFIRMING THAT YOU HAVE READ AND UNDERSTAND THIS AGREEMENT AND WAIVER AND FULLY UNDERSTAND ITS TERMS. YOU UNDERSTAND THAT YOU ARE GIVING UP SUBSTANTIAL RIGHTS, INCLUDING THE RIGHT TO SUE. YOU ACKNOWLEDGE THAT YOU ARE SIGNING THE AGREEMENT AND WAIVER FREELY AND VOLUNTARILY, AND INTEND BY YOUR ACCEPTANCE TO BE A COMPLETE AND UNCONDITIONAL RELEASE OF ALL LIABILITY TO THE GREATEST EXTENT ALLOWED BY LAW.
                            </p>
                            <p>
                              AUTHORITY TO REGISTER AND/OR TO ACT AS AGENT
                              <br />
                              You represent and warrant to Youngathlete ("us", "we", "our") that you have full legal authority to complete this registration including full authority to make use of the credit or debit card to which registration fees will be charged.  You authorize us to send instructions to the financial institution that issued your card to take payments from your card account, based on the published registration fee(s), in accordance with the terms of your agreement with us. If you have chosen the 'Save card for future use' option, you authorize us to initiate a payment on your behalf each time you register and use the saved card. In addition, if you are registering third parties, you represent and warrant that you have been duly authorized to act as agent on behalf of such parties in performing this registration.  By proceeding with this registration, you agree that the terms of this Registration Agreement shall apply equally to you and to any third parties for whom you are acting as agent.  You represent and warrant that, in compliance with the Children's Online Privacy Protection Act (COPPA), you are over thirteen (13) years of age, and that if you are registering a child under fourteen (14) years of age you are the parent of such child, and do hereby consent to the collection of such child's personal information by us.
                            </p>
                            <p>
                              REGISTRATION POLICY
                              <br />
                              We provide an online registration service ("Service") that allows you the convenience of registering online and paying the registration fee with your credit card.  Your use of our website is subject to our Terms of Service.  The Terms of Service are hereby incorporated by reference into this Agreement.
                            </p>
                            <p>
                              DISCLAIMER OF WARRANTY
                              <br />
                              YOU EXPRESSLY UNDERSTAND AND AGREE THAT: (a) YOUR USE OF THE SERVICE IS AT YOUR SOLE RISK.  THE SERVICE IS PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS. Youngathlete AND ITS SUPPLIERS EXPRESSLY DISCLAIM ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTIBILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT. (b) Youngathlete AND ITS SUPPLIERS MAKE NO WARRANTY THAT (i) THE SERVICE WILL MEET YOUR REQUIREMENTS, (ii) THE SERVICE WILL BE UNINTERRUPTED, TIMELY, SECURE OR ERROR-FREE, (iii) THE RESULTS THAT MAY BE OBTAINED FROM THE USE OF THE SERVICE WILL BE ACCURATE OR RELIABLE, AND (iv) THE QUALITY OF ANY PRODUCTS, SERVICE, INFORMATION, OR OTHER MATERIAL PURCHASED OR OBTAINED BY YOU THROUGH THE SERVICE WILL MEET YOUR EXPECTATIONS.  WHILE Youngathlete AND BLUEGOLF WILL USE REASONABLE EFFORTS TO PREVENT UNAUTHORIZED ACCESS TO DATA ENTERED BY YOU WITHIN THE SERVICE, Youngathlete AND ITS SUPPLIERS MAKE NO WARRANTY THAT SUCH DATA WILL BE SECURE AGAINST SUCH UNAUTHORIZED ACCESS OR OTHER SECURITY BREACHES.
                            </p>
                            <p>
                              LIMITATION OF LIABILITY
                              <br />
                              IN NO EVENT SHALL Youngathlete AND/OR ITS LICENSORS BE LIABLE TO ANYONE FOR ANY DIRECT, INDIRECT, PUNITIVE, SPECIAL, EXEMPLARY, INCIDENTAL, CONSEQUENTIAL OR OTHER DAMAGES OF ANY TYPE OR KIND (INCLUDING LOSS OF DATA, REVENUE, PROFITS, USE OR OTHER ECONOMIC ADVANTAGE) ARISING OUT OF, OR IN ANY WAY CONNECTED WITH THIS SERVICE, INCLUDING BUT NOT LIMITED TO THE USE OR INABILITY TO USE THE SERVICE, OR FOR ANY CONTENT OBTAINED FROM OR THROUGH THE SERVICE, EVEN IF THE PARTY FROM WHICH DAMAGES ARE BEING SOUGHT OR SUCH PARTY'S LICENSORS HAVE BEEN PREVIOUSLY ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
                            </p>
                            <p>
                              INDEMNIFICATION
                              <br />
                              You shall indemnify and hold Youngathlete, its licensors and each such party's parent organizations, subsidiaries, affiliates, officers, directors, employees, attorneys and agents harmless from and against any and all claims, costs, damages, losses, liabilities and expenses (including attorneys' fees and costs) arising out of or in connection with your use of the Service.
                            </p>
                            <p>
                              GENERAL PROVISIONS
                              <br />
                              These terms shall be governed by and construed in accordance with the laws of the State of Pennsylvania, without giving effect to any principles of conflicts of law. You agree that any action at law or in equity arising out of or relating to these terms shall be filed only in the state or federal courts located in Delaware County, Pennsylvania, and you hereby consent and submit to the personal jurisdiction of such courts for the purposes of litigating any such action. If any provision of these terms shall be unlawful, void, or for any reason unenforceable, then that provision shall be deemed severable from these terms and shall not affect the validity and enforceability of any remaining provisions. This is the entire agreement between us relating to the subject matter herein and shall not be modified except in writing, signed by both parties.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="form-check mb-12">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value="true"
                          id="eventCheckoutAgreeTerms"
                          {...register('eventCheckoutAgreeTerms', {
                            required: true,
                            onChange: (e) => {
                              handleEventCheckoutFormData({ [e.target.name] : e.target.value });
                            }
                          })}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="eventCheckoutAgreeTerms"
                        >
                          I agree to the waiver.
                        </label>
                        {
                          errors.eventCheckoutAgreeTerms?.type === "required" && 
                          <div className="formFieldError">
                            You must agree to the waiver or you cannot participate.
                          </div>
                        }
                      </div>
                      <div className="text-center">
                        <button
                          disabled={loadEventCheckoutWaiverButton}
                          className="btn btn-primary rounded-pill btn-lg w-100 mb-6"
                          type="submit"
                        >
                          { loadEventCheckoutWaiverButton ? <Loader text='Continue' /> : 'Continue' }
                        </button>
                        <Link
                          to={ `/events/event-details/${eventDetails?.eventURL}` }
                          className="m-2"
                        >
                          Cancel
                        </Link>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </section>
          </div>
        }
      </Layout>
    </>
  );
};

const EventCheckoutCart = ({ 
  nextStep,
  eventDetails,
  handleEventCheckoutFormData,
  eventCheckoutFormData
}) => {
  const [loading, setLoading] = useState(true);
  const [loadEventCheckoutCartButton, setLoadEventCheckoutCartButton] = useState(false);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isValid },
  } = useForm();
  const [subTotal, setSubTotal] = useState(parseFloat(eventDetails.price).toFixed(2));

  const handleEventCheckoutCartFormSubmit = async (values) => {
    const grandTotal = ((parseFloat(eventDetails.price) * values.qty));
    const cartData = {
      subTotal: subTotal,
      grandTotal: grandTotal.toFixed(2),
      ...values
    };

    handleEventCheckoutFormData(cartData);
    isValid ? setLoadEventCheckoutCartButton(true) : setLoadEventCheckoutCartButton(false)
    await setTimeout(() => {
      setLoadEventCheckoutCartButton(false);
    }, 1500);

    setTimeout(() => {
      nextStep();
    }, 2000);
  };

  useEffect(() => {
    if (loading) {
      setTimeout(() => {
        setLoading(false);
        cleanUpBeforePageLoad();
        loadJSScript("/assets/js/theme.js", 'themeScript');
        window.scrollTo(0, 0);
      }, 1000);
    }
  }, [loading]);

  return (
    <>
      {/*<div className="page-loader"></div>*/}

      {
        !localStorage.getItem('ya-user-access-token')
        ?
        <Navigate replace to="/" />
        :
        ''
      }

      <Layout hideMenu={true} pageLoader={true} >
        <div className="content-wrapper">
          <section className="wrapper">
            <div className="container py-16">
              <div className="row">
                <div className="col-xl-7 col-lg-9 col-md-12 m-auto">
                  <h1 className="display-3 mb-8 text-center">Summary</h1>
                  {
                    loading
                    ?
                    <PageLoader />
                    :
                    <form
                      className="text-start mb-3"
                      onSubmit={handleSubmit(handleEventCheckoutCartFormSubmit)}
                    >
                      <div className="mb-12">
                        <div className="card-body">
                          <div className="shopping-cart mb-7">
                            <div className="shopping-cart-item d-flex justify-content-between mb-4">
                              <div className="d-flex flex-row d-flex align-items-center">
                                <div className="w-100">
                                  <h6 className="mb-1">{eventDetails.eventName}</h6>
                                  <div className="small">
                                    {moment(eventDetails.eventDate).tz(eventDetails.timeZone).format('DD/MM/YYYY')} {`${moment(eventDetails.eventDate + ' ' + eventDetails.eventStartTime + ':00' ).tz(eventDetails.timeZone).format('h:mmA')} - ${moment(eventDetails.eventDate + ' ' + eventDetails.eventEndTime + ':00' ).tz(eventDetails.timeZone).format('h:mmA zz')} `}
                                  </div>
                                </div>
                              </div>
                              <div className="ms-2 d-flex align-items-center">
                                <p className="price fs-sm">
                                  <span className="amount">
                                    {eventDetails.priceCurrency}{parseFloat(eventDetails.price).toFixed(2)}
                                  </span>
                                </p>
                              </div>
                            </div>
                          </div>
                          <hr className="my-4" />
                          <div className="shopping-cart-item d-flex justify-content-between mb-6">
                            <div className="d-flex flex-row d-flex align-items-center">
                              <div className="w-100">
                                <h6 className="mb-0">QTY</h6>
                                <small>Please <Link to="/contact">contact us </Link> for group registration. </small>
                              </div>
                            </div>
                            <div className="ms-2 d-flex align-items-center">
                              <div className="form-select-wrapper">
                                <select
                                  className="form-select form-select-sm mx-auto"
                                  id="qty"
                                  {...register('qty', {
                                    required: true,
                                    validate: {
                                      validateQty: (_, values) => {
                                        return values.qty <= eventDetails.totlPendingTickets;
                                      }
                                    },
                                    onChange: (e) => {
                                      handleEventCheckoutFormData({ [e.target.name] : e.target.value });
                                      let subTotalAMount = parseFloat(eventDetails.price).toFixed(2) * watch('qty');
                                      setSubTotal(subTotalAMount.toFixed(2));
                                    }
                                  })}
                                >
                                  <option value="1">1</option>
                                  <option value="2">2</option>
                                  <option value="3">3</option>
                                  <option value="4">4</option>
                                  <option value="5">5</option>
                                </select>
                              </div>
                              {
                                errors.qty?.type === "required" && 
                                <div className="formFieldError">
                                  Please select qty.
                                </div>
                              }
                              {
                                errors.qty?.type === "validateQty" && 
                                <div className="formFieldError">
                                  You can select qty upto {eventDetails.totlPendingTickets}.
                                </div>
                              }
                            </div>
                          </div>
                          <div className="table-responsive">
                            <table className="table table-order">
                              <tbody>
                                <tr>
                                  <td className="ps-0">
                                    <strong className="text-dark">Subtotal</strong>
                                  </td>
                                  <td className="pe-0 text-end">
                                    <p className="price">
                                    {eventDetails.priceCurrency}{subTotal}
                                    </p>
                                  </td>
                                </tr>
                                <tr>
                                  <td className="ps-0">
                                    <strong className="text-dark">Grand Total</strong>
                                  </td>
                                  <td className="pe-0 text-end">
                                    <p className="price text-dark fw-bold">
                                      {eventDetails.priceCurrency}{((parseFloat(eventDetails.price) * watch('qty'))).toFixed(2)}
                                    </p>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <button
                          disabled={loadEventCheckoutCartButton}
                          className="btn btn-primary rounded btn-lg rounded-pill w-100 mb-4"
                          type="submit"
                        >
                          { loadEventCheckoutCartButton ? <Loader text='RSVP / Checkout' /> : 'RSVP / Checkout' }
                        </button>
                      </div>
                    </form>
                  }
                </div>
              </div>
            </div>
          </section>
        </div>
      </Layout>
    </>
  );
};

const EventCheckoutPayment = ({
  nextStep,
  eventDetails,
  handleEventCheckoutFormData,
  eventCheckoutFormData,
  handleEventCheckoutFormSubmit,
}) => {
  const [loading, setLoading] = useState(true);
  const [loadEventCheckoutPaymentButton, setLoadEventCheckoutPaymentButton] = useState(false);
  const [successMsg, setSuccessMsg] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [countryData, setCountryData] = useState('');
  const [stateData, setStateData] = useState('');
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm();

  //const monthNameLists = moment.monthsShort();

  /*const yearLists = [];
  for (let i = moment().year(); i <= moment().add(10, 'y').format('YYYY'); i++) {
    yearLists.push(i);
  }*/
  
  const handleEventCheckoutPaymentFormSubmit = async (values) => {
    isValid ? setLoadEventCheckoutPaymentButton(true) : setLoadEventCheckoutPaymentButton(false)
    const data = {
      eventId: eventDetails.id,
      ...values,
    };

    handleEventCheckoutFormData(data);
    await setTimeout(() => {
      setLoadEventCheckoutPaymentButton(false);
    }, 1500);

    setTimeout(() => {
      nextStep();
    }, 2000);

    /*await handleEventCheckoutFormSubmit(data)
      .then((result) => {
        setLoadEventCheckoutPaymentButton(false);
        //await setTimeout(() => {
          //setLoadEventCheckoutPaymentButton(false);
        //}, 1500);
        window.scrollTo(0, 0);
        //setSuccessMsg('Your payment has been successfully completed.');
        paymentConfirm(result?.data?.message);
        setErrorMsg('');

        setTimeout(() => {
          nextStep();
        }, 100);
      }).catch((error) => {
        setSuccessMsg('');
        setErrorMsg(error?.response?.data?.message);
        setLoadEventCheckoutPaymentButton(false);
      });*/
  };

  const loadCountryData = async () => {
    await requestApi(
      'GET',
      'get-country-list',
    ).then((result) => {
      setErrorMsg('');
      setCountryData(result.data.response);
    }).catch((error) => {
      setErrorMsg(error?.response?.data?.message);
    });
  };

  const loadCountryStateData = async (value) => {
    setStateData('');
    if (value && value !== '') {
      await requestApi(
        'GET',
        'get-country-state-list',
        {},
        { 'country': value },
      ).then((result) => {
        setErrorMsg('');
        setStateData(result.data.response);
      }).catch((error) => {
        setErrorMsg(error?.response?.data?.message);
      });
    }
  };

  useEffect(() => {
    if (countryData === '') {
      loadCountryData();
    }

    if (countryData !== undefined && countryData !== '') {
      setLoading(false);
      cleanUpBeforePageLoad();
      loadJSScript("/assets/js/theme.js", 'themeScript');
      window.scrollTo(0, 0);
    }
  }, [countryData]);

  return (
    <>
      {/*<div className="page-loader"></div>*/}

      {
        !localStorage.getItem('ya-user-access-token')
        ?
        <Navigate replace to="/" />
        :
        ''
      }

      <Layout hideMenu={true} pageLoader={true} >
        <div className="content-wrapper">
          <section className="wrapper">
            <div className="container py-12">
              <div className="row">
                <div className="col-xl-7 col-lg-9 col-md-12 m-auto">
                  {/*<div className="alert alert-danger alert-icon alert-dismissible fade show" role="alert">
                    <i className="uil uil-exclamation-triangle"></i>Something wrong with your payment. <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                  </div>*/}

                  {
                    successMsg &&
                    <div className="alert alert-success alert-icon alert-dismissible fade show text-start" role="alert">
                      <i className="uil uil-check"></i>{successMsg} <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                    </div>  
                  }
                  {
                    errorMsg &&
                    (typeof errorMsg === "string") &&
                    <div className="alert alert-danger alert-icon alert-dismissible fade show text-start" role="alert">
                      <i className="uil uil-exclamation-triangle"></i>{errorMsg} <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                    </div>  
                  }
                  {
                    errorMsg &&
                    (typeof errorMsg === "object") &&
                    Object.entries(errorMsg).map((value, index) => {
                      return (
                        <div key={`eventCheckoutError${index}`} className="formFieldError">
                          {value[1][0]}
                        </div>
                      );
                    })
                  }
                
                  <h1 className="display-3 mb-8 text-center">Payment</h1>
                  {
                    loading
                    ?
                    <PageLoader />
                    :
                    <>
                      <div className="shopping-cart mb-4">
                        <div className="shopping-cart-item d-flex justify-content-between mb-4">
                          <div className="d-flex flex-row d-flex align-items-center">
                            <div className="w-100">
                              <h3 className="mb-1">Total</h3>
                            </div>
                          </div>
                          <div className="ms-2 d-flex align-items-center">
                            <h3 className="mb-1">
                              {eventDetails.priceCurrency}{eventCheckoutFormData.grandTotal}
                            </h3>
                          </div>
                        </div>
                      </div>
                      <hr className="my-6" />
                      <div className="col-lg-12">
                        <h3 className="mb-4">Billing address</h3>

                        <form
                          className="text-start mb-3"
                          onSubmit={handleSubmit(handleEventCheckoutPaymentFormSubmit)}
                        >
                          <div className="row g-3">
                            <div className="col-sm-6">
                              <div className="form-floating">
                                <input
                                  type="text"
                                  id="firstName"
                                  placeholder="First name"
                                  className="form-control"
                                  {...register('billingAddressInfo.firstName', {
                                    required: true,
                                    maxLength: 255,
                                    value: "",
                                    onChange: (e) => {
                                      //handleEventCheckoutFormData({ [e.target.name] : e.target.value });
                                    }
                                  })}
                                />
                                <label htmlFor="firstName" className="form-label">First name</label>
                                {
                                  errors.billingAddressInfo?.firstName?.type === "required" && 
                                  <div className="formFieldError">
                                    Please enter your first name.
                                  </div>
                                }
                                {
                                  errors.billingAddressInfo?.firstName?.type === "maxLength" && 
                                  <div className="formFieldError">
                                    First name length must be maximum 255 character.
                                  </div>
                                }
                                {/*
                                  errorMsg &&
                                  (typeof errorMsg === "object") &&
                                  errorMsg?.["billingAddressInfo.firstName"] && 
                                  <div className="formFieldError">
                                    {errorMsg?.["billingAddressInfo.firstName"][0]}
                                  </div>
                                */}
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="form-floating">
                                <input
                                  type="text"
                                  id="lastName"
                                  className="form-control"
                                  placeholder="Last name"
                                  {...register('billingAddressInfo.lastName', {
                                    required: true,
                                    maxLength: 255,
                                    value: "",
                                    onChange: (e) => {
                                      //handleEventCheckoutFormData({ [e.target.name] : e.target.value });
                                    }
                                  })}
                                />
                                <label htmlFor="lastName" className="form-label">Last name</label>
                                {
                                  errors.billingAddressInfo?.lastName?.type === "required" && 
                                  <div className="formFieldError">
                                    Please enter your last name.
                                  </div>
                                }
                                {
                                  errors.billingAddressInfo?.lastName?.type === "maxLength" && 
                                  <div className="formFieldError">
                                    Last name length must be maximum 255 character.
                                  </div>
                                }
                                {/*
                                  errorMsg &&
                                  (typeof errorMsg === "object") &&
                                  errorMsg?.["billingAddressInfo.lastName"] && 
                                  <div className="formFieldError">
                                    {errorMsg?.["billingAddressInfo.lastName"][0]}
                                  </div>
                                */}
                              </div>
                            </div>
                            <div className="col-12">
                              <div className="form-floating">
                                <input
                                  type="email"
                                  className="form-control"
                                  id="email"
                                  placeholder="Email"
                                  {...register('billingAddressInfo.email', {
                                    required: true,
                                    maxLength: 255,
                                    pattern: /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/,  //eslint-disable-line
                                    onChange: (e) => {
                                      //handleEventCheckoutFormData({ [e.target.name] : e.target.value });
                                    }                            
                                  })}
                                />
                                <label htmlFor="email" className="form-label">Email</label>
                                {
                                  errors.billingAddressInfo?.email?.type === "required" && 
                                  <div className="formFieldError">
                                    Please enter your email address.
                                  </div>
                                }
                                {
                                  errors.billingAddressInfo?.email?.type === "pattern" && 
                                  <div className="formFieldError">
                                    Please enter a valid email address for shipping updates.
                                  </div>
                                }
                                {
                                  errors.billingAddressInfo?.email?.type === "maxLength" && 
                                  <div className="formFieldError">
                                    Email length must be maximum 255 character.
                                  </div>
                                }
                                {/*
                                  errorMsg &&
                                  (typeof errorMsg === "object") &&
                                  errorMsg?.["billingAddressInfo.email"] && 
                                  <div className="formFieldError">
                                    {errorMsg?.["billingAddressInfo.email"][0]}
                                  </div>
                                */}
                              </div>
                            </div>
                            <div className="col-12">
                              <div className="form-floating">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="address"
                                  placeholder="Address"
                                  {...register('billingAddressInfo.address', {
                                    required: true,
                                    maxLength: 255,
                                    onChange: (e) => {
                                      //handleEventCheckoutFormData({ [e.target.name] : e.target.value });
                                    }                            
                                  })}
                                />
                                <label htmlFor="address" className="form-label">Address</label>
                                {
                                  errors.billingAddressInfo?.address?.type === "required" && 
                                  <div className="formFieldError">
                                    Please enter your shipping address.
                                  </div>
                                }
                                {
                                  errors.billingAddressInfo?.address?.type === "maxLength" && 
                                  <div className="formFieldError">
                                    Address length must be maximum 255 character.
                                  </div>
                                }
                                {/*
                                  errorMsg &&
                                  (typeof errorMsg === "object") &&
                                  errorMsg?.["billingAddressInfo.address"] && 
                                  <div className="formFieldError">
                                    {errorMsg?.["billingAddressInfo.address"][0]}
                                  </div>
                                */}
                              </div>
                            </div>
                            <div className="col-12">
                              <div className="form-floating">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="address2"
                                  placeholder="Apartment or suite"
                                  {...register('billingAddressInfo.address2', {
                                    maxLength: 255,
                                    onChange: (e) => {
                                      //handleEventCheckoutFormData({ [e.target.name] : e.target.value });
                                    }                            
                                  })}
                                />
                                <label htmlFor="address2" className="form-label">Address 2 <span className="text-muted">(Optional)</span>
                                </label>
                                {
                                  errors.billingAddressInfo?.address2?.type === "maxLength" && 
                                  <div className="formFieldError">
                                    Address2 length must be maximum 255 character.
                                  </div>
                                }
                                {/*
                                  errorMsg &&
                                  (typeof errorMsg === "object") &&
                                  errorMsg?.["billingAddressInfo.address2"] && 
                                  <div className="formFieldError">
                                    {errorMsg?.["billingAddressInfo.address2"][0]}
                                  </div>
                                */}
                              </div>
                            </div>
                            <div className="col-md-5">
                              <div className="form-select-wrapper">
                                <select
                                  className="form-select"
                                  id="country"
                                  {...register('billingAddressInfo.country', {
                                    required: true,
                                    value: "",
                                    onChange: (e) => {
                                      //handleEventCheckoutFormData({ [e.target.name] : e.target.value });
                                      loadCountryStateData(e.target.value);
                                    }                            
                                  })}
                                >
                                  <option value="">Country</option>
                                  {
                                    countryData &&
                                    Object.entries(countryData).map((value, index) => {
                                      return (
                                        <option
                                          key={`country_${index}`}
                                          value={value[0]}
                                        >
                                          {value[1]}
                                        </option>
                                      );
                                    })
                                  }
                                </select>
                                {
                                  errors.billingAddressInfo?.country?.type === "required" && 
                                  <div className="formFieldError">
                                    Please select country.
                                  </div>
                                }
                                {/*
                                  errorMsg &&
                                  (typeof errorMsg === "object") &&
                                  errorMsg?.["billingAddressInfo.country"] && 
                                  <div className="formFieldError">
                                    {errorMsg?.["billingAddressInfo.country"][0]}
                                  </div>
                                */}
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="form-select-wrapper">
                                <select
                                  className="form-select"
                                  id="state"
                                  {...register('billingAddressInfo.state', {
                                    required: true,
                                    onChange: (e) => {
                                      //handleEventCheckoutFormData({ [e.target.name] : e.target.value });
                                    }                            
                                  })}
                                >
                                  <option value="">State</option>
                                  {
                                    stateData &&
                                    Object.entries(stateData).map((value, index) => {
                                      return (
                                        <option
                                          key={`state_${index}`}
                                          value={value[0]}
                                        >
                                          {value[1]}
                                        </option>
                                      );
                                    })
                                  }
                                </select>
                                {
                                  errors.billingAddressInfo?.state?.type === "required" && 
                                  <div className="formFieldError">
                                    Please select state.
                                  </div>
                                }
                                {/*
                                  errorMsg &&
                                  (typeof errorMsg === "object") &&
                                  errorMsg?.["billingAddressInfo.state"] && 
                                  <div className="formFieldError">
                                    {errorMsg?.["billingAddressInfo.state"][0]}
                                  </div>
                                */}
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="form-floating">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="zip"
                                  placeholder="Zip Code"
                                  {...register('billingAddressInfo.zipCode', {
                                    required: true,
                                    minLength: 6,
                                    maxLength: 6,
                                    pattern: /^[0-9]+$/,
                                    onChange: (e) => {
                                      //handleEventCheckoutFormData({ [e.target.name] : e.target.value });
                                    }
                                  })}
                                />
                                <label htmlFor="zip" className="form-label">Zip Code</label>
                                {
                                  errors.billingAddressInfo?.zipCode?.type === "required" && 
                                  <div className="formFieldError">
                                    Please enter 6 digit zip code.
                                  </div>
                                }
                                {
                                  errors.billingAddressInfo?.zipCode?.type === "minLength" && 
                                  <div className="formFieldError">
                                    Zip code must be minimum 6 digit.
                                  </div>
                                }
                                {
                                  errors.billingAddressInfo?.zipCode?.type === "maxLength" && 
                                  <div className="formFieldError">
                                    Zip code must be maximum 6 digit.
                                  </div>
                                }
                                {
                                  errors.billingAddressInfo?.zipCode?.type === "pattern" && 
                                  <div className="formFieldError">
                                    Zip code must be number.
                                  </div>
                                }
                                {/*
                                  errorMsg &&
                                  (typeof errorMsg === "object") &&
                                  errorMsg?.["billingAddressInfo.zipCode"] && 
                                  <div className="formFieldError">
                                    {errorMsg?.["billingAddressInfo.zipCode"][0]}
                                  </div>
                                */}
                              </div>
                            </div>
                          </div>
                          <hr className="mt-7 mb-6" />
                          <div className="form-check">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="billingAddressIsSameAsShippingAddress"
                              {...register('billingAddressIsSameAsShippingAddress', {
                                onChange: (e) => {
                                  //handleEventCheckoutFormData({ [e.target.name] : e.target.value });
                                }
                              })}
                              value="true"
                            />
                            <label className="form-check-label" htmlFor="billingAddressIsSameAsShippingAddress">Shipping address is the same as my billing address</label>
                            {/*
                              errorMsg &&
                              (typeof errorMsg === "object") &&
                              errorMsg?.billingAddressIsSameAsShippingAddress && 
                              <div className="formFieldError">
                                {errorMsg?.billingAddressIsSameAsShippingAddress[0]}
                              </div>
                            */}
                          </div>
                          <div className="form-check">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="saveBillingAddressInfoForFuture"
                              {...register('saveBillingAddressInfoForFuture', {
                                onChange: (e) => {
                                  //handleEventCheckoutFormData({ [e.target.name] : e.target.value });
                                }
                              })}
                              value="true"
                            />
                            <label className="form-check-label" htmlFor="saveBillingAddressInfoForFuture">Save this information for next time</label>
                            {/*
                              errorMsg &&
                              (typeof errorMsg === "object") &&
                              errorMsg?.saveBillingAddressInfoForFuture && 
                              <div className="formFieldError">
                                {errorMsg?.saveBillingAddressInfoForFuture[0]}
                              </div>
                            */}                          
                          </div>
                          <hr className="mt-7 mb-6" />
                          <h3 className="mb-4">Payment Method</h3>
                          <div className="mt-3 mb-6">
                            {/*<div className="form-check">
                              <input
                                id="creditCard"
                                type="radio"
                                className="form-check-input"
                                defaultChecked
                                {...register('paymentInfo.paymentMethod', {
                                  required: true,
                                  maxLength: 255,
                                  onChange: (e) => {
                                    //handleEventCheckoutFormData({ [e.target.name] : e.target.value });
                                  }
                                })}
                                value="creditCard"
                              />
                              <label className="form-check-label" htmlFor="creditCard">Credit card</label> 
                            </div>
                            <div className="form-check">
                              <input
                                id="debitCard"
                                type="radio"
                                className="form-check-input"
                                {...register('paymentInfo.paymentMethod', {
                                  required: true,
                                  maxLength: 255,
                                  onChange: (e) => {
                                    //handleEventCheckoutFormData({ [e.target.name] : e.target.value });
                                  }
                                })}
                                value="debitCard"
                              />
                              <label className="form-check-label" htmlFor="debitCard">Debit card</label>
                            </div>*/}
                            {
                              eventCheckoutFormData.grandTotal !== 0
                              ?
                              <>
                                <div className="form-check">
                                  <input
                                    id="card"
                                    type="radio"
                                    className="form-check-input"
                                    {...register('paymentInfo.paymentMethod', {
                                      required: true,
                                      maxLength: 255,
                                      onChange: (e) => {
                                        //handleEventCheckoutFormData({ [e.target.name] : e.target.value });
                                      },
                                    })}
                                    value="card"
                                  />
                                  <label className="form-check-label" htmlFor="card">Card</label> 
                                </div>
                                <div className="form-check">
                                  <input
                                    id="paypal"
                                    type="radio"
                                    className="form-check-input"
                                    {...register('paymentInfo.paymentMethod', {
                                      required: true,
                                      maxLength: 255,
                                      onChange: (e) => {
                                        //handleEventCheckoutFormData({ [e.target.name] : e.target.value });
                                      }
                                    })}
                                    value="paypal"
                                  />
                                  <label className="form-check-label" htmlFor="paypal">PayPal</label>
                                </div>
                                {
                                  errors.paymentInfo?.paymentMethod?.type === "required" && 
                                  <div className="formFieldError">
                                    Please select payment method.
                                  </div>
                                }
                                {
                                  errors.paymentInfo?.paymentMethod?.type === "maxLength" && 
                                  <div className="formFieldError">
                                    Payment method length must be maximum 255 character.
                                  </div>
                                }
                                {/*
                                  errorMsg &&
                                  (typeof errorMsg === "object") &&
                                  errorMsg?.["paymentInfo.paymentMethod"] && 
                                  <div className="formFieldError">
                                    {errorMsg?.["paymentInfo.paymentMethod"][0]}
                                  </div>
                                */}
                              </>
                              :
                              'Free'
                            }
                          </div>
                          {/*<div className="row mb-12">
                            <div className="col-xl-12">
                              <div className="row gy-3 gx-3">
                                <div className="col-md-12">
                                  <div className="form-floating">
                                    <input
                                      className="form-control"
                                      id="cardNumber"
                                      placeholder="Card number"
                                      {...register('paymentInfo.cardNumber', {
                                        required: true,
                                        minLength: 16,
                                        maxLength: 16,
                                        pattern: /^[0-9]+$/,
                                        onChange: (e) => {
                                          //handleEventCheckoutFormData({ [e.target.name] : e.target.value });
                                        }
                                      })}
                                    />
                                    <label htmlFor="cardNumber" className="form-label">Credit card number</label>
                                    {
                                      errors.paymentInfo?.cardNumber?.type === "required" && 
                                      <div className="formFieldError">
                                        Please enter card number.
                                      </div>
                                    }
                                    {
                                      errors.paymentInfo?.cardNumber?.type === "minLength" && 
                                      <div className="formFieldError">
                                        Card number must be minimum 16 digit.
                                      </div>
                                    }
                                    {
                                      errors.paymentInfo?.cardNumber?.type === "maxLength" && 
                                      <div className="formFieldError">
                                        Card number must be maximum 16 digit.
                                      </div>
                                    }
                                    {
                                      errors.paymentInfo?.cardNumber?.type === "pattern" && 
                                      <div className="formFieldError">
                                        Card number must be number.
                                      </div>
                                    }
                                  </div>
                                </div>
                                <div className="col-md-12">
                                  <div className="form-floating">
                                    <input
                                      className="form-control"
                                      id="cardName"
                                      placeholder="Name on card"
                                      {...register('paymentInfo.cardName', {
                                        required: true,
                                        maxLength: 255,
                                        onChange: (e) => {
                                          //handleEventCheckoutFormData({ [e.target.name] : e.target.value });
                                        }
                                      })}
                                    />
                                    <label htmlFor="cardName" className="form-label">Name on card</label>
                                    {
                                      errors.paymentInfo?.cardName?.type === "required" && 
                                      <div className="formFieldError">
                                        Please enter card name.
                                      </div>
                                    }
                                    {
                                      errors.paymentInfo?.cardName?.type === "maxLength" && 
                                      <div className="formFieldError">
                                        Card name length must be maximum 255 character.
                                      </div>
                                    }
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="form-select-wrapper">
                                    <select
                                      className="form-select"
                                      id="expirationDateMonth"
                                      {...register('paymentInfo.expiryDate.month', {
                                        required: true,
                                        onChange: (e) => {
                                          //handleEventCheckoutFormData({ [e.target.name] : e.target.value });
                                        }                            
                                      })}
                                    >
                                      <option value="">Month</option>
                                      {
                                        monthNameLists &&
                                        monthNameLists.map((value, index) => {
                                          return (
                                            <option
                                              key={`expirationDateMonth_${index}`}
                                              value={value}
                                            >
                                              {value}
                                            </option>
                                          );
                                        })
                                      }
                                    </select>
                                    {
                                      errors.paymentInfo?.expiryDate?.month?.type === "required" && 
                                      <div className="formFieldError">
                                        Please select expiry date month.
                                      </div>
                                    }
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="form-select-wrapper">
                                    <select
                                      className="form-select"
                                      id="expirationDateYear"
                                      {...register('paymentInfo.expiryDate.year', {
                                        required: true,
                                        onChange: (e) => {
                                          //handleEventCheckoutFormData({ [e.target.name] : e.target.value });
                                        }                            
                                      })}
                                    >
                                      <option value="">Year</option>
                                      {
                                        yearLists &&
                                        yearLists.map((value, index) => {
                                          return (
                                            <option
                                              key={`expirationDateYear_${index}`}
                                              value={value}
                                            >
                                              {value}
                                            </option>
                                          );
                                        })
                                      }
                                    </select>
                                    {
                                      errors.paymentInfo?.expiryDate?.year?.type === "required" && 
                                      <div className="formFieldError">
                                        Please select expiry date year.
                                      </div>
                                    }
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="form-floating">
                                    <input
                                      className="form-control"
                                      id="cvv"
                                      placeholder="CVV"
                                      {...register('paymentInfo.cvv', {
                                        required: true,
                                        minLength: 3,
                                        maxLength: 3,
                                        pattern: /^[0-9]+$/,
                                        onChange: (e) => {
                                          //handleEventCheckoutFormData({ [e.target.name] : e.target.value });
                                        }
                                      })}
                                    />
                                    <label htmlFor="cvv" className="form-label">CVV</label>
                                    {
                                      errors.paymentInfo?.cvv?.type === "required" && 
                                      <div className="formFieldError">
                                        Please enter 3 digit CVV.
                                      </div>
                                    }
                                    {
                                      errors.paymentInfo?.cvv?.type === "minLength" && 
                                      <div className="formFieldError">
                                        CVV code must be minimum 3 digit.
                                      </div>
                                    }
                                    {
                                      errors.paymentInfo?.cvv?.type === "maxLength" && 
                                      <div className="formFieldError">
                                        CVV code must be maximum 3 digit.
                                      </div>
                                    }
                                    {
                                      errors.paymentInfo?.cvv?.type === "pattern" && 
                                      <div className="formFieldError">
                                        CVV code must be number.
                                      </div>
                                    }
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>*/}

                          <div className="col-12">
                            <button
                              disabled={loadEventCheckoutPaymentButton}
                              className="btn btn-primary rounded btn-lg rounded-pill w-100 mb-4"
                              type="submit"
                            >
                              { loadEventCheckoutPaymentButton ? <Loader text='Next' /> : 'Next' }
                            </button>
                          </div>
                        </form>
                      </div>
                    </>
                  }
                </div>
              </div>
            </div>
          </section>
        </div>
      </Layout>
    </>
  );
};

const EventStripeCheckoutForm = ({
  nextStep,
  handleEventCheckoutFormData,
  handleEventCheckoutFormSubmit,
  paymentConfirm,
  eventCheckoutFormData
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [loadEventStripeCheckoutPaymentButton, setLoadEventStripeCheckoutPaymentButton] = useState(false);
  const [successMsg, setSuccessMsg] = useState('');
  const [errorMsg, setErrorMsg] = useState('');

  const handleStripeFormSubmit = async (event) => {
    event.preventDefault();
    setLoadEventStripeCheckoutPaymentButton(true);

    if (!stripe || !elements) {
      setLoadEventStripeCheckoutPaymentButton(false);
      return;
    }

    const result = await stripe.confirmPayment({
      elements,
      redirect: 'if_required',
    });

    if (result.error) {
      if (result.error.type === "card_error" || result.error.type === "validation_error") {
        setErrorMsg(result.error.message);
      } else {
        setErrorMsg("An unexpected error occurred while do payment.");
      }
    } else {
      const data = {
        paymentGatewayResponse: {
          ...result,
          paymentGatewayName: 'Stripe'
        }
      };

      handleEventCheckoutFormData(data);

      await handleEventCheckoutFormSubmit(data)
      .then((result) => {
        setErrorMsg('');
        setSuccessMsg('Success');
        paymentConfirm(result?.data?.message);
        setTimeout(() => {
          nextStep();
        }, 2000);
      }).catch((error) => {
        setSuccessMsg('');
        setErrorMsg(error?.response?.data?.message);
      });
    }

    setLoadEventStripeCheckoutPaymentButton(false);
  };

  return (
    <>
      {
        !localStorage.getItem('ya-user-access-token')
        ?
        <Navigate replace to="/" />
        :
        ''
      }
      {
        successMsg &&
        <div className="alert alert-success alert-icon alert-dismissible fade show text-start" role="alert">
          <i className="uil uil-check"></i>{successMsg} <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
        </div>  
      }
      {
        errorMsg &&
        <div className="alert alert-danger alert-icon alert-dismissible fade show text-start" role="alert">
          <i className="uil uil-exclamation-triangle"></i>{errorMsg} <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
        </div>  
      }
      <form
        className="text-start mb-3"
        onSubmit={handleStripeFormSubmit}
      >
        <PaymentElement
          options={{
            layout: "tabs"
          }}
        />
        <div className="col-12">
          <button
            disabled={loadEventStripeCheckoutPaymentButton || !stripe || !elements}
            className="btn btn-primary rounded btn-lg rounded-pill w-100 mb-4"
            type="submit"
          >
            { loadEventStripeCheckoutPaymentButton ? <Loader text='Pay Now' /> : 'Pay Now' }
          </button>
        </div>
      </form>
    </>
  );
};

const EventPaypalCheckoutForm = ({
  nextStep,
  handleEventCheckoutFormData,
  handleEventCheckoutFormSubmit,
  paymentConfirm,
  eventCheckoutFormData
}) => {
  const [loadEventPaypalCheckoutPaymentButton, setLoadEventPaypalCheckoutPaymentButton] = useState(false);
  const [successMsg, setSuccessMsg] = useState('');
  const [errorMsg, setErrorMsg] = useState('');

  const handlePaypalFormSubmit = async (event) => {
    event.preventDefault();
    setLoadEventPaypalCheckoutPaymentButton(true);

    const data = {
      paymentGatewayResponse: {
        paymentGatewayName: 'Paypal'
      }
    };

    handleEventCheckoutFormData(data);

    await handleEventCheckoutFormSubmit(data)
    .then((result) => {
      setErrorMsg('');
      setSuccessMsg('Success');
      paymentConfirm(result?.data?.message);
      setTimeout(() => {
        nextStep();
      }, 2000);
    }).catch((error) => {
      setSuccessMsg('');
      setErrorMsg(error?.response?.data?.message);
    });

    setLoadEventPaypalCheckoutPaymentButton(false);
  };

  return (
    <>
      {
        !localStorage.getItem('ya-user-access-token')
        ?
        <Navigate replace to="/" />
        :
        ''
      }
      {
        successMsg &&
        <div className="alert alert-success alert-icon alert-dismissible fade show text-start" role="alert">
          <i className="uil uil-check"></i>{successMsg} <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
        </div>  
      }
      {
        errorMsg &&
        <div className="alert alert-danger alert-icon alert-dismissible fade show text-start" role="alert">
          <i className="uil uil-exclamation-triangle"></i>{errorMsg} <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
        </div>  
      }
      <form
        className="text-start mb-3"
        onSubmit={handlePaypalFormSubmit}
      >
        <div className="col-12">
          <button
            disabled={loadEventPaypalCheckoutPaymentButton}
            className="btn btn-primary rounded btn-lg rounded-pill w-100 mb-4"
            type="submit"
          >
            { loadEventPaypalCheckoutPaymentButton ? <Loader text='Pay Now' /> : 'Pay Now' }
          </button>
        </div>
      </form>
    </>
  );
};

const EventCheckoutPaymentPage = ({
  nextStep,
  eventDetails,
  handleEventCheckoutFormData,
  eventCheckoutFormData,
  handleEventCheckoutFormSubmit,
  paymentConfirm,
}) => {
  const [loading, setLoading] = useState(true);
  const [errorMsg, setErrorMsg] = useState('');
  const [clientSecret, setClientSecret] = useState('');

  const stripePublicKey = process.env.REACT_APP_STRIPE_PUBLIC_KEY;
  const stripePromise = loadStripe(stripePublicKey);
  const appearance = {
    theme: 'stripe',
  };
  
  /*const handleEventCheckoutPaymentFormSubmit = async (values) => {
    isValid ? setLoadEventCheckoutPaymentPageButton(true) : setLoadEventCheckoutPaymentPageButton(false)
    const data = {
      eventId: eventDetails.id,
      ...values,
    };

    handleEventCheckoutFormData(data);

    await handleEventCheckoutFormSubmit(data)
      .then((result) => {
        setLoadEventCheckoutPaymentButton(false);
        //await setTimeout(() => {
          //setLoadEventCheckoutPaymentButton(false);
        //}, 1500);
        window.scrollTo(0, 0);
        //setSuccessMsg('Your payment has been successfully completed.');
        paymentConfirm(result?.data?.message);
        setErrorMsg('');

        setTimeout(() => {
          nextStep();
        }, 100);
      }).catch((error) => {
        setSuccessMsg('');
        setErrorMsg(error?.response?.data?.message);
        setLoadEventCheckoutPaymentButton(false);
      });
  };*/

  const loadStripeClientSecretKey = async () => {
    await requestApi(
      'POST',
      'get-stripe-card-paymentintent-key',
      { 
        'Authorization' : `Bearer ${localStorage.getItem('ya-user-access-token')}`,
      },
      {},
      { 'amount': eventCheckoutFormData.grandTotal }
    ).then((result) => {
      setErrorMsg('');
      setClientSecret(result.data.response);
    }).catch((error) => {
      setErrorMsg(error?.response?.data?.message);
    });
  };

  useEffect(() => {
    if (
        eventCheckoutFormData.paymentInfo.paymentMethod !== undefined
        && eventCheckoutFormData.paymentInfo.paymentMethod !== null
        && eventCheckoutFormData.paymentInfo.paymentMethod === 'card'
        && clientSecret === ''
    ) {
      loadStripeClientSecretKey();
    }

    if (
        clientSecret !== '' || 
        (
          eventCheckoutFormData.paymentInfo.paymentMethod !== undefined
          && eventCheckoutFormData.paymentInfo.paymentMethod !== null
          && eventCheckoutFormData.paymentInfo.paymentMethod === 'paypal'
        )
    ) {
      setLoading(false);
      cleanUpBeforePageLoad();
      loadJSScript("/assets/js/theme.js", 'themeScript');
      window.scrollTo(0, 0);
    }
  }, [clientSecret, eventCheckoutFormData]);

  return (
    <>
      {/*<div className="page-loader"></div>*/}

      {
        !localStorage.getItem('ya-user-access-token')
        ?
        <Navigate replace to="/" />
        :
        ''
      }

      <Layout hideMenu={true} pageLoader={true} >
        <div className="content-wrapper">
          <section className="wrapper">
            <div className="container py-12">
              <div className="row">
                <div className="col-xl-7 col-lg-9 col-md-12 m-auto">
                  <h1 className="display-6 mb-8 text-center">
                    Selected Payment Method: <span className="text-uppercase">{eventCheckoutFormData.paymentInfo.paymentMethod}</span>
                  </h1>
                  {
                    loading
                    ?
                    <PageLoader />
                    :
                    <>
                      <div className="mt-3 mb-6">
                      {
                        eventCheckoutFormData.paymentInfo.paymentMethod !== undefined
                        && eventCheckoutFormData.paymentInfo.paymentMethod !== null
                        && eventCheckoutFormData.paymentInfo.paymentMethod === 'card'
                        && clientSecret !== ''
                        && 
                        <div className="row mb-6">
                          <Elements
                            stripe={stripePromise}
                            options={{
                              clientSecret,
                              appearance
                            }}
                          >
                            <EventStripeCheckoutForm
                              nextStep={nextStep}
                              handleEventCheckoutFormData={handleEventCheckoutFormData}
                              handleEventCheckoutFormSubmit={handleEventCheckoutFormSubmit}
                              paymentConfirm={paymentConfirm}
                              eventCheckoutFormData={eventCheckoutFormData}
                            />
                          </Elements>
                        </div>
                      }

                      {
                        eventCheckoutFormData.paymentInfo.paymentMethod !== undefined
                        && eventCheckoutFormData.paymentInfo.paymentMethod !== null
                        && eventCheckoutFormData.paymentInfo.paymentMethod === 'paypal'
                        && 
                        <div className="row mb-6">
                          <EventPaypalCheckoutForm
                            nextStep={nextStep}
                            handleEventCheckoutFormData={handleEventCheckoutFormData}
                            handleEventCheckoutFormSubmit={handleEventCheckoutFormSubmit}
                            paymentConfirm={paymentConfirm}
                            eventCheckoutFormData={eventCheckoutFormData}
                          />
                        </div>
                      }
                      </div>
                    </>
                  }
                </div>
              </div>
            </div>
          </section>
        </div>
      </Layout>
    </>
  );
};

const EventCheckoutPaymentConfirmation = ({ successMsg }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (loading) {
      setTimeout(() => {
        setLoading(false);
        cleanUpBeforePageLoad();
        loadJSScript("/assets/js/theme.js", 'themeScript');
        window.scrollTo(0, 0);
      }, 1000);
    }
  }, [loading]);

  return (
    <>
      {/*<div className="page-loader"></div>*/}

      {
        !localStorage.getItem('ya-user-access-token')
        ?
        <Navigate replace to="/" />
        :
        ''
      }

      <Layout hideMenu={true} pageLoader={true} >
        {
          loading
          ?
          <PageLoader />
          :
          <div className="content-wrapper">
            <section className="wrapper">
              <div className="container py-16 text-center">
                <div className="col-xl-7 col-lg-9 col-md-12 m-auto">
                  <h1 className="display-3 mb-6">Great, you're all set!</h1>
                  <p className="mb-4">{successMsg}.Confirmation email will sent to your email account.</p>
                  <p className="mb-12">Click continue to proceed to our home page.</p>
                  <p>
                    <Link to="/" className="btn btn-primary rounded-pill btn-lg w-100 px-12">Continue</Link>
                  </p>
                </div>
              </div>
            </section>
          </div>
        }
      </Layout>
    </>
  );
};

const EventCheckout = () => {
  const [onEventCheckoutStep, setOnEventCheckoutStep] = useState(1);
  const [eventCheckoutFormData, setEventCheckoutFormData] = useState({});
  const [eventData, setEventData] = useState('');
  const [successMsg, setSuccessMsg] = useState('');
  const { eventURL } = useParams();
  const navigate = useNavigate();

  const nextStep = () => {
    setOnEventCheckoutStep(onEventCheckoutStep + 1);
  };

  const handleEventCheckoutFormData = (values) => {
    setEventCheckoutFormData({...eventCheckoutFormData, ...values });
  };

  const paymentConfirm = (value) => {
    setSuccessMsg(value);
  };

  const loadEventDetailData = async () => {
    await requestApi(
      'GET',
      `get-single-event-details/${eventURL}`,
    ).then((result) => {
      if (result.data.response.length !== undefined && result.data.response.length === 0) {
        alert('Event not found');
        navigate('/events');
      }
      setEventData(result.data.response);
    }).catch((error) => {
      console.log(error?.response?.data?.message);
    });
  };

  const handleEventCheckoutFormSubmit = async (values) => {
    const newData = {
      ...values, ...eventCheckoutFormData
    };
    delete newData.eventCheckoutAgreeTerms;
    newData.billingAddressIsSameAsShippingAddress = Boolean(newData.billingAddressIsSameAsShippingAddress);
    newData.saveBillingAddressInfoForFuture = Boolean(newData.saveBillingAddressInfoForFuture);
    const postEventCheckout = await requestApi(
        'POST',
        'book-event',
        { 
          'Authorization' : `Bearer ${localStorage.getItem('ya-user-access-token')}`,
        },
        {},
        newData
      );

      return postEventCheckout;
  }

  if (eventData === '') {
    loadEventDetailData();
  }

  switch (onEventCheckoutStep) {
    case 1:
      return (
        <EventCheckoutWaiver
          nextStep={nextStep}
          eventDetails={eventData}
          handleEventCheckoutFormData={handleEventCheckoutFormData}
          eventCheckoutFormData={eventCheckoutFormData}
        />
      );

    case 2:
      return (
        <EventCheckoutCart
          nextStep={nextStep}
          eventDetails={eventData}
          handleEventCheckoutFormData={handleEventCheckoutFormData}
          eventCheckoutFormData={eventCheckoutFormData}
        />
      );

    case 3:
      return (
        <EventCheckoutPayment
          nextStep={nextStep}
          eventDetails={eventData}
          handleEventCheckoutFormData={handleEventCheckoutFormData}
          eventCheckoutFormData={eventCheckoutFormData}
          handleEventCheckoutFormSubmit={handleEventCheckoutFormSubmit}
        />
      );

    case 4:
      return (
        <EventCheckoutPaymentPage
          nextStep={nextStep}
          eventDetails={eventData}
          handleEventCheckoutFormData={handleEventCheckoutFormData}
          eventCheckoutFormData={eventCheckoutFormData}
          handleEventCheckoutFormSubmit={handleEventCheckoutFormSubmit}
          paymentConfirm={paymentConfirm}
        />
      );

    case 5:
      return (
        <EventCheckoutPaymentConfirmation
          eventDetails={eventData}
          successMsg={successMsg}
        />
      );

    default:
      return (
        <EventCheckoutWaiver />
      );
  }
};

export default EventCheckout;