import React, { useState, useEffect } from 'react';
import { Navigate, useNavigate, useLocation } from 'react-router-dom';
import {
  Layout,
  cleanUpBeforePageLoad,
  loadJSScript,
  PageLoader,
  requestApi,
} from '../../CommonFunction';

const MembershipPlan = () => {
  const [loading, setLoading] = useState(true);
  const [membershipPlans, setMembershipPlans] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const navigate = useNavigate();
  const { state } = useLocation();

  const loadMembershipPlan = async () => {
    await requestApi(
      'GET',
      'get-membership-plan',
      { 'Authorization' : `Bearer ${localStorage.getItem('ya-user-access-token')} ` },
    ).then((result) => {
      setErrorMsg('');
      setMembershipPlans(result.data.response);
    }).catch((error) => {
      setLoading(false);
      setErrorMsg(error?.response?.data?.message);
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    if (membershipPlans === '' && localStorage.getItem('ya-user-access-token')) {
      loadMembershipPlan();
    }

    if (membershipPlans !== undefined && membershipPlans !== '') {
      setLoading(false);
      cleanUpBeforePageLoad();
      loadJSScript("/assets/js/theme.js", 'themeScript');
      window.scrollTo(0, 0);
    }
  }, [membershipPlans]);

  return (
    <>
      {/*<div className="page-loader"></div>*/}

      {
        !localStorage.getItem('ya-user-access-token')
        ?
        <Navigate replace to="/" />
        :
        ''
      }

      <Layout pageLoader={true} >
        {
          loading
          ?
          <PageLoader />
          :
          <div className="content-wrapper">
            <section className="wrapper bg-soft-primary">
              <div className="container py-14 text-center">
                <div className="row">
                  {
                    errorMsg &&
                    (typeof errorMsg === "string") &&
                    <div className="alert alert-danger alert-icon alert-dismissible fade show text-start" role="alert">
                      <i className="uil uil-exclamation-triangle"></i>{errorMsg} <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                    </div>  
                  }
                  <div className="col-sm-9 col-md-7 col-lg-5 pb-18 mx-auto">
                    <h1 className="display-3 mb-3">Our membership</h1>
                    <p className="lead mb-0 px-xl-10 px-xxl-13">Become a member to be part of our athlete family.</p>
                  </div>
                </div>
              </div>
            </section>
            <section className="wrapper">
              <div className="container pb-14 pb-md-16">
                <div className="pricing-wrapper position-relative mt-n18 mt-md-n21 mb-12 mb-md-15">
                  {
                    membershipPlans !== undefined && membershipPlans?.length > 0
                    ?
                      <>
                        <div className="shape bg-dot primary rellax w-16 h-18" data-rellax-speed="1" style={{ top: '2rem', right: '-2.4rem' }}></div>
                        <div className="shape rounded-circle bg-line red rellax w-18 h-18 d-none d-lg-block" data-rellax-speed="1" style={{ bottom: '0.5rem', left: '-2.5rem' }}></div>
                        <div className="row gy-6 mt-3 mt-md-5">
                          {
                            membershipPlans.map((value, key) => {
                              return (
                                <div key={`membershipPlanId${value?.id}`} className={`col-md-6 col-lg-4 ${(value?.id === 2) ? 'popular' : (value?.id === 3) ? 'offset-md-3 offset-lg-0' : ''}`}>
                                  <div className="pricing card text-center">
                                    <div className="card-body">
                                      {
                                        value?.id === 1
                                        ?
                                        <img src="/assets/img/icons/lineal/shopping-basket.svg" className="icon-svg icon-svg-md text-primary mb-3" alt="" />
                                        :
                                        value?.id === 2
                                        ?
                                        <img src="/assets/img/icons/lineal/home.svg" className="icon-svg icon-svg-md text-primary mb-3" alt="" />
                                        :
                                        value?.id === 3
                                        ?
                                        <img src="/assets/img/icons/lineal/briefcase-2.svg" className="icon-svg icon-svg-md text-primary mb-3" alt="" />
                                        :
                                        ''
                                      }
                                      <h4 className="card-title">{value?.planName}</h4>
                                      <div className="prices text-dark">
                                        <div className="price price-show">
                                          <span className="price-currency">{value?.priceCurrency}</span>
                                          <span className="price-value">{value?.price}</span>
                                          <span className="price-duration">
                                            {value?.planType === 'yearly' ? 'yr' : value?.planType === 'monthly' ? 'month' : ''}
                                          </span>
                                        </div>
                                        <div className="price price-hide price-hidden">
                                          <span className="price-currency">{value?.priceCurrency}</span>
                                          <span className="price-value">{value?.price}</span>
                                          <span className="price-duration">
                                            {value?.planType === 'yearly' ? 'yr' : value?.planType === 'monthly' ? 'month' : ''}
                                          </span>
                                        </div>
                                      </div>
                                      <ul className="icon-list bullet-bg bullet-soft-primary mt-7 mb-8 text-start">
                                        <li>
                                          <i className="uil uil-check"></i>
                                          <span>
                                            <strong>{value?.descriptions?.project}</strong>{` Project${value?.descriptions?.project > 1 ? 's' : ''} `}</span>
                                        </li>
                                        <li>
                                          <i className="uil uil-check"></i>
                                          <span>
                                            <strong>{value?.descriptions?.apiAccess}</strong> API Access </span>
                                        </li>
                                        <li>
                                          <i className="uil uil-check"></i>
                                          <span>
                                            <strong>{value?.descriptions?.storage}</strong> Storage </span>
                                        </li>
                                        <li>
                                          {
                                            value?.descriptions?.weeklyReports === 'yes'
                                            ?
                                            <i className="uil uil-check"></i>
                                            :
                                            <i className="uil uil-times bullet-soft-red"></i>
                                          }
                                          <span> Weekly <strong>Reports</strong>
                                          </span>
                                        </li>
                                        <li>
                                          {
                                            value?.descriptions?.customerSupport === 'yes'
                                            ?
                                            <i className="uil uil-check"></i>
                                            :
                                            <i className="uil uil-times bullet-soft-red"></i>
                                          }
                                          <span> 7/24 <strong>Support</strong>
                                          </span>
                                        </li>
                                      </ul>
                                      <button
                                        onClick={() => {
                                          if (state !== null && state?.redirectPageFrom === 'renew-membership') {
                                            navigate('/account/renew-membership', { state: { selectedPlan: value }})
                                          } else {
                                            navigate('/account/buy-membership', { state: { selectedPlan: value }})
                                          }
                                        }}
                                        className="btn btn-primary rounded-pill w-100"
                                      >
                                        Join
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              )
                            })
                          }
                        </div>
                      </>
                    :
                    <p className="text-center pt-10 text-dark">No plans available</p>
                  }
                </div>
              </div>
            </section>
          </div>
        }
      </Layout>
    </>
  );
};

export default MembershipPlan;