import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Link, Navigate } from 'react-router-dom';
import {
  Loader,
  Layout,
  cleanUpBeforePageLoad,
  loadJSScript,
  PageLoader,
  requestApi,
} from '../CommonFunction';

const ChangeAddress = () => {
  const [loading, setLoading] = useState(true);
  const [changeAddressButton, setChangeAddressButton] = useState(false);
  const [successMsg, setSuccessMsg] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [userAddressInfoData, setUserAddressInfoData] = useState('');
  const [countryData, setCountryData] = useState('');
  const [stateData, setStateData] = useState('');
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isValid },
  } = useForm();

  const handleChangeAddressFormSubmit = async (values) => {
    isValid ? setChangeAddressButton(true) : setChangeAddressButton(false)

    await requestApi(
      'POST',
      'update-user-address-info',
      { 
        'Authorization' : `Bearer ${localStorage.getItem('ya-user-access-token')}`,
      },
      {},
      values
    ).then((result) => {
      setSuccessMsg('Your information has been updated.');
      // setTimeout(() => {
      //   setChangeAddressButton(false);  
      // }, 1500);
      setChangeAddressButton(false);
      setErrorMsg('');
    }).catch((error) => {
      setSuccessMsg('');
      setErrorMsg(error?.response?.data?.message);
      setChangeAddressButton(false);
    });
  };

  const loadCountryData = async () => {
    await requestApi(
      'GET',
      'get-country-list',
    ).then((result) => {
      setCountryData(result.data.response);
    }).catch((error) => {
      setErrorMsg(error?.response?.data?.message);
    });
  };

  const loadCountryStateData = async (value) => {
    setStateData('');
    if (value && value !== '') {
      await requestApi(
        'GET',
        'get-country-state-list',
        {},
        { 'country': value },
      ).then((result) => {
        setStateData(result.data.response);
      }).catch((error) => {
        setErrorMsg(error?.response?.data?.message);
      });
    }
  };

  const loadUserAddressInfoData = async () => {
    await requestApi(
      'GET',
      'get-user-data',
      { 'Authorization' : `Bearer ${localStorage.getItem('ya-user-access-token')} ` },
    ).then((result) => {
      setUserAddressInfoData(result.data.response.addressInformation);
    }).catch((error) => {
      setErrorMsg(error?.response?.data?.message);
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    if (countryData === ''
        && userAddressInfoData === ''
        && localStorage.getItem('ya-user-access-token')
    ) {
      loadCountryData();
      loadUserAddressInfoData();
    }

    if (
        countryData !== undefined
        && countryData !== null
        && userAddressInfoData !== undefined
        && userAddressInfoData !== null
    ) {
      loadCountryStateData(userAddressInfoData.country);
      
      setTimeout(() => {  
        setValue('address', userAddressInfoData.address);
        setValue('address2', userAddressInfoData.address2);
        setValue('country', userAddressInfoData.country);
        setValue('state', userAddressInfoData.state);
        setValue('zipCode', userAddressInfoData.zipCode);

        setLoading(false);
        cleanUpBeforePageLoad();
        loadJSScript("/assets/js/theme.js", 'themeScript');
        window.scrollTo(0, 0);
      }, 1000);
    }
  }, [userAddressInfoData, countryData, setValue]);

  return (
    <>
      {/*<div className="page-loader"></div>*/}

      {
        !localStorage.getItem('ya-user-access-token')
        ?
        <Navigate replace to="/" />
        :
        ''
      }

      <Layout isUserLogin={true} pageLoader={true} >
        <div className="content-wrapper">
          <section className="wrapper">
            <div className="container py-12">
              <div className="col-xl-7 col-lg-9 col-md-12 m-auto">
                <nav className="d-inline-block" aria-label="breadcrumb">
                  <ol className="breadcrumb mb-6">
                    <li className="breadcrumb-item">
                      <Link to="/account">Account</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">Change Address</li>
                  </ol>
                </nav>
                {/*<div className="alert alert-success alert-icon alert-dismissible fade show" role="alert">
                  <i className="uil uil-check-circle"></i>Your information has been updated. <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                </div>*/}

                {
                  successMsg &&
                  <div className="alert alert-success alert-icon alert-dismissible fade show text-start" role="alert">
                    <i className="uil uil-check"></i>{successMsg} <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                  </div>  
                }
                {
                  errorMsg &&
                  (typeof errorMsg === "string") &&
                  <div className="alert alert-danger alert-icon alert-dismissible fade show text-start" role="alert">
                    <i className="uil uil-exclamation-triangle"></i>{errorMsg} <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                  </div>  
                }

                <h2 className="mb-6">Address</h2>
                {
                  loading
                  ?
                  <PageLoader />
                  :
                  <form
                    className="text-start mb-3"
                    onSubmit={handleSubmit(handleChangeAddressFormSubmit)}
                  >
                    <div className="row g-3 mb-12">
                      <div className="col-12">
                        <div className="form-floating">
                          <input
                            id="address1"
                            className="form-control"
                            placeholder="1234 Main St"
                            {...register('address', {
                              required: true,
                              maxLength: 255,
                              value: userAddressInfoData && userAddressInfoData.address,                       
                            })}
                          />
                          <label htmlFor="address1" className="form-label">Address</label>
                          {
                            errors.address?.type === "required" && 
                            <div className="formFieldError">
                              Please enter your address.
                            </div>
                          }
                          {
                            errors.address?.type === "maxLength" && 
                            <div className="formFieldError">
                              Address may not be greater than 255 character.
                            </div>
                          }
                          {
                            errorMsg &&
                            (typeof errorMsg === "object") &&
                            errorMsg?.address && 
                            <div className="formFieldError">
                              {errorMsg.address[0]}
                            </div>
                          }
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-floating">
                          <input
                            id="address2"
                            className="form-control"
                            placeholder="Apartment or suite"
                            {...register('address2', {
                              maxLength: 255,
                              value: userAddressInfoData && userAddressInfoData.address2
                            })}
                          />
                          <label htmlFor="address2" className="form-label">Address 2 <span className="text-muted">(Optional)</span>
                          </label>
                          {
                            errors.address2?.type === "maxLength" && 
                            <div className="formFieldError">
                              Address2 may not be greater than 255 character.
                            </div>
                          }
                          {
                            errorMsg &&
                            (typeof errorMsg === "object") &&
                            errorMsg?.address2 && 
                            <div className="formFieldError">
                              {errorMsg.address2[0]}
                            </div>
                          }
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-select-wrapper">
                          <select
                            id="country"
                            className="form-select"
                            {...register('country', {
                              required: true,
                              value: userAddressInfoData && userAddressInfoData.country,
                              onChange: ({ target }) => loadCountryStateData(target.value),                           
                            })}
                          >
                            <option value="">Country</option>
                            {/*<option value="US">United States</option>*/}
                            {
                              countryData &&
                              Object.entries(countryData).map((value, index) => {
                                return (
                                  <option
                                    key={`country_${index}`}
                                    value={value[0]}
                                  >
                                    {value[1]}
                                  </option>
                                );
                              })
                            }
                          </select>
                          {
                            errors.country?.type === "required" && 
                            <div className="formFieldError">
                              Please select country.
                            </div>
                          }
                          {
                            errorMsg &&
                            (typeof errorMsg === "object") &&
                            errorMsg?.country && 
                            <div className="formFieldError">
                              {errorMsg.country[0]}
                            </div>
                          }
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-select-wrapper">
                          <select
                            id="state"
                            name="state"
                            className="form-select"
                            {...register('state', {
                              required: true,
                              value: userAddressInfoData && userAddressInfoData.state,
                            })}
                          >
                            <option value="">State</option>
                            {/*<option value="california">California</option>*/}
                            {
                              stateData &&
                              Object.entries(stateData).map((value, index) => {
                                return (
                                  <option
                                    key={`state_${index}`}
                                    value={value[0]}
                                  >
                                    {value[1]}
                                  </option>
                                );
                              })
                            }
                          </select>
                          {
                            errors.state?.type === "required" && 
                            <div className="formFieldError">
                              Please select state.
                            </div>
                          }
                          {
                            errorMsg &&
                            (typeof errorMsg === "object") &&
                            errorMsg?.state && 
                            <div className="formFieldError">
                              {errorMsg.state[0]}
                            </div>
                          }
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-floating">
                          <input
                            id="zip"
                            className="form-control"
                            placeholder="Zip Code"
                            {...register('zipCode', {
                              required: true,
                              value: userAddressInfoData && userAddressInfoData.zipCode,
                              minLength: 6,
                              maxLength: 6,
                              pattern: /^[0-9]+$/,
                            })}
                          />
                          <label htmlFor="zip" className="form-label">Zip Code</label>
                          {
                            errors.zipCode?.type === "required" && 
                            <div className="formFieldError">
                              Please enter 6 digit zip code.
                            </div>
                          }
                          {
                            errors.zipCode?.type === "minLength" && 
                            <div className="formFieldError">
                              Zip code must be minimum 6 digit.
                            </div>
                          }
                          {
                            errors.zipCode?.type === "maxLength" && 
                            <div className="formFieldError">
                              Zip code must be maximum 6 digit.
                            </div>
                          }
                          {
                            errors.zipCode?.type === "pattern" && 
                            <div className="formFieldError">
                              Zip code must be number.
                            </div>
                          }
                          {
                            errorMsg &&
                            (typeof errorMsg === "object") &&
                            errorMsg?.zipCode && 
                            <div className="formFieldError">
                              {errorMsg.zipCode[0]}
                            </div>
                          }
                        </div>
                      </div>
                    </div>
                    <button
                      disabled={changeAddressButton}
                      className="btn btn-primary rounded-pill btn-lg w-100 mb-2"
                      type="submit"
                    >
                      { changeAddressButton ? <Loader text='Update' /> : 'Update' }
                    </button>
                  </form>
                }
              </div>
            </div>
          </section>
        </div>
      </Layout>
    </>
  );
};

export default ChangeAddress;