import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Link, Navigate } from 'react-router-dom';
import {
  Loader,
  Layout,
  cleanUpBeforePageLoad,
  loadJSScript,
  PageLoader,
  requestApi,
} from '../CommonFunction';

const EmailPreference = () => {
  const [loading, setLoading] = useState(true);
  const [updateEmailPreferenceButton, setUpdateEmailPreferenceButton] = useState(false);
  const [successMsg, setSuccessMsg] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [userEmailNotificationData, setUserEmailNotificationData] = useState('');
  const {
    register,
    handleSubmit,
    setValue,
    formState: { isValid },
  } = useForm();

  const handleUpdateEmailPrefrenceFormSubmit = async (values) => {
    isValid ? setUpdateEmailPreferenceButton(true) : setUpdateEmailPreferenceButton(false)
    
    await requestApi(
      'POST',
      'update-user-email-notification-setting',
      { 
        'Authorization' : `Bearer ${localStorage.getItem('ya-user-access-token')}`,
      },
      {},
      values
    ).then((result) => {
      setSuccessMsg('Your email preferences have been updated.');
      // await setTimeout(async () => {
      //   setUpdateEmailPreferenceButton(false);
      // }, 1500);
      setUpdateEmailPreferenceButton(false);
      setErrorMsg('');
    }).catch((error) => {
      setSuccessMsg('');
      setErrorMsg(error?.response?.data?.message);
      setUpdateEmailPreferenceButton(false);
    });
  };

  const loadUserEmailNotificationSettingData = async () => {
    await requestApi(
      'GET',
      'get-user-data',
      { 'Authorization' : `Bearer ${localStorage.getItem('ya-user-access-token')} ` },
    ).then((result) => {
      setUserEmailNotificationData(result.data.response.emailNotification);
    }).catch((error) => {
      setErrorMsg(error?.response?.data?.message);
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    if (userEmailNotificationData === ''
        && localStorage.getItem('ya-user-access-token')
    ) {
      loadUserEmailNotificationSettingData();
    }

    if (
        userEmailNotificationData !== undefined
        && userEmailNotificationData !== null
    ) {
      setTimeout(() => {
        setLoading(false);
        cleanUpBeforePageLoad();
        loadJSScript("/assets/js/theme.js", 'themeScript');
        window.scrollTo(0, 0);
      }, 1000);

      setValue('newsNotification', userEmailNotificationData?.newsNotification);
      setValue('tipsNotification', userEmailNotificationData?.tipsNotification);
      setValue('partnerNotification', userEmailNotificationData?.partnerNotification);
      setValue('surveyNotification', userEmailNotificationData?.surveyNotification);
    }
  }, [userEmailNotificationData, setValue]);

  return (
    <>
      {/*<div className="page-loader"></div>*/}

      {
        !localStorage.getItem('ya-user-access-token')
        ?
        <Navigate replace to="/" />
        :
        ''
      }

      <Layout isUserLogin={true} pageLoader={true} >
        <div className="content-wrapper">
          <section className="wrapper">
            <div className="container py-14">
              <div className="col-xl-7 col-lg-9 col-md-12 m-auto">
                <nav className="d-inline-block" aria-label="breadcrumb">
                  <ol className="breadcrumb mb-6">
                    <li className="breadcrumb-item">
                      <Link to="/account">Account</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">Email Preferences</li>
                  </ol>
                </nav>
                {/*<div className="alert alert-success alert-icon alert-dismissible fade show" role="alert">
                  <i className="uil uil-check-circle"></i>Your Email preferences have been updated. <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                </div>*/}

                {
                  successMsg &&
                  <div className="alert alert-success alert-icon alert-dismissible fade show text-start" role="alert">
                    <i className="uil uil-check"></i>{successMsg} <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                  </div>  
                }
                {
                  errorMsg &&
                  (typeof errorMsg === "string") &&
                  <div className="alert alert-danger alert-icon alert-dismissible fade show text-start" role="alert">
                    <i className="uil uil-exclamation-triangle"></i>{errorMsg} <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                  </div>  
                }

                <h2 className="mb-6">Email preferences</h2>
                {
                  loading
                  ?
                  <PageLoader />
                  :
                  <>
                    <p className="mb-6">You will always receive email when there is a transaction or change to your account.</p>
                    <h4>You will receive emails when there are:</h4>
                    <form
                      className="text-start mb-3"
                      onSubmit={handleSubmit(handleUpdateEmailPrefrenceFormSubmit)}
                    >
                      <div className="form-check mb-4">
                        <input
                          id="newsNotification"
                          type="checkbox"
                          className="form-check-input"
                          {...register('newsNotification', {
                            value: userEmailNotificationData && userEmailNotificationData.newsNotification
                          })}
                        />
                        <label className="form-check-label" htmlFor="newsNotification">
                          <h6 className="mb-0">News & Annoucements</h6> News about our programs and features
                        </label>
                        {
                          errorMsg &&
                          (typeof errorMsg === "object") &&
                          errorMsg?.newsNotification && 
                          <div className="formFieldError">
                            {errorMsg.newsNotification[0]}
                          </div>
                        }
                      </div>
                      <div className="form-check mb-4">
                        <input
                          id="tipsNotification"
                          type="checkbox"
                          className="form-check-input"
                          {...register('tipsNotification', {
                            value: userEmailNotificationData && userEmailNotificationData.tipsNotification
                          })}
                        />
                        <label className="form-check-label" htmlFor="tipsNotification">
                          <h6 className="mb-0"> Tips & Suggestions</h6>Get the latest tips and coaching information for your sports
                        </label>
                        {
                          errorMsg &&
                          (typeof errorMsg === "object") &&
                          errorMsg?.tipsNotification && 
                          <div className="formFieldError">
                            {errorMsg.tipsNotification[0]}
                          </div>
                        }
                      </div>
                      <div className="form-check mb-4">
                        <input
                          id="partnerNotification"
                          className="form-check-input"
                          type="checkbox"
                          {...register('partnerNotification', {
                            value: userEmailNotificationData && userEmailNotificationData.partnerNotification
                          })}
                        />
                        <label className="form-check-label" htmlFor="partnerNotification">
                          <h6 className="mb-0">Partners Update</h6> News about our partner products and other third party services
                        </label>
                        {
                          errorMsg &&
                          (typeof errorMsg === "object") &&
                          errorMsg?.partnerNotification && 
                          <div className="formFieldError">
                            {errorMsg.partnerNotification[0]}
                          </div>
                        }
                      </div>
                      <div className="form-check mb-4">
                        <input
                          id="surveyNotification"
                          type="checkbox"
                          className="form-check-input"
                          {...register('surveyNotification', {
                            value: userEmailNotificationData && userEmailNotificationData.surveyNotification
                          })}
                        />
                        <label className="form-check-label" htmlFor="surveyNotification">
                          <h6 className="mb-0">Surveys</h6> Give us your feedback on how to make our organization the best for you.
                        </label>
                        {
                          errorMsg &&
                          (typeof errorMsg === "object") &&
                          errorMsg?.surveyNotification && 
                          <div className="formFieldError">
                            {errorMsg.surveyNotification[0]}
                          </div>
                        }
                      </div>                
                      <button
                        disabled={updateEmailPreferenceButton}
                        className="btn btn-primary rounded-pill btn-lg w-100 mb-2"
                        type="submit"
                      >
                        { updateEmailPreferenceButton ? <Loader text='Update' /> : 'Update' }
                      </button>
                    </form>
                  </>
                }
              </div>
            </div>
          </section>
        </div>
      </Layout>
    </>
  );
};

export default EmailPreference;