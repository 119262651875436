import React, { useState, useEffect } from 'react';
import {
  Link,
  useParams,
  Navigate,
  useLocation,
  useNavigate,
} from 'react-router-dom'; 
import {
  Layout,
  cleanUpBeforePageLoad,
  loadJSScript,
  PageLoader,
  requestApi
} from '../../components/CommonFunction';
import {
  AddNewCareerHighlightForm,
  UpdateCareerHighlightForm
} from '../../components/ModalForms';

const CareerHighlights = () => {
  const [loading, setLoading] = useState(true);
  const [successMsg, setSuccessMsg] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [userCareerHighlightList, setUserCareerHighlightList] = useState('');
  const { userName } = useParams();
  const { pathname, search } = useLocation();
  const [updatedAwardData, setUpdatedAwardData] = useState('');
  const navigate = useNavigate();

  const handleDeleteCareerHighlight = async (awardId) => {
    if (awardId === '') {
      return false;
    }

    await requestApi(
      'DELETE',
      `delete-athlete-user-award-detail/${userName}/` + awardId,
      {
        'Authorization' : `Bearer ${localStorage.getItem('ya-user-access-token')}`,
      },
    ).then((result) => {
      setSuccessMsg('Your career highlight deleted successfully.Redirecting to career highlight listing page...');
      setErrorMsg('');

      setTimeout(() => {
        navigate(0);
      }, 500);
    }).catch((error) => {
      setSuccessMsg('');
      setErrorMsg(error?.response?.data?.message);
    });    
  };

  const loadAthleteUserCareerHighlightData = async () => {
    let url = `get-athlete-user-award-list/${userName}`;
    if (search !== undefined && search !== '') {
      url += search;
    }

    await requestApi(
      'GET',
      url,
      { 
        'Authorization' : `Bearer ${localStorage.getItem('ya-user-access-token')}`,
      },
    ).then((result) => {
      setErrorMsg('');
      setUserCareerHighlightList(result.data.response);
    }).catch((error) => {
      setLoading(false);
      setErrorMsg(error?.response?.data?.message);
    });
  };

  if (userCareerHighlightList === '') {
    loadAthleteUserCareerHighlightData();
  }

  useEffect(() => {
    if (userCareerHighlightList !== '' && userCareerHighlightList !== undefined) {
      setLoading(false);
      cleanUpBeforePageLoad();
      loadJSScript("/assets/js/theme.js", 'themeScript');
      window.scrollTo(0, 0);
    }
  }, [userCareerHighlightList, search]);

  return (
    <>
      {/*<div className="page-loader"></div>*/}

      {
        !localStorage.getItem('ya-user-access-token')
        ?
        <Navigate replace to="/" />
        :
        ''
      }
      
      <Layout pageLoader={true} >
        <div className="content-wrapper">
          <section className="wrapper">
            <div className="container pt-16 pb-10 pt-md-16 pb-md-10">
              <h1 className="display-3 mb-12 text-center">
                All Career Highlights
                <Link
                  to="/#"
                  data-bs-toggle="modal"
                  data-bs-target="#modal-addNewCareerHighlight"
                  className="btn btn-primary btn-sm rounded-pill mx-2 mb-2 mb-md-0"
                >
                  + Add
                </Link>
              </h1>
              {
                successMsg &&
                <div className="alert alert-success alert-icon alert-dismissible fade show text-start" role="alert">
                  <i className="uil uil-check"></i>{successMsg} <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                </div>  
              }
              {
                errorMsg &&
                (typeof errorMsg === "string") &&
                <div className="alert alert-danger alert-icon alert-dismissible fade show text-start" role="alert">
                  <i className="uil uil-exclamation-triangle"></i>{errorMsg} <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                </div>  
              }

              <div className="row gy-6">
                {
                  loading
                  ?
                  <PageLoader />
                  :
                  <>
                    {
                      (userCareerHighlightList !== undefined
                       && userCareerHighlightList.data !== undefined
                       && userCareerHighlightList.data.length !== 0
                      )
                      ?
                      <>
                        <div className="table-responsive">
                          <table className="table">
                            <thead>
                              <tr>
                                <th scope="col">Date</th>
                                <th scope="col">Event Name</th>
                                <th scope="col">{/*Award*/} Description</th>
                                <th scope="col">Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                userCareerHighlightList
                                && userCareerHighlightList.data.map((value, index) => {
                                  return (
                                    <tr key={`athleteUserCareerHighlights_${index}`}>
                                      <td>{value.userReadableDate}</td>
                                      <td>
                                        <Link to="#/">{value.eventName}</Link>
                                      </td>
                                      <td>{value.description}</td>
                                      <td>
                                        <div className="dropdown">
                                          <Link className="tableActionDropdown dropdown-toggle" to="#/" role="button" id="event_actions" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <i className="uil uil-ellipsis-v"></i>
                                          </Link>
                                          <ul className="dropdown-menu" aria-labelledby="event_actions">
                                            <li>
                                              <Link
                                                to="/#"
                                                data-bs-toggle="modal"
                                                data-bs-target="#modal-updateCareerHighlight"
                                                className="dropdown-item"
                                                onClick={() => setUpdatedAwardData(value)}
                                              >
                                                Edit
                                              </Link>
                                            </li>
                                            <li>
                                              <Link
                                                className="dropdown-item"
                                                to="#/"
                                                onClick={() => {
                                                  if (window.confirm('Are you sure you want to delete this career highlight?')) {
                                                    handleDeleteCareerHighlight(value.id);
                                                  }
                                                }}
                                              >
                                                Delete
                                              </Link>
                                            </li>
                                          </ul>
                                        </div>
                                      </td>
                                    </tr>
                                  );
                                })
                              }
                            </tbody>
                          </table>
                        </div>
                        {
                          (userCareerHighlightList !== undefined
                           && userCareerHighlightList.data !== undefined
                           && userCareerHighlightList.paginationLinks !== undefined
                          )
                          ?
                          <nav className="d-flex justify-content-center" aria-label="pagination">
                            <ul className="pagination mb-0">
                              <li className={`page-item ${userCareerHighlightList.paginationLinks !== undefined && userCareerHighlightList.paginationLinks.previousPageUrl === null ? 'disabled' : ''} `}>
                                <Link
                                  className="page-link"
                                  to={userCareerHighlightList.paginationLinks !== undefined
                                        && userCareerHighlightList.paginationLinks.previousPageUrl !== null
                                        ? pathname + userCareerHighlightList.paginationLinks.previousPageUrl.split(userName)[1]
                                        : '#/'
                                      }
                                  onClick={() => setUserCareerHighlightList('')}
                                  aria-label="Previous"
                                >
                                  <span aria-hidden="true"><i className="uil uil-arrow-left"></i></span>
                                </Link>
                              </li>
                              {
                                userCareerHighlightList !== undefined
                                && userCareerHighlightList.data !== undefined
                                && userCareerHighlightList.paginationLinks !== undefined
                                && Object.entries(userCareerHighlightList.paginationLinks.allPaginationLink).map((pageLink, index) => {
                                  return (
                                    <li
                                      key={`careerHighlightPaginationLinks${index}`}
                                      className={`page-item ${userCareerHighlightList.paginationLinks !== undefined && userCareerHighlightList.paginationLinks.currentPage.toString() === pageLink[0].toString() ? 'active' : ''}`}
                                    >
                                      <Link
                                        className="page-link"
                                        to={pathname + pageLink[1].split(userName)[1]}
                                        onClick={() => setUserCareerHighlightList('')}
                                      >
                                        {pageLink[0]}
                                      </Link>
                                    </li>
                                  );
                                })
                              }
                              <li className={`page-item ${userCareerHighlightList.paginationLinks !== undefined && userCareerHighlightList.paginationLinks.nextPageUrl === null ? 'disabled' : ''} `}>
                                <Link
                                  className="page-link"
                                  to={userCareerHighlightList.paginationLinks !== undefined
                                        && userCareerHighlightList.paginationLinks.nextPageUrl !== null
                                        ? pathname + userCareerHighlightList.paginationLinks.nextPageUrl.split(userName)[1]
                                        :
                                        '#/'
                                      }
                                  onClick={() => setUserCareerHighlightList('')}
                                  aria-label="Next"
                                >
                                  <span aria-hidden="true"><i className="uil uil-arrow-right"></i></span>
                                </Link>
                              </li>
                            </ul>
                          </nav>
                          :
                          ''
                        }
                      </>
                      :
                      <p className="text-center">No career highlights found</p>
                    }
                  </>
                }
              </div>
            </div>
          </section>
        </div>

        <AddNewCareerHighlightForm userName={userName} />

        <UpdateCareerHighlightForm
          userName={userName}
          updateAwardId={updatedAwardData.id}
          updatedAwardData={updatedAwardData}
        />
      </Layout>
    </>
  );
};

export default CareerHighlights;