import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import {
  Loader,
  Layout,
  cleanUpBeforePageLoad,
  loadJSScript,
  PageLoader,
  requestApi
} from './CommonFunction';
import '../styles/custom.css';

export const ForgotPasswordStepOne = ({ nextStep, handleFormData, formData }) => {
  const [sendOTPButton, setSendOTPButton] = useState(false);
  const [successMsg, setSuccessMsg] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm();

  const handleSendOTPFormSubmit = async (values) => {
    isValid ? setSendOTPButton(true) : setSendOTPButton(false)

    await requestApi(
      'POST',
      'user-forget-password',
      {},
      {},
      values
    ).then((result) => {
      setSuccessMsg('OTP send successfully.Please verify.');
      // await setTimeout(() => {
      //   setSendOTPButton(false);
      // }, 1500);
      setSendOTPButton(false);
      setErrorMsg('');
      nextStep();
    }).catch((error) => {
      setErrorMsg(error?.response?.data?.message);
      setSendOTPButton(false);
    });


    // await setTimeout(() => {
    //   setSendOTPButton(false);
    // }, 1500);
    
    //setErrorMsg('Some error is occured.');

    // setTimeout(() => {
    //   nextStep();
    // }, 2000);
  };

	return (
		<>
      {/*<div className="page-loader"></div>*/}

      <Layout hideMenu={true} >
  			<div className="content-wrapper">
  			  <section className="wrapper">
            <div className="container py-16">
              <div className="col-xl-7 col-lg-9 col-md-12 m-auto text-center">
                {/*<div className="alert alert-danger alert-icon alert-dismissible fade show text-start" role="alert">
                  <i className="uil uil-exclamation-triangle"></i>Can't find the account with this email address <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                </div>*/}
                <h1 className="display-3 mb-2">Forgot Password</h1>
                <p className="mb-8">Enter the email you signed up with，we will send you reset instructions.</p>
                {
                  successMsg &&
                  <div className="alert alert-success alert-icon alert-dismissible fade show text-start" role="alert">
                    <i className="uil uil-check"></i>{successMsg} <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                  </div>  
                }
                {
                  errorMsg &&
                  (typeof errorMsg === "string") &&
                  <div className="alert alert-danger alert-icon alert-dismissible fade show text-start" role="alert">
                    <i className="uil uil-exclamation-triangle"></i>{errorMsg} <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                  </div>  
                }

                <form
                  className="text-start mb-3"
                  onSubmit={handleSubmit(handleSendOTPFormSubmit)}
                >
                  <div className="form-floating mb-8">
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Email"
                      id="accountEmail"
                      {...register('email', {
                        required: true,
                        pattern: /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/, //eslint-disable-line
                        onChange: handleFormData,
                        value: formData?.email
                      })}
                    />
                    <label htmlFor="accountEmail">Email</label>
                    {
                      errors.email?.type === "required" && 
                      <div className="formFieldError">
                        Please enter your email address.
                      </div>
                    }
                    {
                      errors.email?.type === "pattern" && 
                      <div className="formFieldError">
                        Please enter valid email address.
                      </div>
                    }
                    {
                      errorMsg &&
                      (typeof errorMsg === "object") &&
                      <div className="formFieldError">
                        {errorMsg[0]}
                      </div>  
                    }
                  </div>
                  <div className="text-center">
                    <p className="mb-6">
                      <button
                        disabled={sendOTPButton}
                        className="btn btn-primary rounded-pill px-12"
                        type="submit"
                      >
                        { sendOTPButton ? <Loader text='Reset Password' /> : 'Reset Password' }
                      </button>
                    </p>
                    <p>
                      <Link to="/">Back to home</Link>
                    </p>
                  </div>
                </form>
              </div>
            </div>
          </section>
  			</div>
      </Layout>
		</>
	);
};

export const ForgotPasswordStepTwo = ({ prevStep, nextStep, handleFormData, formData }) => {
  const [loading, setLoading] = useState(true);
  const [verifyOTPButton, setVerifyOTPButton] = useState(false);
  const [successMsg, setSuccessMsg] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm();

  const handleVerifyOTPFormSubmit = async (values) => {
    isValid ? setVerifyOTPButton(true) : setVerifyOTPButton(false)

    await requestApi(
      'POST',
      'verify-user-forget-password-otp',
      {},
      {},
      values
    ).then((result) => {
      setSuccessMsg('OTP verify successfully.');
      // setTimeout(() => {
      //   setVerifyOTPButton(false);
      // }, 1500);
      setVerifyOTPButton(false);
      setErrorMsg('');
      nextStep();
    }).catch((error) => {
      setErrorMsg(error?.response?.data?.message);
      setVerifyOTPButton(false);
    });

    //setErrorMsg('Some error is occured.');

    // setTimeout(() => {
    //   nextStep();
    // }, 2000);
  };

  useEffect(() => {
    if (loading) {
      setTimeout(() => {
        setLoading(false);
        cleanUpBeforePageLoad();
        loadJSScript("/assets/js/theme.js", 'themeScript');
        window.scrollTo(0, 0);
      }, 20);
    }
  }, [loading]);

  return (
    <>
      {/*<div className="page-loader"></div>*/}

      <Layout hideMenu={true} pageLoader={true} >
        {
          loading
          ?
          <PageLoader />
          :
          <div className="content-wrapper">
            <section className="wrapper">
              <div className="container py-16">
                <div className="col-xl-7 col-lg-9 col-md-12 m-auto text-center">
                  {/*<div className="alert alert-danger alert-icon alert-dismissible fade show text-start" role="alert">
                    <i className="uil uil-exclamation-triangle"></i>Code does not match. <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                  </div>*/}
                  <h1 className="display-3 mb-2">Verify it's you</h1>
                  <p className="mb-7">We've sent you an email with a 6 digit code and it will expire in 1 minute. Please enter the code below.</p>
                  {
                    successMsg &&
                    <div className="alert alert-success alert-icon alert-dismissible fade show text-start" role="alert">
                      <i className="uil uil-check"></i>{successMsg} <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                    </div>  
                  }

                  {
                    errorMsg &&
                    (typeof errorMsg === "string") &&
                    <div className="alert alert-danger alert-icon alert-dismissible fade show text-start" role="alert">
                      <i className="uil uil-exclamation-triangle"></i>{errorMsg} <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                    </div>  
                  }

                  <form
                    className="text-start mb-3"
                    onSubmit={handleSubmit(handleVerifyOTPFormSubmit)}
                  >
                    <div className="form-floating mb-8">
                      <input
                        className="form-control"
                        type="hidden"
                        placeholder="Email"
                        id="otpEmail"
                        {...register('email', {
                          required: true,
                        })}
                        value={formData?.email}
                      />
                    </div>
                    <div className="form-floating mb-8">
                      <input
                        className="form-control"
                        placeholder="OTP Code"
                        id="otpCode"
                        {...register('otp', {
                          required: true,
                          minLength: 6,
                          maxLength: 6,
                          pattern: /^[0-9]+$/,
                          onChange: handleFormData,
                        })}
                      />
                      <label htmlFor="opt_code">6 digit code</label>
                      {
                        errors.otp?.type === "required" && 
                        <div className="formFieldError">
                          Please enter 6 digit OTP.
                        </div>
                      }
                      {
                        errors.otp?.type === "minLength" && 
                        <div className="formFieldError">
                          OTP code must be minimum 6 digit.
                        </div>
                      }
                      {
                        errors.otp?.type === "maxLength" && 
                        <div className="formFieldError">
                          OTP code must be maximum 6 digit.
                        </div>
                      }
                      {
                        errors.otp?.type === "pattern" && 
                        <div className="formFieldError">
                          OTP code must be number.
                        </div>
                      }
                      {
                        errorMsg &&
                        (typeof errorMsg === "object") &&
                        <div className="formFieldError">
                          {errorMsg[1]}
                        </div>  
                      }
                    </div>
                    <div className="text-center">
                      <p className="mb-6">
                        <button
                          disabled={verifyOTPButton}
                          className="btn btn-primary rounded-pill px-12"
                          type="submit"
                        >
                          { verifyOTPButton ? <Loader text='Submit Code' /> : 'Submit Code' }
                        </button>
                      </p>
                      <p className="mb-0">Did not receive code? <Link to="#/" onClick={prevStep}>Request a new code</Link>
                      </p>
                      <p>If you can't find the email, check spam/junk folder. For futher assistance, please <Link to="/contact">contact us</Link>. </p>
                    </div>
                  </form>
                </div>
              </div>
            </section>
          </div>
        }
      </Layout>
    </>
  );
};

export const ForgotPasswordStepThree = ({ nextStep, formData }) => {
  const [loading, setLoading] = useState(true);
  const [resetPasswordButton, setResetPasswordButton] = useState(false);
  const [successMsg, setSuccessMsg] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isValid },
  } = useForm();

  const handleResetPasswordFormSubmit = async (values) => {
    isValid ? setResetPasswordButton(true) : setResetPasswordButton(false)

    await requestApi(
      'POST',
      'user-reset-password',
      {},
      {},
      values
    ).then((result) => {
      setSuccessMsg('Password reset successfully.');
      // setTimeout(() => {
      //   setResetPasswordButton(false);
      // }, 1500);
      setResetPasswordButton(false);
      setErrorMsg('');
      nextStep();
    }).catch((error) => {
      setErrorMsg(error?.response?.data?.message);
      setResetPasswordButton(false);
    });

    //setErrorMsg('Some error is occured.');

    // setTimeout(() => {
    //   nextStep();
    // }, 2000);
  };

  useEffect(() => {
    if (loading) {
      setTimeout(() => {
        setLoading(false);
        cleanUpBeforePageLoad();
        loadJSScript("/assets/js/theme.js", 'themeScript');
        window.scrollTo(0, 0);
      }, 20);
    }
  }, [loading]);

  return (
    <>
      {/*<div className="page-loader"></div>*/}

      <Layout hideMenu={true} pageLoader={true} >
        {
          loading
          ?
          <PageLoader />
          :
          <div className="content-wrapper">
            <section className="wrapper">
              <div className="container py-16">
                <div className="col-xl-7 col-lg-9 col-md-12 m-auto text-center">
                  {/*<div className="alert alert-danger alert-icon alert-dismissible fade show text-start" role="alert">
                    <i className="uil uil-exclamation-triangle"></i>New password does not meet minimum requirement. <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                  </div>*/}
                  <h1 className="display-3 mb-2">Choose your new password</h1>
                  <div className="row gx-md-0 gx-xl-12 text-center">
                    <p className="mb-6">your password must be 8-12 characters.</p>
                    {
                      successMsg &&
                      <div className="alert alert-success alert-icon alert-dismissible fade show text-start" role="alert">
                        <i className="uil uil-check"></i>{successMsg} <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                      </div>  
                    }
                    {
                      errorMsg &&
                      (typeof errorMsg === "string") &&
                      <div className="alert alert-danger alert-icon alert-dismissible fade show text-start" role="alert">
                        <i className="uil uil-exclamation-triangle"></i>{errorMsg} <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                      </div>  
                    }

                    <form
                      className="text-start mb-3"
                      onSubmit={handleSubmit(handleResetPasswordFormSubmit)}
                    >
                      <div className="form-floating mb-4">
                        <input
                          className="form-control"
                          type="hidden"
                          placeholder="Email"
                          id="resetPasswordEmail"
                          {...register('email', {
                            required: true,
                          })}
                          value={formData?.email}
                        />
                      </div>
                      <div className="form-floating password-field mb-4">
                        <input
                          type="password"
                          className="form-control"
                          placeholder="New Password"
                          id="newPassword"
                          {...register('password', {
                            required: true,
                            minLength: 8,
                            maxLength: 12,
                          })}
                        />
                        <span className="password-toggle">
                          <i className="uil uil-eye"></i>
                        </span>
                        <label htmlFor="newPassword">New Password</label>
                        {
                          errors.password?.type === "required" && 
                          <div className="formFieldError">
                            Please enter your new password.
                          </div>
                        }
                        {
                          errors.password?.type === "minLength" && 
                          <div className="formFieldError">
                            New password length must be minimum 8 character.
                          </div>
                        }
                        {
                          errors.password?.type === "maxLength" && 
                          <div className="formFieldError">
                            New password length must be maximum 12 character.
                          </div>
                        }
                        {
                          errorMsg &&
                          (typeof errorMsg === "object") &&
                          errorMsg?.password && 
                          <div className="formFieldError">
                            {errorMsg.password[0]}
                          </div>
                        }
                      </div>
                      <div className="form-floating password-field mb-8">
                        <input
                          type="password"
                          className="form-control"
                          placeholder="Confirm New Password"
                          id="confirmNewPassword"
                          {...register('password_confirmation', {
                            required: true,
                            minLength: 8,
                            maxLength: 12,
                            validate: () => {
                              return watch('password') === watch('password_confirmation')
                                && watch('password_confirmation') !== ''
                            }
                          })}
                        />
                        <span className="password-toggle">
                          <i className="uil uil-eye"></i>
                        </span>
                        <label htmlFor="confirmNewPassword">Confirm New Password</label>
                        {
                          errors.password_confirmation?.type === "required" && 
                          <div className="formFieldError">
                            Please enter new password again.
                          </div>
                        }
                        {
                          errors.password_confirmation?.type === "minLength" && 
                          <div className="formFieldError">
                            Confirm password length must be minimum 8 character.
                          </div>
                        }
                        {
                          errors.password_confirmation?.type === "maxLength" && 
                          <div className="formFieldError">
                            Confirm password length must be maximum 12 character.
                          </div>
                        }
                        {
                          errors.password_confirmation?.type === "validate" && 
                          <div className="formFieldError">
                            New password and confirm password must be same.
                          </div>
                        }
                        {/*
                          watch('password') !== watch('password_confirmation') && getValues('password_confirmation') !== '' && 
                          <div className="formFieldError">
                            New password and confirm password must be same.
                          </div>
                        */}
                        {
                          errorMsg &&
                          (typeof errorMsg === "object") &&
                          errorMsg?.password_confirmation && 
                          <div className="formFieldError">
                            {errorMsg.password_confirmation[0]}
                          </div>
                        }
                      </div>
                      <div className="text-center">
                        <p className="mb-6">
                          <button
                            disabled={resetPasswordButton}
                            className="btn btn-primary rounded-pill px-12"
                            type="submit"
                          >
                            { resetPasswordButton ? <Loader text='Change my password' /> : 'Change my password' }
                          </button>
                        </p>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </section>
          </div>
        }
      </Layout>
    </>
  );
};

export const ForgotPasswordStepFour = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (loading) {
      setTimeout(() => {
        setLoading(false);
        cleanUpBeforePageLoad();
        loadJSScript("/assets/js/theme.js", 'themeScript');
        window.scrollTo(0, 0);
      }, 500);
    }
  }, [loading]);

  return (
    <>
      {/*<div className="page-loader"></div>*/}

      <Layout hideMenu={true} pageLoader={true} >
        {
          loading
          ?
          <PageLoader />
          :
          <div className="content-wrapper">
            <section className="wrapper">
              <div className="container py-16">
                <div className="col-xl-7 col-lg-9 col-md-12 m-auto text-center">
                  <h1 className="display-3 mb-6">Done!</h1>
                  <p className="mb-7">Your password is updated, a comfirmation email has sent to your email account.</p>
                  <p>
                    <Link to="/" className="btn btn-primary rounded-pill px-12">Go to home</Link>
                  </p>
                </div>
              </div>
            </section>
          </div>
        }
      </Layout>
    </>
  );
};