import React, { useState } from 'react';
import { 
	OtpLoginStepOne,
	OtpLoginStepTwo,
} from '../components/LoginThroughOtp';

const OtpLogin = () => {
	const [otpLoginStep, setOtpLoginStep] = useState(1);
	const [formData, setFormData] = useState({});

	const prevStep = () => {
		setOtpLoginStep(otpLoginStep - 1);
	};

	const nextStep = () => {
		setOtpLoginStep(otpLoginStep + 1);
	};

	const handleFormData = (values) => {
		setFormData({...formData, [values.target.name]: values.target.value });
	};

	switch (otpLoginStep) {
	  case 1: 
	    return (
	      <OtpLoginStepOne
	      	nextStep={nextStep}
	      	handleFormData={handleFormData}
	      	formData={formData}
	      />
	    );
	  case 2: 
	    return (
	      <OtpLoginStepTwo
	      	prevStep={prevStep}
	      	handleFormData={handleFormData}
	      	formData={formData}
	      />
	    );
	  default: 
	     return (
	     	<OtpLoginStepOne />
	    );
	}
};

export default OtpLogin;