import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom';
import {
  Loader,
  Layout,
  cleanUpBeforePageLoad,
  loadJSScript,
  PageLoader,
  requestApi,
} from '../../CommonFunction';
import moment from 'moment';

const BuyMembership = () => {
  const [loading, setLoading] = useState(true);
  const [selectedMembershipPlan, setSelectedMembershipPlan] = useState('');
  const [successMsg, setSuccessMsg] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [buyMembershipButton, setBuyMembershipButton] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm();
  const { state } = useLocation();
  const navigate = useNavigate();

  const handleBuyMembershipFormSubmit = async (values) => {
    isValid ? setBuyMembershipButton(true) : setBuyMembershipButton(false)
    const postData = {
      'planId': selectedMembershipPlan?.id,
      'amount': selectedMembershipPlan?.price
    };

    await requestApi(
      'POST',
      'buy-membership',
      { 
        'Authorization' : `Bearer ${localStorage.getItem('ya-user-access-token')}`,
      },
      {},
      postData
    ).then(async (result) => {
      await setSuccessMsg('Your membership subscribtion successfully activated.Redirecting to account page...');
      await setBuyMembershipButton(false);
      setErrorMsg('');

      setTimeout(() => {
        navigate('/account');
      }, 1500);
    }).catch((error) => {
      setSuccessMsg('');
      setErrorMsg(error?.response?.data?.message);
      setBuyMembershipButton(false);
    });
  };

  useEffect(() => {
    setLoading(true);
    window.scrollTo(0, 0);

    if (state === null) {
      navigate('/');
    }

    if (state?.selectedPlan !== undefined && selectedMembershipPlan === '' && state?.selectedPlan !== null) {
      setSelectedMembershipPlan(state?.selectedPlan);
    }

    if (selectedMembershipPlan !== undefined && selectedMembershipPlan !== '') {
      setLoading(false);
      cleanUpBeforePageLoad();
      loadJSScript("/assets/js/theme.js", 'themeScript');
      window.scrollTo(0, 0);
    }
  }, [selectedMembershipPlan, navigate, state]);

  return (
    <>
      {/*<div className="page-loader"></div>*/}

      {
        !localStorage.getItem('ya-user-access-token')
        ?
        <Navigate replace to="/" />
        :
        ''
      }

      <Layout isUserLogin={true} pageLoader={true} >
        {
          loading
          ?
          <PageLoader />
          :
          <div className="content-wrapper">
            <section className="wrapper">
              <div className="container py-14">
                <div className="col-xl-7 col-lg-9 col-md-12 m-auto">
                  <nav className="d-inline-block" aria-label="breadcrumb">
                    <ol className="breadcrumb mb-6">
                      <li className="breadcrumb-item">
                        <Link to="/account">Account</Link>
                      </li>
                      <li className="breadcrumb-item">
                        <Link to="/account/membership-plan">Membership Plan</Link>
                      </li>
                      <li className="breadcrumb-item active" aria-current="page">Buy Membership Subscribtion</li>
                    </ol>
                  </nav>

                  {
                    successMsg &&
                    <div className="alert alert-success alert-icon alert-dismissible fade show text-start" role="alert">
                      <i className="uil uil-check"></i>{successMsg} <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                    </div>  
                  }
                  {
                    errorMsg &&
                    (typeof errorMsg === "string") &&
                    <div className="alert alert-danger alert-icon alert-dismissible fade show text-start" role="alert">
                      <i className="uil uil-exclamation-triangle"></i>{errorMsg} <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                    </div>  
                  }
                  {
                    errorMsg &&
                    (typeof errorMsg === "object") &&
                    <div className="alert alert-danger alert-icon alert-dismissible fade show text-start" role="alert">
                      <i className="uil uil-exclamation-triangle"></i>Error <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                    </div>  
                  }

                  <h2 className="mb-6">Membership Subscribtion</h2>
                  {
                    loading
                    ?
                    <PageLoader />
                    :
                    <>
                      <p>Selected plan: {selectedMembershipPlan?.planName}</p>
                      <p> 
                        {`
                          Your membership expires on ${selectedMembershipPlan?.planType === 'yearly'
                          ?
                          moment().add(12, 'months').format('YYYY-MM-DD')
                          :
                          selectedMembershipPlan?.planType === 'monthly'
                          ?
                          moment().add(1, 'months').format('YYYY-MM-DD')
                          :
                          ''}
                        `}
                      </p>
                      {
                        selectedMembershipPlan?.planType === 'yearly'
                        || selectedMembershipPlan?.planType === 'monthly'
                        ?
                        <h4>
                          {`One ${selectedMembershipPlan?.planType === 'yearly' ? 'year' : 'month'} membership subscription: ${selectedMembershipPlan?.priceCurrency}${parseFloat(selectedMembershipPlan?.price).toFixed(2)} `}
                        </h4>
                        :
                        ''
                      }
                      
                      <form
                        className="text-start mb-3"
                        onSubmit={handleSubmit(handleBuyMembershipFormSubmit)}
                      >
                        <div className="form-check mb-12">
                          <input
                            id="acceptmembershipAgreement" 
                            className="form-check-input"
                            type="checkbox"
                            {...register('acceptmembershipAgreement', {
                              required: true,
                            })}
                          />
                          <label className="form-check-label" htmlFor="acceptmembershipAgreement">I agree the membership agreement </label>
                          {
                            errors.acceptmembershipAgreement?.type === "required" && 
                            <div className="formFieldError">
                              Please accept membership agreement.
                            </div>
                          }
                        </div>
                        <button
                          disabled={buyMembershipButton}
                          className="btn btn-primary rounded-pill btn-lg w-100 mb-2"
                          type="submit"
                        >
                          { buyMembershipButton ? <Loader text='Buy' /> : 'Buy' }
                        </button>
                      </form>
                    </>
                  }
                </div>
              </div>
            </section>
          </div>
        }
      </Layout>
    </>
  );
};

export default BuyMembership;