import React, { useState, useEffect } from 'react';
import {
  Link,
  useParams,
  Navigate,
  useLocation,
} from 'react-router-dom'; 
import {
  Layout,
  cleanUpBeforePageLoad,
  loadJSScript,
  PageLoader,
  requestApi,
} from '../../components/CommonFunction';

const StudentList = () => {
  const [loading, setLoading] = useState(true);
  const [assignedEventStudentList, setAssignedEventStudentList] = useState('');
  const { pathname, search } = useLocation();
  const { eventName } = useParams();

  const loadAssignedEventStudentData = async () => {
    let url = `get-event-register-users-list/${eventName}`;
    if (search !== undefined && search !== '') {
      url += search;
    }

    await requestApi(
      'GET',
      url,
      { 'Authorization' : `Bearer ${localStorage.getItem('ya-user-access-token')} ` },
    ).then((result) => {
      setLoading(false);
      setAssignedEventStudentList(result.data.response);
    }).catch((error) => {
      setLoading(false);
      //console.log(error?.response?.data?.message);
    });
  };

  if (localStorage.getItem('ya-user-access-token')
      && assignedEventStudentList === ''
  ) {
    loadAssignedEventStudentData();
  }

  useEffect(() => {
    if (assignedEventStudentList !== undefined
        && assignedEventStudentList !== ''
    ) {
      setLoading(false);
      cleanUpBeforePageLoad();
      loadJSScript("/assets/js/theme.js", 'themeScript');
      window.scrollTo(0, 0);
    };
  }, [assignedEventStudentList]);

  return (
    <>
      {/*<div className="page-loader"></div>*/}

      {
        !localStorage.getItem('ya-user-access-token')
        ?
        <Navigate replace to="/" />
        :
        ''
      }
      
      <Layout pageLoader={true} >
        <div className="content-wrapper">
          <section className="wrapper">
            <div className="container pt-16 pb-10 pt-md-16 pb-md-10">
              <h1 className="display-3 mb-12">
                {`Student List For ${eventName}`}
              </h1>

              <div className="row gy-6">
                {
                  loading
                  ?
                  <PageLoader />
                  :
                  <>
                    {
                      (assignedEventStudentList !== undefined
                       && assignedEventStudentList.data !== undefined
                       && assignedEventStudentList.data.length !== 0
                      )
                      ?
                      <>
                        <div className="table-responsive">
                          <table className="table">
                            <thead>
                              <tr>
                                <th scope="col">Name</th>
                                <th scope="col">Email</th>
                                <th scope="col">Country</th>
                                <th scope="col">Address</th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                assignedEventStudentList
                                && assignedEventStudentList.data.map((value, index) => {
                                  return (
                                    <tr key={`assignedEventStudentList_${index}`}>
                                      <td>{value.userData.fullName}</td>
                                      <td>{value.userData.email}</td>
                                      <td>{value.userData.userReadableAddressInformation.country}</td>
                                      <td>
                                        {value.userData.userReadableAddressInformation.address}
                                        {value.userData.userReadableAddressInformation.address2
                                         && `, ${value.userData.userReadableAddressInformation.address2}`
                                        }
                                      </td>
                                    </tr>
                                  );
                                })
                              }
                            </tbody>
                          </table>
                        </div>
                        {
                          (assignedEventStudentList !== undefined
                           && assignedEventStudentList.data !== undefined
                           && assignedEventStudentList.paginationLinks !== undefined
                          )
                          ?
                          <nav className="d-flex justify-content-center" aria-label="pagination">
                            <ul className="pagination mb-0">
                              <li className={`page-item ${assignedEventStudentList.paginationLinks !== undefined && assignedEventStudentList.paginationLinks.previousPageUrl === null ? 'disabled' : ''} `}>
                                <Link
                                  className="page-link"
                                  to={assignedEventStudentList.paginationLinks !== undefined
                                    && assignedEventStudentList.paginationLinks.previousPageUrl !== null
                                    ? pathname + assignedEventStudentList.paginationLinks.previousPageUrl.split(assignedEventStudentList.paginationLinks.paginationOptions.path)[1]
                                    : '#/'
                                  }
                                  onClick={() => {
                                    setLoading(true);
                                    setAssignedEventStudentList('');
                                  }}
                                  aria-label="Previous"
                                >
                                  <span aria-hidden="true"><i className="uil uil-arrow-left"></i></span>
                                </Link>
                              </li>
                              {
                                Object.entries(assignedEventStudentList.paginationLinks.allPaginationLink).map((pageLink, index) => {
                                  return (
                                    <li
                                      key={`assignedEventStudentListPaginationLinks${index}`}
                                      className={`page-item ${assignedEventStudentList.paginationLinks !== undefined && assignedEventStudentList.paginationLinks.currentPage.toString() === pageLink[0].toString() ? 'active' : ''}`}
                                    >
                                      <Link
                                        className="page-link"
                                        to={pathname + pageLink[1].split(assignedEventStudentList.paginationLinks.paginationOptions.path)[1]}
                                        onClick={() => {
                                          setLoading(true);
                                          setAssignedEventStudentList('');
                                        }}
                                      >
                                        {pageLink[0]}
                                      </Link>
                                    </li>
                                  );
                                })
                              }
                              <li className={`page-item ${assignedEventStudentList.paginationLinks !== undefined && assignedEventStudentList.paginationLinks.nextPageUrl === null ? 'disabled' : ''} `}>
                                <Link
                                  className="page-link"
                                  to={assignedEventStudentList.paginationLinks !== undefined
                                    && assignedEventStudentList.paginationLinks.nextPageUrl !== null
                                    ? pathname + assignedEventStudentList.paginationLinks.nextPageUrl.split(assignedEventStudentList.paginationLinks.paginationOptions.path)[1]
                                    :
                                    '#/'
                                  }
                                  onClick={() => {
                                    setLoading(true);
                                    setAssignedEventStudentList('');
                                  }}
                                  aria-label="Next"
                                >
                                  <span aria-hidden="true"><i className="uil uil-arrow-right"></i></span>
                                </Link>
                              </li>
                            </ul>
                          </nav>
                          :
                          ''
                        }  
                      </>
                      :
                      <p className="text-center">
                        <i className="uil uil-calendar-alt"></i> No Student Details Found
                      </p>
                    }
                  </>
                }
              </div>
            </div>
          </section>
        </div>
      </Layout>
    </>
  );
};

export default StudentList;